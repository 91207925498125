import { useState, useCallback, useRef, useEffect } from 'react';
import FileSender from "./filesender";
import FileType from 'file-type';
import sparkMD5 from "spark-md5";

export interface UploadingManyUploadedItem {
    fileName: string,
    fileSize: number,
    fileId: string,
    fileUrl: string,

}
function useUploadingMany(wsUrl: string) {
    const [uploadedItems, setUploadedItems] = useState<UploadingManyUploadedItem[]>();

    const [statusText, setStatusText] = useState('');
    const [lastModified, setLastModified] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    let sender: FileSender | undefined = undefined
    let isAbort = false

    const onProgress = useCallback((sentBytes, totalBytes) => {
        setProgress((sentBytes / totalBytes) * 100);
    }, []);
    const onFinished = useCallback((fileId, fileUrl, file) => {
        setUploadedItems((uploadedItems) => {
            return [
                ...(uploadedItems || []),
                {
                    fileName: file.name,
                    fileSize: file.size,
                    fileId: fileId,
                    fileUrl: fileUrl
                }
            ]
        })
        setFileUploaded(true)
    }, []);

    const startUpload = useCallback((acceptedTypes) => {
        setFileUploaded(false)
        isAbort = false;

        const fe = document.createElement("input");
        fe.type = "file";
        fe.multiple = true;
        fe.accept = acceptedTypes;

        fe.onchange = async (e) => {
            setIsUploading(true)
            const files = (e.target as HTMLInputElement).files!;
            // for each files
            console.log(files)
            for (let i = 0; i < files.length; i++) {
                if(isAbort) {
                    break;
                }

                const file = files[i];
                if (file) {
                    setStatusText("正在准备文件 " + file.name)
                    setLastModified(file.lastModified);
                    setProgress(0);
                    setErrorMessage('');
                    const md5 = await calculateMD5(file)
                    sender = new FileSender(wsUrl, file, md5, onProgress, onFinished);
                    try {
                        setStatusText("正在上传数据")
                        await sender.startSend();
                        setStatusText("上传完成")

                    } catch (e: any) {
                        setErrorMessage(e.message);
                        setStatusText("上传失败 " + e.message)
                    }
                }
            }
            setIsUploading(false)
        }
        fe.click();


    }, []);



    const stopUpload = useCallback(() => {
        isAbort = true;
        if(sender) {
            sender.stopSend()
        }
    }, []);

    const clean = useCallback(() => {
        setUploadedItems([])
    }, []);

    const calculateMD5 = (file: File): Promise<string> => {
        setStatusText("正在计算文件校验码")
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = function() {
                const binaryStr = reader.result as string;
                const md5 = sparkMD5.hashBinary(binaryStr);
                resolve(md5);
            };
            reader.onerror = function() {
                reject('文件MD5计算失败');
            };
            reader.readAsBinaryString(file);
        });
    }




    return {
        uploadedItems,
        statusText,
        lastModified,
        isUploading,
        progress,
        errorMessage,
        startUpload,
        fileUploaded,
        stopUpload,
        clean,
    };
}

export default useUploadingMany;
