
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea} from "../WidgetAppParam";


export class EngChatApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextArea("points", "知识点", "输入你需要练习的知识点", "知识点"),

        ];
    }

    template: string = "EngChat";
    title: string = "英语对话练习";

}

export default EngChatApp;

