
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine} from "../WidgetAppParam";


export class StudyTestApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextLine("subject", "学习科目", "请输入我的学习科目, 比如英语", "学习科目"),
            new WidgetAppParamTextArea("content", "知识点", "请输入要考察的知识点, 每个知识点一行", "知识点"),
        ];
    }

    template: string = "StudyTest";
    title: string = "学习测评";

}

export default StudyTestApp;

