import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";


// components
import PageTitle from "../../../components/PageTitle";
import TransLangSelectGroup, {Engine, LangInfo} from "../../../utils/TransLangSelectGroup";
import AudioTransResultBox from "../AudioTransOnce/AudioTransResultBox";
import useDocBatchClient, {Sentence} from "../../../utils/docbatch_client";
import UploadableAudioBox from "../AudioTransOnce/UploadableAudioBox";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";
import {ModelConfig} from "../../../utils/WebAppConfig";
import {set} from "react-hook-form";
import {withSwal} from "react-sweetalert2";




const AudioTransOnceApp = withSwal((props: any) => {
    const docBatchClient = useDocBatchClient()
    const [fileId, setFileId] = useState<string | null>(null)
    const [fileName, setFileName] = useState<string | null>(null)
    const [taskId, setTaskId] = useState<string | null>(null)
    const [taskStatus, setTaskStatus] = useState<string>("idle")
    const [progressText, setTaskProgressText] = useState<string>("")
    const [progressValue, setTaskProgressValue] = useState<number>(0)
    const [sentences, setSentences] = useState<Sentence[]>([])
    const [currentPlayingSentenceIndex, setCurrentPlayingSentenceIndex] = useState<number>(-1)
    const [fromLang, setFromLang] = useState<LangInfo>({value: "en", label: "英语"})
    const [toLang, setToLang] = useState<LangInfo>({value: "zh", label: "中文"})
    const [transEngine, setTransEngine] = useState<Engine>({value: "niu", label: "组合模型"})
    const [modelConfig, setModelConfig] = useState<ModelConfig>()
    const {swal} = props

    const [isWorking, setIsWorking] = useState(false)




    const handleOnTranslateClick = async () => {
        if(!modelConfig) {
            swal.fire("错误", "请先选择模型", "error")
            return
        }
        setIsWorking(true)
        const tid = await docBatchClient.addTaskOne({
            task_type: "audio_trans",
            file_id: fileId!,
            base_model: modelConfig.modelName,
            file_name: fileName!,
            audio_trans_option: {
                from_lang_code: fromLang.value,
                from_lang_name: fromLang.label,
                to_lang_code: toLang.value,
                to_lang_name: toLang.label,
                trans_engine: transEngine.value,
            }
        })
        console.log("tid is " + tid)
        setTaskId(tid)
    }

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('音频翻译'))
    }, []);
    return (
        <>
            <Row>
                <Col md={12} xl={12}>
                    <div className="tool-bar">
                        <TransLangSelectGroup
                            defaultFromLang={{value: "en", label: "英语"}}
                            defaultToLang={{value: "zh", label: "中文"}}
                            defaultEngine={{value: "niu", label: "组合模型"}}
                            onFromLangChanged={(e)=>{setFromLang(e)}}
                            onToLangChanged={(e)=>{setToLang(e)}}
                            onEngineChanged={(e) => {setTransEngine(e)} }
                            onModelConfigChanged={setModelConfig}
                        />

                        <Button
                            onClick={handleOnTranslateClick}
                            disabled={isWorking}
                        >翻译
                        </Button>
                        <span>{
                            taskStatus === 'idle'? "正在排队" :
                                taskStatus === 'processing'? `正在处理` :
                                    taskStatus === 'finished'? "处理成功" :
                                        taskStatus === 'error'? "处理失败" : "未知状态"
                        }</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}>
                    <UploadableAudioBox
                        onAudioFileChanged={(fileId, fileName, fileUrl) => {
                            setFileId(fileId)
                            setFileName(fileName)
                        }}
                        onTimeUpdate={(currentTime) => {
                            /*
                            let i = 0
                            for(let sen of sentences) {
                                if (currentTime >= sen.start && currentTime < sen.stop) {
                                    // setCurrentSubTitle(sen['text'])
                                    setCurrentPlayingSentenceIndex(i)
                                    console.log("index is " + i)
                                    break
                                }
                                i++
                            }
                             */

                        }
                        } />

                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}  style={{height: "50vh"}}>
                    <h5>翻译结果</h5>
                    <AudioTransResultBox  sentences={sentences} highLightIndex={currentPlayingSentenceIndex}></AudioTransResultBox>
                </Col>
            </Row>
        </>
    );
});

export default AudioTransOnceApp;
