import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import UsageChart from "./UsageChart";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import {formatFileSize} from "../../../utils/Helpers";



interface DiskInfo {
    "device": string
    "mount_point": string
    "fs_type": string
    "total_space": number
    "free_space": number
    "available_space": number
}

interface MemInfo {
    total_memory: number
    available_memory: number
}
const DiskUsageApp = () => {

    const [disks, setDisks] = useState<DiskInfo[]>([])
    const [memInfo, setMemInfo] = useState<MemInfo>()


    useEffect(() => {
        auth_fetch_post_json(Conf.urlStatsDiskUsage, {})
            .then((json: any) => {
                console.log(json)
                setDisks(json['disk_info'])
                setMemInfo(json['mem_info'])
            })
            .catch(err => console.log(err))
    }, []);





    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/diskusage" },
                    { label: "资源使用", path: "/apps/diskusage", active: true },
                ]}
                title={"资源使用"}
            />

            <Row>
                {disks.map(disk =>
                    (
                        <Col lg={4}>
                            <UsageChart
                                key = {disk.mount_point}
                                usageName={'磁盘' + disk.device} usagePercent={Math.ceil((disk.total_space - disk.available_space) * 100 / disk.total_space)} chartLabel={disk.mount_point}
                                totalLabel={"全部空间"}
                                totalValue={formatFileSize(disk.total_space)}
                                usedLabel={"已用空间"}
                                usedValue={formatFileSize(disk.total_space - disk.free_space)}
                                freeLabel={"剩余空间"}
                                freeValue={formatFileSize(disk.free_space)}
                            ></UsageChart>
                        </Col>
                    )
                )}

                <Col lg={4}>
                    {memInfo && (<UsageChart
                        key = {"mem"}
                        usageName={'内存'} usagePercent={Math.ceil(memInfo.available_memory * 100 / memInfo.total_memory)} chartLabel={"物理内存"}
                        totalLabel={"全部内存"}
                        totalValue={formatFileSize(memInfo.total_memory)}
                        usedLabel={"已用内存"}
                        usedValue={formatFileSize(memInfo.available_memory)}
                        freeLabel={"剩余内存"}
                        freeValue={formatFileSize(memInfo.total_memory - memInfo.available_memory)}
                    ></UsageChart>)}
                </Col>
            </Row>

        </>
    );
};

export default DiskUsageApp;
