
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine} from "../WidgetAppParam";


export class CreateHelperApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextLine("subject", "创作主题", "请输入创作的主题", "文章主题"),
            new WidgetAppParamTextArea("content", "创作内容", "请输入创作的内容", "创作内容"),
        ];
    }

    template: string = "CreateHelper";
    title: string = "创作助手";
}

export default CreateHelperApp;

