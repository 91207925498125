
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine} from "../WidgetAppParam";


export class RulesWriteApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextLine("subject", "主题", "请输入规章制度主题, 例如规范户外烧烤", "主题"),
            new WidgetAppParamTextArea("content", "大致内容", "请输入规章制度的大致内容", "大致内容"),

        ];
    }

    template: string = "RulesWrite";
    title: string = "规章制度助手";

}

export default RulesWriteApp;

