import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";


// components
import PageTitle from "../../../components/PageTitle";
import TransLangSelectGroup, {Engine, LangInfo} from "../../../utils/TransLangSelectGroup";
import AudioTransResultBox from "../AudioTransOnce/AudioTransResultBox";
import useDocBatchClient, {Sentence} from "../../../utils/docbatch_client";
import UploadableAudioBox from "../AudioTransOnce/UploadableAudioBox";




const AudioTransOnceApp = () => {
    const docBatchClient = useDocBatchClient()
    const [fileId, setFileId] = useState<string | null>(null)
    const [fileName, setFileName] = useState<string | null>(null)
    const [taskId, setTaskId] = useState<string | null>(null)
    const [taskStatus, setTaskStatus] = useState<string>("idle")
    const [progressText, setTaskProgressText] = useState<string>("")
    const [progressValue, setTaskProgressValue] = useState<number>(0)
    const [sentences, setSentences] = useState<Sentence[]>([])
    const [currentPlayingSentenceIndex, setCurrentPlayingSentenceIndex] = useState<number>(-1)
    const [fromLang, setFromLang] = useState<LangInfo>({value: "en", label: "英语"})
    const [toLang, setToLang] = useState<LangInfo>({value: "zh", label: "中文"})
    const [transEngine, setTransEngine] = useState<Engine>({value: "niu", label: "组合模型"})

    const [isWorking, setIsWorking] = useState(false)




    const handleOnTranslateClick = async () => {
        setIsWorking(true)
        const tid = await docBatchClient.addTaskOne({
            task_type: "audio_trans",
            file_id: fileId!,
            file_name: fileName!,
            audio_trans_option: {
                asr_lang_code: fromLang.value,
                trans_engine: transEngine.value,
                trans_to_lang_code: toLang.value
            }
        })
        console.log("tid is " + tid)
        setTaskId(tid)
    }


    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps" },
                    { label: "音频翻译", path: "/apps/audiotransonce", active: true },
                ]}
                title={"音頻翻译"}
            />
            <Row>
                <Col md={12} xl={12}>
                    <div className="tool-bar">
                        <TransLangSelectGroup
                            defaultFromLang={{value: "en", label: "英语"}}
                            defaultToLang={{value: "zh", label: "中文"}}
                            defaultEngine={{value: "niu", label: "组合模型"}}
                            onFromLangChanged={(e)=>{setFromLang(e)}}
                            onToLangChanged={(e)=>{setToLang(e)}}
                            onEngineChanged={(e) => {setTransEngine(e)} }
                        />

                        <Button
                            onClick={handleOnTranslateClick}
                            disabled={isWorking}
                        >翻译
                        </Button>
                        <span>{
                            taskStatus === 'idle'? "正在排队" :
                                taskStatus === 'processing'? `正在处理` :
                                    taskStatus === 'finished'? "处理成功" :
                                        taskStatus === 'error'? "处理失败" : "未知状态"
                        }</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}>
                    <UploadableAudioBox
                        onAudioFileChanged={(fileId, fileName, fileUrl) => {
                            setFileId(fileId)
                            setFileName(fileName)
                        }}
                        onTimeUpdate={(currentTime) => {
                            /*
                            let i = 0
                            for(let sen of sentences) {
                                if (currentTime >= sen.start && currentTime < sen.stop) {
                                    // setCurrentSubTitle(sen['text'])
                                    setCurrentPlayingSentenceIndex(i)
                                    console.log("index is " + i)
                                    break
                                }
                                i++
                            }
                             */

                        }
                        } />

                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}  style={{height: "50vh"}}>
                    <h5>翻译结果</h5>
                    <AudioTransResultBox  sentences={sentences} highLightIndex={currentPlayingSentenceIndex}></AudioTransResultBox>
                </Col>
            </Row>
        </>
    );
};

export default AudioTransOnceApp;
