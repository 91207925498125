import React, { useCallback, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Card,
  Dropdown,
  Row,
  Col,
  OverlayTrigger,
  Tooltip, Alert, Button, ButtonGroup, Form, InputGroup, Collapse,
} from "react-bootstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import marked from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai.css';  // 引入你选择的样式

import './messages.css'
// components
import { FormInput } from "../../../components/";
import Loader from "../../../components/Loader";

// default data
import {messages, ChatMessage, ChatUser, users, UserInputFile, KbChatConf} from "./data";

import avatar1 from "../../../assets/images/users/user-1.jpg";
import {configure} from "@testing-library/react";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import Conf from "../Utils/Conf";
import {ssePost, sseGet} from "../Utils/HttpUtil";
import Spinner from "../../../components/Spinner";
import { withSwal } from "react-sweetalert2";
import {APICore} from "../../../helpers/api/apiCore";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import ChatInput from "./ChatInput";
import useUploading from "../../../utils/uploading";
import ChatFileCard from "./ChatFileCard";
import ChatFileInBubble from "./ChatFileInBubble";
import {chatWsSendJson} from "../../../utils/chat_ws";
import useTts from "../../../utils/tts";
import useWebAppConfig from "../../../utils/WebAppConfig";
import classNames from "classnames";
import {KbDocBase} from "../KbChat/KbDocBases";

const from_cache = {

}
const UserMessage = ({
                       message,
                       toUser,
                     }: {
  message: ChatMessage;
  toUser: ChatUser;
}) => {
  const getMarkdownText = (src: string) => {
    const rawMarkup = marked(src, { sanitize: true });
    return { __html: rawMarkup };
  };


  marked.setOptions({
    gfm: true, // 启用 GitHub Flavored Markdown
    breaks: false, // 可以根据需要启用 GFM 换行符
    // 其他选项...
  });

  useEffect(() => {
    // 应用高亮
    document.querySelectorAll("pre code").forEach(block => {
      hljs.highlightBlock(block as HTMLElement);
    });
    document.querySelectorAll('table').forEach(table => {
      table.classList.add('table-style');
    });
  });


  const DocsItem = ({from_uuid}: {from_uuid: string}) => {
    const [docs, setDocs] = useState<any[]>([])
    const [collapse, setCollapse] = useState<boolean>(false);
    const toggleContent = () => {
      setCollapse(!collapse);
    };
    useEffect(() => {
      if(from_cache.hasOwnProperty(from_uuid)) {
        // @ts-ignore
        setDocs(from_cache[from_uuid])
      } else {
        auth_fetch_post_json(Conf.urlChatFromInfo, {from_uuid: from_uuid})
            .then((data: any) => {
              console.log(data)
                // @ts-ignore
              from_cache[from_uuid] = data
              setDocs(data)
            })
            .catch((error) => {
              console.error('Error:', error);
            });
      }
    }, []);
    return (
        <div>
          <div><a href="#" onClick={toggleContent}>
            <span style={{display: "inline"}}>信息来源</span><i className={classNames("mdi", {
            "mdi-chevron-up": collapse,
            "mdi-chevron-down": !collapse,
          })}  style={{display: "inline"}}></i>
          </a></div>

          <Collapse in={collapse}>
            <div>
              {docs.map((doc, index) => {
                return (
                    <div key={"doc" + index} dangerouslySetInnerHTML={getMarkdownText(doc)}></div>
                )
              })}
            </div>
          </Collapse>

        </div>
    )
  }

  return (
      <li className={classnames("clearfix", {odd: false && message.from.id === toUser.id})}>
        <div className="chat-avatar">
          <img src={message.from.avatar} className="rounded" alt=""/>
        </div>

        <div className="conversation-text">
          {message.message.type === "text" && (
              <div className="ctext-wrap">
                <i>{message.from.name}</i>
                <Row>
                  {
                    message.files?.map((file: UserInputFile) => {
                      return (
                          <Col xl={12} lg={12}>
                            <ChatFileInBubble key={file.fileId} userInputFile={file}></ChatFileInBubble>
                          </Col>
                      )
                    })
                  }
                </Row>
                {/*message.message.type === "text" && <ReactMarkdown children={message.message.value + ""} rehypePlugins={[remarkGfm]} ></ReactMarkdown>*/}
                {message.message.type === "text" && <div dangerouslySetInnerHTML={getMarkdownText(message.message.value + "")} ></div>}
                {message.from_uuid && <DocsItem from_uuid={message.from_uuid}></DocsItem>}

              </div>
          )}
          {message.message.type === "typing" && (
              <div className="ctext-wrap">
                <i>{message.from.name}</i>
                <span>
                <Spinner className="spinner-border-sm" />
              </span>
              </div>
          )}
          {message.message.type === "file" && (
              <Card className="mt-2 mb-1 shadow-none border text-start">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <div className="avatar-sm">
                    <span className="avatar-title bg-primary rounded">
                      .ZIP
                    </span>
                      </div>
                    </Col>
                    <Col className="ps-0">
                      <Link to="#" className="text-muted fw-bold">
                        {message.message.value.file}
                      </Link>
                      <p className="mb-0">{message.message.value.size}</p>
                    </Col>
                    <Col className="col-auto">
                      <Link to="#" className="btn btn-link btn-lg text-muted">
                        <i className="dripicons-download"></i>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Card>
          )}
        </div>


        <Dropdown
            className="conversation-actions"
            align={message.from.id === toUser.id ? "start" : "end"}
        >

          <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
            <i className="mdi mdi-dots-vertical font-16"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={()=> {

              const textarea = document.createElement('textarea');
              textarea.value = message.message.value;
              document.body.appendChild(textarea);
              textarea.select();
              document.execCommand('copy');
              document.body.removeChild(textarea);
            }}>
              复制信息
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
  );
};

interface ChatAreaProps {
  selectedUser: ChatUser;
  chatDocBaseUuid?: string;
  kbChatConf?: KbChatConf;
}
interface SwalProps {
  swal: any;
}


// ChatArea
const ChatArea = withSwal(({ selectedUser, chatDocBaseUuid, swal, kbChatConf }: ChatAreaProps & SwalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userMessages, setUserMessages] = useState<ChatMessage[]>([]);
  const [prompts, setPrompts] = useState([]);
  const [inputingMessage, setInputingMessage] = useState('');
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const [userInputFiles, setUserInputFiles] = useState<UserInputFile[]>([])
  const foUploader = useUploading(Conf.urlSecaiFoSend)
  const [enableSpeak, setEnableSpeak] = useState(false)
  const tts = useTts();
  const messageAudioControl = useRef<HTMLAudioElement>();
  const api = new APICore();
  const webAppConfig = useWebAppConfig()


  const [toUser] = useState<ChatUser>({
    id: 9,
    name: "我",
    avatar: avatar1,
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Friends",
  });

  /*
   *  Fetches the messages for selected user
   */
  const getMessagesForUser = useCallback(() => {
    if (selectedUser) {
      setLoading(true);
      const api = new APICore();

      auth_fetch_post_json(Conf.urlApiChatList, {
        'robotId': selectedUser.robotId
      })
          .then((data:any) => {
            const messages = data.map((m: any) => {
              const robot = selectedUser
              const user = toUser
              return {
                id: m.id,
                from: m.role == "user"? user: robot,
                to: m.role == "robot"? robot: user,
                sendOn: m.sendOn,
                message: m.message,
                files: m.files,
                has_from: m.has_from,
                from_uuid: m.from_uuid
              }
            })
            setUserMessages(messages)
            setLoading(false)
          })
          .catch((error) => {
            console.error('Error:', error);
            setLoading(false)
          });
    }
  }, [selectedUser, toUser]);

  useEffect(() => {
    getMessagesForUser();
  }, [getMessagesForUser]);

  useEffect(() => {
    getPromptDemos()
  }, [selectedUser]);


  useEffect(() => {
    webAppConfig.loadWebAppConfig()
  }, []);


  useEffect(() => {
    if(foUploader.fileUploaded) {
      console.log("new file uploaded " + foUploader.fileId)
      const files = [...userInputFiles]
        files.push({
            fileName: foUploader.fileName,
            fileId: foUploader.fileId,
            fileSize: foUploader.fileSize
        })
        setUserInputFiles(files)
    }
  }, [foUploader.fileUploaded])
  const handleUploadFile = () => {
    const mimePdfAndTextAndDocx = "application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    foUploader.startUpload(mimePdfAndTextAndDocx)

  }
  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
      yup.object().shape({
        newMessage: yup.string().required("Please enter your messsage"),
      })
  );

  const getPromptDemos = useCallback(() => {
    if(!selectedUser) {
      return
    }
    auth_fetch_post_json(Conf.urlPromptDemoList, {
      "robot_id": selectedUser.robotId,
    })
        .then((data: any) => {
          setPrompts(data)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }, [selectedUser])



  /*
   * form methods
   */
  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = methods;

  /**
   * sends the chat message
   */
  const sendChatMessage = async (values: { newMessage: string }) => {
    setTextareaHeight('auto');
    let newUserMessages = [...userMessages];
    newUserMessages.push({
      id: userMessages.length + 1,
      from: toUser,
      to: selectedUser,
      message: {
        type: "text",
        value: values["newMessage"]
      },
      files: userInputFiles,
      sendOn: new Date().getHours() + ":" + new Date().getMinutes(),
    });
    setUserInputFiles([])

    newUserMessages.push({
      id: userMessages.length + 1,
      from: selectedUser,
      to: toUser,
      message: { type: "typing", value: 'loading...' },
      sendOn: new Date().getHours() + ":" + new Date().getMinutes(),
    })
    setUserMessages(newUserMessages);

    const outgoingJson = {
      'message': values["newMessage"],
      'robot': selectedUser.robotId,
      'docbase_uuid': chatDocBaseUuid,
      'kb_chat_conf': kbChatConf,
      "stream": true,
      'file_ids': userInputFiles.map((file: UserInputFile) => {
        return file.fileId
      })
    }

    const handleReceivedData = (data: string) => {
      let otherNewMessages = [...newUserMessages]
      let parts = data.split("|\n|\n")
      parts.forEach((part: string) => {
        if(part!= "") {
          const obj = JSON.parse(part)
          if (obj['event'] == 'token') {
            msg += obj['data']
            otherNewMessages[otherNewMessages.length - 1]['from'] = selectedUser
            otherNewMessages[otherNewMessages.length - 1]['to'] = toUser
            otherNewMessages[otherNewMessages.length - 1]['message'] = {type: "text", value: msg}
            setUserMessages(otherNewMessages);
          } else if (obj['event'] == 'from_info') {
            otherNewMessages[otherNewMessages.length - 1]['from'] = selectedUser
            otherNewMessages[otherNewMessages.length - 1]['to'] = toUser
            otherNewMessages[otherNewMessages.length - 1]['from_uuid'] = obj['from_uuid']
            setUserMessages(otherNewMessages);
          }  else if (obj['event'] == 'error') {
            swal.fire({
              title: '错误',
              text: obj['data'],
              icon: 'error',
              confirmButtonText: '确定'
            })
          }
        }
      })
    }
    const webSocketMode =  false
    const packetToSend = webSocketMode?
        {
          action: 'chat',
          token: api.isUserAuthenticated() ? '' + api.getLoggedInUser().token : '',
          chat_data: outgoingJson
        }
        :
        outgoingJson
    console.log("packetToSend ")
    console.log(packetToSend)
    let msg = ''

    if(webSocketMode) {
      chatWsSendJson(
          Conf.urlChatWs,
          packetToSend,
          (data: string) => {
            console.log(JSON.stringify(data))
            handleReceivedData(data)
          },
          (err) => {},
          () => {}

      )
    } else {
      await ssePost(
          Conf.urlChatSse,
          packetToSend,
          (data: any) => {
            console.log("data: " + data)
            handleReceivedData(data)
          },
          () => {
            if(enableSpeak) {
              tts.callTts("0", msg, (data: any) => {
                console.log("callback " + data)
              })
            }
          },
          (msg: string) => {

          }
      )
    }






    // setUserMessages(newUserMessages);
    reset();
  };

  const cleanMessages = () => {
    auth_fetch_post_json(Conf.urlApiChatClean, {
        'robotId': selectedUser.robotId
    })
        .then((data: any) => {
              setUserMessages([])
            }
        )
        .catch((error) => {
              console.error('Error:', error);
            }
        );
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (elementRef && elementRef.current && elementRef.current.scrollIntoView) {
        elementRef.current.scrollIntoView({behavior:'smooth'})
      }
    })
    return <div ref={elementRef} />
  }


  return (
      <>
        <Card>
          <Card.Body className="py-2 px-3 border-bottom border-light">
            <Row className="justify-content-between py-1">
              <Col sm={7}>
                <div className="d-flex align-items-start">
                  <img
                      src={selectedUser.avatar}
                      className="me-2 rounded-circle"
                      height="36"
                      alt="Brandon Smith"
                  />
                  <div>
                    <h5 className="mt-0 mb-0 font-15">
                      <Link to="/apps/contacts/profile" className="text-reset">
                        {selectedUser.name}
                      </Link>
                    </h5>
                    <p className="mt-1 mb-0 text-muted font-12">
                      <small className="mdi mdi-circle text-success"></small>{" "}
                      在线
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="col-auto">
                <div>
                  <Button
                      size={"sm"}
                      variant={"outline-secondary"}
                      className="waves-effect waves-light me-2"
                      onClick={()=>setEnableSpeak(!enableSpeak)}
                  >
                    <i className={enableSpeak? "fe-volume-1": "fe-volume-x"}></i>
                  </Button>
                  <Button size={"sm"}  variant={"outline-secondary"} className="waves-effect waves-light me-2" onClick={()=> cleanMessages()}> <i className="fe-trash-2"></i></Button>
                  <Dropdown as={ButtonGroup} size={"sm"}>
                    <Dropdown.Toggle variant="outline-secondary">
                      <i className="mdi mdi-chevron-down">提问帮助</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header>你还可以这样提问</Dropdown.Header>
                      {
                        prompts.map((prompt: any) => {
                          return (
                              <Dropdown.Item key={"prompt_demo_id_" + prompt.prompt_demo_id} onClick={()=> {
                                sendChatMessage({
                                  'newMessage': prompt.prompt
                                })
                              }}>{prompt.prompt}</Dropdown.Item>
                          )
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Body>
            {loading && <Loader />}

            <SimpleBar
                style={{ height: "60vh", width: "100%" }}
                id="chatScrollBar"
            >
              <ul
                  className="conversation-list"
              >
                {(userMessages || []).map((message, index) => {
                  return (
                      <UserMessage key={index} message={message} toUser={toUser} />
                  );
                })}
                <AlwaysScrollToBottom />
              </ul>
            </SimpleBar>

            <Row>
              <Col>
                <div className="mt-3 bg-light p-3 rounded">
                  <div className = "row">
                    {
                      foUploader.uploading && (
                            <Col  xl={12} lg={12}>
                              <Alert color={'danger'}>正在发送文件</Alert>
                            </Col>
                        )
                    }

                    {
                      userInputFiles.map((file: UserInputFile) => {
                        return (<Col xl={6} lg={6}>
                          <ChatFileCard key={file.fileId} userInputFile={file} onCloseClicked={() => {
                            const files = userInputFiles.filter((f: UserInputFile) => {
                              return f.fileId != file.fileId
                            })
                            setUserInputFiles(files)
                          }}></ChatFileCard>
                        </Col>)
                      })
                    }

                  </div>
                  <div className="row">
                    <div className="col mb-2 mb-sm-0">


                      <FormInput
                          type="textarea"
                          name="newMessage"
                          className="border-0"
                          placeholder="随便说点什么, 回车发送，Shift+Enter换行"
                          register={register}
                          key="newMessage"
                          errors={errors}
                          control={control}
                          value={inputingMessage}
                          style={{ overflow: 'hidden', resize: 'none', height: textareaHeight }}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                              e.preventDefault();
                              if(inputingMessage != '') {
                                sendChatMessage({
                                  'newMessage': inputingMessage
                                });
                                setInputingMessage('');
                                setTimeout(() => {
                                  e.target.style.height = 'auto'; // 重置高度
                                  e.target.style.height = e.target.scrollHeight + 'px'; // 设置为内容的高度
                                }, 0);
                              }
                            }
                          }}
                          onChange={(e: any) => {
                            setInputingMessage(e.target.value);

                            // 动态调整高度
                            setTimeout(() => {
                                e.target.style.height = 'auto'; // 重置高度
                                e.target.style.height = e.target.scrollHeight + 'px'; // 设置为内容的高度
                                }, 0);
                          }}
                      />



                    </div>
                    <div className="col-sm-auto">
                      <div className="btn-group">
                        {
                        <Button hidden={!webAppConfig.allowFileInChat} className="btn btn-light" onClick={handleUploadFile}>
                          <i className="fe-paperclip"></i>
                        </Button>
                        }
                        <button
                            type="button"
                            className="btn btn-success chat-send w-100"
                            disabled={inputingMessage == ''}
                            onClick={() => {
                                if(inputingMessage != '') {
                                    sendChatMessage({
                                    'newMessage': inputingMessage
                                    })
                                    setInputingMessage('')
                                }
                            }}
                        >
                          <i className="fe-send"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <audio src={tts.ttsResult} controls={true} autoPlay={enableSpeak} hidden={true}></audio>
      </>
  );
});

export default ChatArea;
