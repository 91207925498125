
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine} from "../WidgetAppParam";


export class ActivityPlanApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextLine("subject", "活动主题", "请输入活动主题", "活动主题"),
            new WidgetAppParamTextLine("date", "活动日期", "请输入活动日期", "活动日期"),
            new WidgetAppParamTextArea("content", "活动内容", "请输入活动内容", "活动内容"),

        ];
    }

    template: string = "ActivityPlan";
    title: string = "活动方案";

}

export default ActivityPlanApp;

