import {Button, Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import {UserInputFile} from "./data";

interface ChatFileCardProps {
    userInputFile: UserInputFile
    onCloseClicked: () => any
}
const ChatFileCard = (props: ChatFileCardProps) => {

    return (

        <Card className="m-1 shadow-none border">
            <Button variant="outline-secondary" className="btn-sm position-absolute top-0 end-0 m-2"
            onClick={() => props.onCloseClicked()}
            >
                <i className="mdi mdi-close"></i>
            </Button>
            <div className="p-2">
                <Row className="align-items-center">
                    <Col className="col-auto pe-0">
                        <div className="avatar-sm">
                          <span className="avatar-title bg-light text-secondary rounded">
                            <i className='fe-paperclip'></i>
                          </span>
                        </div>
                    </Col>
                    <Col>
                        <div className="text-muted fw-bold"
                              style={{
                                  width : 'calc(100% - 40px)',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap'
                        }}
                        >
                            {props.userInputFile.fileName}
                        </div>
                    </Col>
                </Row>
            </div>
        </Card>

    )
}

export default ChatFileCard;