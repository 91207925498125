


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_pptoutline.png";

interface PptOutlineWidgetProps extends WidgetProps {
    pad?: string;
}
export class PptOutlineWidget extends BaseWidget<any, PptOutlineWidgetProps> {
    title: string = "PPT大纲生成";
    desc: string = "根据输入内容生成PPT大纲";
    routePath: string = "/apps/aitool/widgets/pptoutline/pptoutlineapp";
    iconUrl: string = avatar;

}



