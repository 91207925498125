import {Card, Col, Dropdown, Row} from "react-bootstrap";
import Chart from "react-apexcharts";
import ChartStatistics from "../../../components/ChartStatistics";
import React from "react";
import {ApexOptions} from "apexcharts";

export interface UsageChartProps {
    usageName: string
    usagePercent: number
    chartLabel: string
    totalLabel: string
    totalValue: string
    usedLabel: string
    usedValue: string
    freeLabel: string
    freeValue: string
}


const UsageChart = (props: UsageChartProps) => {
    const apexOpts: ApexOptions = {
        chart: {
            height: 242,
            type: "radialBar",
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: "65%",
                },
            },
        },
        colors: ["#f1556c"],
        labels: [props.chartLabel],
    };


    return (
        <Card>
            <Card.Body>

                <h4 className="header-title mb-0">{props.usageName}</h4>

                <div className="widget-chart text-center" dir="ltr">
                    <Chart
                        options={apexOpts}
                        series={[props.usagePercent]}
                        type="radialBar"
                        height={242}
                        className="apex-charts mt-0"
                    />

                    <h5 className="text-muted mt-0">{props.freeLabel}<strong>{props.freeValue}</strong></h5>
                    <h5 className="text-muted mt-0">{props.usedLabel}<strong>{props.usedValue}</strong></h5>
                    <h5 className="text-muted mt-0">{props.totalLabel}<strong>{props.totalValue}</strong></h5>
                </div>
            </Card.Body>
        </Card>
    )
}

export default UsageChart;