


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_flowchart.png";

interface FlowChartWidgetProps extends WidgetProps {
    pad?: string;
}
export class FlowChartWidget extends BaseWidget<any, FlowChartWidgetProps> {
    title: string = "流程图绘制";
    desc: string = "帮你绘制流程图";
    routePath: string = "/apps/aitool/widgets/flowchart/flowchartapp";
    iconUrl: string = avatar;


}



