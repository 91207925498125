


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_poetrytool.png";

interface PoetryToolWidgetProps extends WidgetProps {
    avatar: string;
    name: string;
    position: string;
}
export class PoetryToolWidget extends BaseWidget<any, PoetryToolWidgetProps> {
    title: string = "诗词创作";
    desc: string = "帮助创作诗词";
    routePath: string = "/apps/aitool/widgets/poetrytool/poetrytoolapp";
    iconUrl: string = avatar;
}



