
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine} from "../WidgetAppParam";


export class SwotApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextLine("subject", "调研主题", "请输入调研主题", "活动主题"),
            new WidgetAppParamTextArea("content", "调研内容", "请输调研内容", "调研内容"),
        ];
    }

    template: string = "Swot";
    title: string = "SWOT分析";
}

export default SwotApp;

