import React, { useEffect, useState } from "react";
import {Row, Col, Card, Button, Form} from "react-bootstrap";
import "@fullcalendar/react";


import PageTitle from "../../../components/PageTitle";



import Select from "react-select";

import Conf from "../Utils/Conf";
import useUploading from "../../../utils/uploading";
import useOcr from "../../../utils/ocr";
import UploadingProgressModal from "../../../utils/UploadingProgressModal";
import ErrorModal from "../../../utils/ErrorModal";
import useFilePoster from "../../../utils/fileposter";
import useWebAppConfig from "../../../utils/WebAppConfig";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import useDocTrans from "../../../utils/doctrans";
import {BasicPortlet} from "../../../components/Portlet";






const OcrApp = () => {
    /*
     * modal handeling
     */

    const uploading = useUploading(Conf.urlSecaiFoSend);
    const ocr = useOcr()
    const docTrans = useDocTrans()
    const [langCode, setLangCode] = useState("ch")
    const [transToLangName, setTransToLangName] = useState("中文")
    const [transFromLangName, setTransFromLangName] = useState("英语")
    const [transEnabled, setTransEnabled] = useState(false)
    const webAppConfig = useWebAppConfig()
    useEffect(() => {
        webAppConfig.loadWebAppConfig()
    }, [])


    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/ocr" },
                    { label: "OCR识别", path: "/apps/ocr", active: true },
                ]}
                title={"OCR识别"}
            />

            <Row>
                <Col lg={6} md={6} sm={12}>
                    <Card>
                        <Card.Header>图像</Card.Header>
                        <Card.Body  style={{height: "400px"}}>
                            <img src={uploading.fileUrl} width={"100%"} height={"100%"} hidden={uploading.fileUrl == ""}/>

                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col hidden={!webAppConfig.hasSecret}>
                                    <Select
                                        className="react-select react-select-container"
                                        classNamePrefix="react-select"
                                        options={
                                            [
                                                {"value": "private", "label": "内部"},
                                                {"value": "public", "label": "非涉密"}
                                            ]
                                        }
                                        defaultValue={
                                            {"value": "private", "label": "内部"}
                                        }
                                    ></Select>
                                </Col>
                                <Col>
                                    <div className="col d-flex" style={
                                        {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }
                                    }
                                    >
                                        <Button variant="primary" className="mr-2 me-4"
                                                onClick={() => uploading.startUpload('image/jpeg')}>
                                            <i className="bi bi-upload me-1"></i>
                                        </Button>

                                        <Select
                                            className="react-select react-select-container me-2"
                                            classNamePrefix="react-select"
                                            options={ocr.ocrLangs}
                                            defaultValue={{"value": "ch", "label": "中文"}}
                                            onChange={x => {
                                                setLangCode(x ? x.value : 'zh')
                                                setTransFromLangName(x? x.label : '中文')
                                            }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '120px',
                                                }),
                                            }}
                                        ></Select>

                                        <Button variant="danger" className="mr-1 me-2 " disabled={ocr.isOcring}
                                                onClick={() => ocr.startOcr(uploading.fileId, langCode, transEnabled, transFromLangName, transToLangName)}>
                                            <i className="bi bi-translate me-1"></i>识别
                                        </Button>
                                        <Form.Check
                                            type="checkbox"
                                            label={"自动翻译成中文"}
                                            checked={transEnabled}
                                            onChange={e => setTransEnabled(e.target.checked)}

                                        />

                                    </div>
                                </Col>

                            </Row>
                            <Row hidden={!webAppConfig.hasSecret}>
                                <Col>
                                    <h5 className={"text-danger"}
                                        hidden={!webAppConfig.hasSecret}>本产品密级为<strong>内部</strong>，
                                        请不要上传更高安全级别的文件</h5>
                                </Col>
                            </Row>

                        </Card.Footer>
                    </Card>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <Card>
                        <Card.Header>识别标注</Card.Header>
                        <Card.Body  style={{height: "400px"}}>
                            <img src={ocr.ocrResult.imageUrl? Conf.backendApi(ocr.ocrResult.imageUrl): ""} width={"100%"} height={"100%"} hidden={ocr.ocrResult.imageUrl == undefined || ocr.ocrResult.imageUrl == ""}/>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="primary" className="mr-1" disabled={ocr.ocrResult.imageUrl == undefined || ocr.ocrResult.imageUrl == ""} onClick={()=>window.open(ocr.ocrResult.imageUrl)}><i className={"bi bi-cloud-download me-1"}></i>下载</Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Row>
                    <Col>
                        <BasicPortlet titleClass="card-title" allowClose={false} allowReload={false} cardTitle={"识别文本"}>
                            <div>
                                <textarea className="form-control" id="tares" rows={10} value={ocr.ocrResult.txt} readOnly></textarea>
                            </div>
                        </BasicPortlet>
                    </Col>
                </Row>
                {transEnabled && (
                    <Row>
                        <Col>
                            <BasicPortlet titleClass="card-title" allowClose={false} allowReload={false} cardTitle={"翻译文本"}>
                                <div>
                                    <textarea className="form-control" id="tares" rows={10} value={ocr.ocrResult.translatedText}
                                              readOnly></textarea>
                                </div>
                            </BasicPortlet>
                        </Col>
                    </Row>
                )}

            </Row>
            <UploadingProgressModal fileName={uploading.fileName} fileSize={uploading.fileSize} progress={uploading.progress} statusText={uploading.statusText} show={uploading.uploading} onHide={()=> {}} onStopClicked={()=> {}}></UploadingProgressModal>
            <ErrorModal show={uploading.errorMessage!=''} title={"上传错误"} message={uploading.errorMessage}></ErrorModal>
            <ErrorModal show={ocr.errorMessage!=''} title={"OCR错误"} message={ocr.errorMessage}></ErrorModal>


        </>
    );
};

export default OcrApp;
