
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea} from "../WidgetAppParam";


export class MindMapApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextArea("subject", "思维导图主题", "请输入思维导图的主题", "思维导图主题")
        ];
    }

    template: string = "MindMap";
    title: string = "思维导图";
}

export default MindMapApp;

