
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea} from "../WidgetAppParam";


export class PsychologyWikiApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextArea("question", "心理知识科普", "请输入要提问的心理知识,比如:什么是厌学情绪", "心理知识问题"),

        ];
    }

    template: string = "PsychologyWiki";
    title: string = "心理知识科普";

}

export default PsychologyWikiApp;

