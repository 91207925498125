
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine} from "../WidgetAppParam";


export class NetSecToolApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextArea("outline", "你想完成什么功能?", "请输入您要完成的功能的介绍", "功能"),
        ];
    }

    template: string = "NetSecTool";
    title: string = "网络安全助手";
}

export default NetSecToolApp;

