import Select, { components, OptionProps, SingleValueProps } from "react-select";
import React, {useEffect, useState} from "react";
import { ChatUser, users } from "./data";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import useWebAppConfig, {ModelConfig} from "../../../utils/WebAppConfig";
import {set} from "react-hook-form";

interface ModelSelectorProps {

    onModelChanged: (model: ModelConfig) => void
    usingInTrans?: boolean
}
export const ModelSelector = (props: ModelSelectorProps) => {
    const webCfg = useWebAppConfig()
    const [selectedModel, setSelectedModel] = useState<ModelConfig | null>(null)
    useEffect(() => {
        webCfg.loadWebAppConfig()

    }, []);

    useEffect(() => {
        if(webCfg.models.length > 0) {
            setSelectedModel(webCfg.models[0])
            props.onModelChanged(webCfg.models[0])
        } else {
            setSelectedModel(null)

        }
    }, [webCfg.models]);
    return (
        <Dropdown as={ButtonGroup} size={"md"}>
            <Dropdown.Toggle variant="outline-secondary">
                {selectedModel?.modelDisplayName + (props.usingInTrans? '+NLLB组合模型':'') || "没有可用模型"}
                <i className="mdi mdi-chevron-down"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>您还可以使用下面的基础大模型</Dropdown.Header>
                <Dropdown.Divider />
                {
                    webCfg.models.map((model: ModelConfig, index: number) => {
                        return (
                            <Dropdown.Item key={"prompt_demo_id_" + index} onClick={async () => {

                                props.onModelChanged(model)
                                setSelectedModel(model)
                            }}>{model.modelDisplayName + (props.usingInTrans? '+NLLB组合模型':'')}</Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}