


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_swot.png";

interface SwotWidgetProps extends WidgetProps {
    pad?: string;
}
export class SwotWidget extends BaseWidget<any, SwotWidgetProps> {
    title: string = "SWOT分析";
    desc: string = "帮助你生成SWOT分析";
    routePath: string = "/apps/aitool/widgets/swot/swotapp";
    iconUrl: string = avatar;


}



