import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

// components
import PageTitle from "../../../components/PageTitle";

import ChatDocBases, {ChatDocBase} from "./ChatDocBases";

// dummy data
import { users, ChatUser } from "./data";
import avatar_chathelper_b from "../../../assets/images/aichat/bots/chathelper_b.png";
import ChatArea from "../AiChat/ChatArea";


// AiChatApp
const AiChatApp = () => {
  const [selectedUser, setSelectedUser] = useState<ChatUser>({
          id: 2,
          robotId: "chatFile",
          name: "内部知识库",
          avatar: avatar_chathelper_b,
          lastMessage: "",
          totalUnread: 3,
          lastMessageOn: "4:30am",
          email: "support@coderthemes.com",
          phone: "+1 456 9595 9594",
          location: "California, USA",
          languages: "English, German, Spanish",
          groups: "Work, Favourties",
      }
  );

  const [selectedChatDocBase, setSelectedChatDocBase] = useState<ChatDocBase>();




    const onChatDocBaseChange = (docBase: ChatDocBase) => {
        setSelectedChatDocBase(docBase);
    };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "应用", path: "/apps/chatfile" },
          { label: "内部知识库", path: "/apps/chatfile", active: true },
        ]}
        title={"内部知识库"}
      />

      <Row>
        <Col xs={12} sm={6} md={6} lg={4} xl={3}>
          <ChatDocBases onChatDocBaseSelect={onChatDocBaseChange} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={8} xl={9}>
          <ChatArea selectedUser={selectedUser} chatDocBaseUuid={selectedChatDocBase?.uuid} />
        </Col>
      </Row>
    </>
  );
};

export default AiChatApp;
