


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_psychologywiki.png";

interface PsychologyWikiWidgetProps extends WidgetProps {
    pad?: string;
}
export class PsychologyWikiWidget extends BaseWidget<any, PsychologyWikiWidgetProps> {
    title: string = "心理知识科普";
    desc: string = "帮助咨询者了解心理学知识";
    routePath: string = "/apps/aitool/widgets/psychologywiki/psychologywikiapp";
    iconUrl: string = avatar;


}



