import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";
import welcome from "../../../assets/images/welcome.jpg";

// components
import PageTitle from "../../../components/PageTitle";
import FAQs from "../../../components/FAQs";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";


interface RawFaqs {
    id: number;
    question: string;
    answer: string;
}

const WelcomeApp = () => {

    const [rawFaqs, setRawFaqs] = useState<RawFaqs[]>([]);
    const [bigTitle, setBigTitle] = useState("")
    const [smallTitle, setSmallTitle] = useState("")

    const loadFaqs = async () => {
        auth_fetch_post_json(Conf.urlWelcomeFaqList, {
            'is_welcome': true
        }).then((json: any)=> {
            if(json.status) {
                const xs = json.data
                let id = 0
                setRawFaqs(xs.map((x: any) => {
                    return {
                        id: id++,
                        question: x.question,
                        answer: x.answer
                    }
                }))
            } else {
                console.log(json)
            }
        }).catch(error => console.log(error))
    }

    const loadTitles = async () => {
        auth_fetch_post_json(Conf.urlWebCustomGetStr, {
            'k': "welcome_big_title"
        }).then((json: any)=> {
            if(json.status) {
                setBigTitle(json.v)
            } else {
                console.log(json)
            }
        }).catch(error => console.log(error))
        auth_fetch_post_json(Conf.urlWebCustomGetStr, {
            'k': "welcome_small_title"
        }).then((json: any)=> {
            if(json.status) {
                setSmallTitle(json.v)
            } else {
                console.log(json)
            }
        }).catch(error => console.log(error))

    }
    useEffect(() => {
        loadTitles()
        loadFaqs()
    }, []);
    return (
        <>

            <PageTitle
                breadCrumbItems={[
                    { label: "欢迎", path: "/apps/welcome", active: true },
                ]}
                title={"欢迎使用AI助手"}
            />

            <Row>
                <Col>
                    <div className="text-center">
                        <i className="h1 mdi mdi-comment-multiple-outline text-muted"></i>
                        <h3 className="mb-3">{bigTitle}</h3>
                        <p className="text-muted">{smallTitle}</p>

                    </div>
                </Col>
            </Row>

            {/* question/answer */}
            <FAQs rawFaqs={rawFaqs} containerClass={"pt-5"} />

        </>
    );
};

export default WelcomeApp;
