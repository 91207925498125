import React, {useEffect, useState} from "react";
import {Modal, Button, ProgressBar} from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {bool} from "yup";


interface ErrorModalProps {
    show: boolean
    title: string;
    message: string
}

const ErrorModal = (props: ErrorModalProps) => {
    const [isShow, setIsShow] = useState(props.show)
    useEffect(() => {
        setIsShow(props.show)
    }, [props.show]);
    const handleOnClose = () => {
        setIsShow(false)
    }
    return (
        <>
            <Modal
                show={isShow}
                onHide={handleOnClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="bg-light" closeButton>
                    <Modal.Title className="m-0">{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <p>{props.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"btn btn-danger"} onClick={handleOnClose}>确定</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};


export default ErrorModal;
