import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";
import {bool, string} from "yup";

export interface ModelConfig {
    modelName: string
    modelDisplayName: string
}
export interface WebAppConfig {
    enableNameLogin: boolean
    enableGoalLogin: boolean
    allowFileInChat: boolean
    isLoading: boolean
    hasSecret: boolean
    models: ModelConfig[]

}
function useWebAppConfig() {



    const [enableNameLogin, setEnableNameLogin] = useState(false)
    const [enableGoalLogin, setEnableGoalLogin] = useState(false)
    const [allowFileInChat, setAllowFileInChat] = useState(false)
    const [hasSecret, setHasSecret] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [models, setModels] = useState<ModelConfig[]>([])





    const loadWebAppConfig = useCallback(() => {
        setIsLoading(true)
        setError(null)
        // @ts-ignore
        fetch(Conf.urlWebAppConfig,  {}, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((resp: { ok: any; json: () => any; }) => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error("连接服务器错误")
            }
        }).then((data: WebAppConfig) => {
            setEnableGoalLogin(data['enableGoalLogin'])
            setEnableNameLogin(data['enableNameLogin'])
            setAllowFileInChat(data['allowFileInChat'])
            setHasSecret(data['hasSecret'])
            setModels(data['models'])
            setError(null)
        }).catch((e: any) => {
            setError(e.message + " 请检查网络并刷新本页面")
        }).finally(()=> {
            setIsLoading(false)
        })
    }, [])

    return {
        enableNameLogin,
        enableGoalLogin,
        isLoading,
        error,
        loadWebAppConfig,
        allowFileInChat,
        hasSecret,
        models
    };
}

export default useWebAppConfig;
