import React, {useCallback, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

// dummy data
import { users, ChatUser } from "./data";

import profilePic from "../../../assets/images/users/user-1.jpg";
import Select from "react-select";
import Conf from "../Utils/Conf";
import {APICore} from "../../../helpers/api/apiCore";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";

export interface ChatDocBase {
  uuid: string;
  name: string;
}
interface ChatUsersProps {
  onChatDocBaseSelect: (value: ChatDocBase) => void;
}

// KbDocBases
const ChatDocBases = ({ onChatDocBaseSelect }: ChatUsersProps) => {
    const [docBases, setDocBases] = useState<any>([]);
    const [docs, setDocs] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState(docBases.length > 0 ? {
        value: docBases[0].uuid,
        label: docBases[0].name
    } : null);


    const getDocBases = useCallback(() => {
        const api = new APICore()

        auth_fetch_post_json(Conf.urlFileChatDocBaseList, {
            'group': 0
        })
            .then((data: any) => {
                setDocBases(data)
                if (data.length > 0) {
                    onChatDocBaseSelect({
                        uuid: data[0].uuid,
                        name: data[0].name
                    })
                    setSelectedValue({
                        value: data[0].uuid,
                        label: data[0].name
                    })
                } else {
                    onChatDocBaseSelect({
                        uuid: "",
                        name: ""
                    })
                    setSelectedValue(null)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                onChatDocBaseSelect({
                    uuid: "",
                    name: ""
                })
                setSelectedValue(null)
            });
    }, []);

    useEffect(() => {
        getDocBases();
    }, [])

    useEffect(() => {
        if (selectedValue) {
            auth_fetch_post_json(Conf.urlFileChatDocBaseDocList, {docbase_uuid: selectedValue.value}).then((data: any) => {
                setDocs(data)
            })

        }
    }, [selectedValue]);

    return (
        <>
            <Card>
                <Card.Body>
                    <h6 className="font-13 text-muted text-uppercase">知识库</h6>
                    <Select
                        className="react-select react-select-container mb-3"
                        classNamePrefix="react-select"
                        options={docBases.map((item: any) => {
                            return {
                                value: item.uuid,
                                label: item.name
                            }
                        })
                        }
                        value={selectedValue}

                        onChange={(e: any) => {
                            console.log("onChatDocBaseSelect change to " + e.value)
                            console.log(onChatDocBaseSelect)
                            onChatDocBaseSelect({
                                uuid: e.value,
                                name: e.label
                            })
                            setSelectedValue(e);
                        }}
                    ></Select>

                    {
                        docs.map((doc: any, index: number) => {
                            return (<div key={"doc_" + index}>
                                {doc['name']}
                            </div>)
                        })
                    }


                </Card.Body>
            </Card>
        </>
    );
};

export default ChatDocBases;
