import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import MyProfile from "./MyProfile";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import MyProfileUserBox from "./MyProfileUserBox";




// import AddEditEvent from "./AddEditEvent";

// dummy data
// import { defaultEvents } from "./data";

const MyProfileApp = () => {
    /*
     * modal handeling
     */
    const [show, setShow] = useState<boolean>(false);

    const onCloseModal = () => {
        setShow(false);
        setEventData({});
        setDateInfo({});
    };
    const onOpenModal = () => setShow(true);
    const [isEditable, setIsEditable] = useState<boolean>(false);

    /*
     * event data
     */
    // const [events, setEvents] = useState<EventInput[]>([...defaultEvents]);
    const [eventData, setEventData] = useState<EventInput>({});
    const [dateInfo, setDateInfo] = useState<any>({});


    useEffect(() => {
        // create dragable events
    }, []);

    /*
      calendar events
      */
    // on date click
    const onDateClick = (arg: DateClickArg) => {
        setDateInfo(arg);
        onOpenModal();
        setIsEditable(false);
    };

    // on event click
    const onEventClick = (arg: EventClickArg) => {
        const event = {
            id: String(arg.event.id),
            title: arg.event.title,
            className: arg.event.classNames[0],
        };
        setEventData(event);
        setIsEditable(true);
        onOpenModal();
    };




    // create new event
    const createNewEvent = () => {
        setIsEditable(false);
        onOpenModal();
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "我的账户", path: "/apps/myprofile", active: true },
                ]}
                title={"我的账户"}
            />

            <Row>
                <Col md={12} xl={12}>
                    <MyProfileUserBox></MyProfileUserBox>
                </Col>
            </Row>

        </>
    );
};

export default MyProfileApp;
