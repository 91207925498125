import {Alert, Button, ButtonGroup, Dropdown, Form, Modal, SplitButton} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import classNames from "classnames";
import useUploadingMany, {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import Spinner from "../../../components/Spinner";
import React, {useCallback, useEffect, useState} from "react";
import Conf from "../Utils/Conf";
import Select from "react-select";
import useOcr from "../../../utils/ocr";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import useDocTrans from "../../../utils/doctrans";
import useDocBatchClient, {TaskType, taskTypeFileInfos} from "../../../utils/docbatch_client";
import useAsr from "../../../utils/asr";

interface DocImportModalProps {
    visible: boolean
    onHide: () => void
    onTasks: (tasks: any) => void
    baseModel: string
}


interface LangInfo {
    value: string
    label: string
}



const DocImportModal = (props: DocImportModalProps) => {
    const methodsDocImport = useForm({});
    const docsUploader = useUploadingMany(Conf.urlSecaiFoSend)
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const docTrans = useDocTrans()
    const defaultFromLang =    {"value": "en", "label": "英语"}
    const defaultToLang =    {"value": "zh", "label": "中文"}


    const [fromLang, setFromLang] = useState<LangInfo>(defaultFromLang);
    const [toLang, setToLang] = useState<LangInfo>(defaultToLang);
    const [selectedTaskType, setSelectedTaskType] = useState<TaskType>('txt_trans')
    const docBatchClient = useDocBatchClient()
    const asr = useAsr()

    const getAcceptedExtensions = (taskType: TaskType) => {
        const acceptedExtensions = docBatchClient.getTaskTypeFileInfo(taskType).filters.flatMap(filter => filter.extensions.map(ext => `.${ext}`)).join(',');
        return acceptedExtensions

    }

    const addTask = async (values: any, event: any) => {
        console.log(values)
        console.log(event)
        setIsSaving(true)

        const json = {
            'base_model': props.baseModel,
            "from_lang_code": fromLang.value,
            "to_lang_code": toLang.value,
            "from_lang_name": fromLang.label,
            "to_lang_name": toLang.label,
            "trans_engine": "llm",
            "trans_mode": "batch",
            "items": [
                ...docsUploader.uploadedItems!.map((item: UploadingManyUploadedItem) => {
                    return {
                        "file_name": item.fileName,
                        "file_id": item.fileId,
                        'task_type': selectedTaskType,
                    }
                })
            ]
        }


        auth_fetch_post_json(Conf.urlSecAiDocBatchTasksAdd, json)
            .then((data: any) => {
                console.log("tasksAdd:");

                console.log(data)
                props.onTasks(data.data)
                props.onHide()
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(() => {
            setIsSaving(false)
            docsUploader.clean()
        })
        return false
    }

    const handleSwitchLang = () => {
        const tmpLang = fromLang
        setFromLang(toLang)
        setToLang(tmpLang)
    }


    const ofFromLangs = () => {
        if(selectedTaskType == 'txt_trans') return docTrans.docTransLangs
        if(selectedTaskType == 'audio_trans') return asr.asrLangs
        if(selectedTaskType == 'docx_trans') return docTrans.docTransLangs
        if(selectedTaskType == 'pdf_ocr_trans') return docTrans.docTransLangs
        if(selectedTaskType == 'pdf_txt_trans') return docTrans.docTransLangs
        if(selectedTaskType == 'pptx_trans') return docTrans.docTransLangs
        if(selectedTaskType == 'video_trans') return asr.asrLangs
        if(selectedTaskType == 'xlsx_trans') return docTrans.docTransLangs
        return docTrans.docTransLangs
    }
    return (
        <Modal show={props.visible} onHide={props.onHide} backdrop="static" size={"lg"}
               keyboard={false}>
            <FormProvider {...methodsDocImport}>
                <Form onSubmit={methodsDocImport.handleSubmit(addTask)}>
                    <Modal.Header closeButton>
                        <h4 className="modal-title">批量添加文档翻译任务</h4>
                    </Modal.Header>
                    <Modal.Body className="p-4">


                        <div className="col d-flex" style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }
                        }>
                            <Select
                                className="react-select react-select-container me-2"
                                classNamePrefix="react-select"
                                options={ofFromLangs()}
                                defaultValue={defaultFromLang}
                                value={fromLang}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '160px',
                                    }),
                                }}
                                onChange={x => {
                                    setFromLang(x ? x : defaultFromLang)
                                }}
                            ></Select>
                            <Button onClick={() => handleSwitchLang()} variant="default"
                                    className="btn-info waves-effect waves-light  me-2">
                                <i className="fas fa-exchange-alt"></i>
                            </Button>
                            <Select
                                className="react-select react-select-container me-3"
                                classNamePrefix="react-select"
                                options={docTrans.docTransLangs}
                                value={toLang}
                                defaultValue={defaultToLang}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '160px',
                                    }),
                                }}
                                onChange={x => setToLang(x ? x : defaultToLang)}
                            ></Select>
                            <Dropdown
                                as={ButtonGroup}
                                onSelect={(e: any) => {
                                    if (e) {
                                        setSelectedTaskType(e)
                                    }
                                }}
                            >
                                <Button
                                    variant={'primary'}
                                    disabled={docsUploader.isUploading || isSaving}
                                    onClick={() => {
                                        docsUploader.startUpload(
                                            getAcceptedExtensions(selectedTaskType)
                                        )
                                    }}
                                >
                                    <i className={"icon-cloud-upload me-1"}></i>添加{docBatchClient.getTaskTypeFileInfo(selectedTaskType).name}
                                </Button>
                                <Dropdown.Toggle
                                    disabled={docsUploader.isUploading || isSaving}
                                    split variant={'primary'}>
                                    <i className="mdi mdi-chevron-down"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        taskTypeFileInfos.map(t => <Dropdown.Item key={t.type}
                                                                                  eventKey={t.type}>{t.name}</Dropdown.Item>)
                                    }
                                    <Dropdown.Divider/>
                                    <Dropdown.Item eventKey="add_help">帮助</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <button type={"button"}
                                    className={"btn btn-outline-danger ms-2"}
                                    disabled={docsUploader.isUploading || isSaving} onClick={() => {
                                docsUploader.clean()
                            }}>
                                <i className={"icon-trash"}></i>
                            </button>

                        </div>


                        <div className="mb-3"
                             style={{maxHeight: "256px", overflowY: "auto"}}>
                            {docsUploader.uploadedItems?.map((item: UploadingManyUploadedItem) => {
                                return (
                                    <div key={item.fileId}><strong
                                        color={'secondary'}>{item.fileName}</strong>
                                    </div>
                                )
                            })}
                        </div>


                    </Modal.Body>

                    <Modal.Footer>
                        <span hidden={!docsUploader.isUploading}><Spinner className="spinner-border-sm"/></span>
                        <span>{docsUploader.statusText}</span>
                        <span hidden={!isSaving}><Spinner className="spinner-border-sm"/></span>
                        <span hidden={!isSaving}>正在保存</span>
                        <button
                            type="button"
                            className="btn btn-secondary waves-effect"
                            disabled={isSaving}
                            onClick={() => {
                                docsUploader.stopUpload()
                                props.onHide()
                            }}
                        >
                            取消
                        </button>
                        <button type="submit"
                                disabled={isSaving}
                                className="btn btn-primary waves-effect waves-light">保存
                        </button>

                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}

export default DocImportModal;