import {Alert, Form, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import classNames from "classnames";
import useUploadingMany, {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import Spinner from "../../../components/Spinner";
import React, {useCallback, useEffect, useState} from "react";
import Conf from "../Utils/Conf";
import Select from "react-select";
import useOcr from "../../../utils/ocr";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";

interface OcrImportModalProps {
    visible: boolean
    onHide: () => void
    onTasks: (tasks: any) => void
}
const OcrImportModal = (props: OcrImportModalProps) => {
    const methodsOcrImport = useForm({});
    const docsUploader = useUploadingMany(Conf.urlSecaiFoSend)
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const ocr = useOcr()
    const [langCode, setLangCode] = useState("ch")
    const [transEnabled, setTransEnabled] = useState(false)
    const [transToLangName, setTransToLangName] = useState("中文")
    const [transFromLangName, setTransFromLangName] = useState("英语")
    const supportedFileTypes = {
        "图像": ['.png', '.jpg', '.jpeg', '.bmp']
    }
    const showHelp = () => {
        let msg = '支持的文件类型: '
        Object.entries(supportedFileTypes).forEach(([fileType, extensions]) => {
            msg += "\n" + fileType + ' (' + extensions.join(', ') + ') '
        })
        alert(msg)
    }




    const addTask = async (values: any, event: any) => {
        console.log(values)
        console.log(event)
        setIsSaving(true)

        const json = {
            "lang_code": langCode,
            "trans_enabled": transEnabled,
            "trans_from_lang_name": transFromLangName,
            "trans_to_lang_name": transToLangName,
            "items": [
                ...docsUploader.uploadedItems!.map((item: UploadingManyUploadedItem) => {
                    return {
                        "file_name": item.fileName,
                        "file_id": item.fileId
                    }
                })
            ]
        }


        auth_fetch_post_json(Conf.urlSecAiOcrBatchTasksAdd, json)
            .then((data: any) => {
                console.log("tasksAdd:");

                console.log(data)
                props.onTasks(data.data)
                props.onHide()
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(() => {
            setIsSaving(false)
            docsUploader.clean()
        })
        return false
    }
    return (
        <Modal show={props.visible} onHide={props.onHide} backdrop="static"
               keyboard={false}>
            <FormProvider {...methodsOcrImport}>
                <Form onSubmit={methodsOcrImport.handleSubmit(addTask)}>
                    <Modal.Header closeButton>
                        <h4 className="modal-title">批量添加OCR任务</h4>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <div className="row">
                            <div className="col">
                                <Select
                                    className="react-select react-select-container  mb-2"
                                    classNamePrefix="react-select"
                                    options={ocr.ocrLangs}
                                    defaultValue={
                                        {"value": "ch", "label": "中文"}
                                    }
                                    onChange={x => {
                                        setLangCode(x ? x.value : 'zh')
                                        setTransFromLangName(x ? x.label : '中文')
                                    }}
                                ></Select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3"
                                     style={{maxHeight: "256px", overflowY: "auto"}}>
                                    {docsUploader.uploadedItems?.map((item: UploadingManyUploadedItem) => {
                                        return (
                                            <div  key={item.fileId}><strong
                                                         color={'secondary'}>{item.fileName}</strong>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>

                        <div className="row">
                              <div className="col-md-12">
                                  <div className="col d-flex" style={
                                      {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center'
                                      }
                                  }>
                                      <button type={"button"}
                                              className={"btn d-inline-block btn-outline-danger waves-effect waves-light me-3"}
                                              disabled={docsUploader.isUploading || isSaving} onClick={() => {
                                          docsUploader.clean()
                                      }}><i className={"icon-trash me-1"}></i>清空
                                      </button>
                                      <button
                                          type={"button"}
                                          className={"btn btn-outline-primary me-3"}
                                          disabled={docsUploader.isUploading || isSaving} onClick={() => {
                                          docsUploader.startUpload(
                                              Object.entries(supportedFileTypes).map(v => v[1].join(", ")).join(", ")
                                          )
                                      }}><i className={"icon-cloud-upload me-1"}></i>导入文件
                                      </button>
                                      <Form.Check
                                          className={"me-2"}

                                          type="checkbox"
                                          label={"自动翻译成中文"}
                                          checked={transEnabled}
                                          onChange={e => setTransEnabled(e.target.checked)}

                                      />
                                      <button type={"button"} className={"btn btn-outline-info"}
                                              onClick={() => showHelp()}>
                                          <i className="mdi mdi-help"></i>
                                      </button>

                                      <span hidden={!docsUploader.isUploading}><Spinner
                                          className="spinner-border-sm"/></span>
                                      <span>{docsUploader.statusText}</span>
                                  </div>
                              </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <span hidden={!isSaving}><Spinner className="spinner-border-sm"/></span>
                        <span hidden={!isSaving}>正在保存</span>
                        <button
                            type="button"
                            className="btn btn-secondary waves-effect"
                            disabled={isSaving}
                            onClick={() => {
                                docsUploader.stopUpload()
                                props.onHide()
                            }}
                        >
                            取消
                        </button>
                        <button type="submit"
                                disabled={isSaving}
                                className="btn btn-primary waves-effect waves-light">保存
                        </button>

                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}

export default OcrImportModal;