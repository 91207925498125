import React from "react";
import {Col, Card} from "react-bootstrap";

export interface WidgetProps {
    pad?: string;
}

export abstract class BaseWidget<S, P extends WidgetProps> extends React.Component<S, P> {
    abstract title: string
    abstract desc: string
    abstract routePath: string
    abstract iconUrl: string
  render() {
      /**
       <a class="" data-menu-key="apps-aitool" href="/apps/aitool"><span class="menu-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-disc "><g><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="3"></circle></g></svg> </span><span class="menu-text"> Blank </span></a>
       */
      return (

          <a href={this.routePath}>
              <Card className="widget-rounded-circle">
                  <Card.Body>
                      <div className="row align-items-center">
                          <div className="col-auto">
                              <div className="avatar-lg">
                                  <img
                                      src={this.iconUrl}
                                      className="img-fluid rounded-circle"
                                      alt=""
                                  />
                              </div>
                          </div>
                          <div className="col">
                              <h5 className="mb-1 mt-2 font-16">{this.title}</h5>
                              <p className="mb-2 text-muted">{this.desc}</p>
                          </div>
                      </div>
                  </Card.Body>
              </Card>
          </a>


      );
  }
}