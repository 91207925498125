import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Badge} from "react-bootstrap";
import "@fullcalendar/react";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import useDocTrans from "../../../utils/doctrans";
import DocImportModal from "./DocImportModal";
import Conf from "../Utils/Conf";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";


const DocBatchApp = () => {
    const [docTransTasks, setDocTransTasks] = useState<any>([]);
    const docTransTable = useRef<any>(null);
    const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
    const docTrans = useDocTrans()


    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: docTransTasks.length,
        },
    ];


    const columns = React.useMemo(
        () => [
            {
                Header: "任务ID",
                accessor: "id",
                sort: false,
            },
            {
                Header: "文件名",
                accessor: "file_name",
                sort: false,
            },
            {
                Header: "原始语言",
                accessor: "from_lang_code_action",
                Cell: (data: any) => (
                    <span>{docTrans.findLang(data.row.original.from_lang_code)}</span>
                )
            },
            {
                Header: "目标语言",
                accessor: "to_lang_code_action",
                Cell: (data: any) => (
                    <span>{docTrans.findLang(data.row.original.to_lang_code)}</span>
                )
            },
            {
                Header: "提交时间",
                accessor: "timestr",
                sort: false,
            },
            {
                Header: "状态",
                accessor: "status_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (<Badge bg={"success"}>翻译成功</Badge >)}
                        {data.row.original.status == "idle" && (<Badge bg={"info"}>正在排队</Badge>)}
                        {data.row.original.status == "processing" && (<Badge bg={"primary"}>正在翻译</Badge>)}
                        {data.row.original.status == "error" && (<Badge bg={'danger'}>翻译出错</Badge>)}
                    </div>
                )
            },
            {
                Header: "结果",
                accessor: "result_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (
                            <a href={data.row.original.result_url} target="_blank">下载</a>)}
                    </div>
                )
            }
        ],
        []
    );


    const getTasks = useCallback(() => {
        console.log("getTasks")
        auth_fetch_post_json(Conf.urlSecAiDocBatchTasksList, {
        })
            .then((data: any) => {
                setDocTransTasks(data.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const cleanTasks = useCallback(() => {
        auth_fetch_post_json(Conf.urlSecAiDocBatchTasksClean, {
        })
            .then((data: any) => {
                setDocTransTasks(data.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    useEffect(() => {

        const timer = setInterval(() => getTasks(), 1000)
        return () => {
            clearInterval(timer);
        };
    }, [getTasks]);

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "AI翻译", path: "/apps/docbatch" },
                    { label: "批量翻译", path: "/apps/docbatch", active: true },
                ]}
                title={"批量翻译"}
            />

            <Row>
                <Col>
                    <Button variant="outline-secondary" className="btn-sm me-2"
                            onClick={()=> setImportModalVisible(true)}
                    >
                        添加任务

                    </Button>
                    <Button variant="outline-danger" className="btn-sm" onClick={()=> cleanTasks()}>
                        清除任务
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col md={12} xl={12}>
                    <Table
                        columns={columns}
                        data={docTransTasks}
                        pageSize={25}
                        sizePerPageList={sizePerPageList}
                        isSortable={true}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}
                        ref={docTransTable}
                    />
                </Col>
            </Row>

            <DocImportModal visible={importModalVisible} onHide={()=> setImportModalVisible(false)} onTasks={tasks => setDocTransTasks(tasks)}></DocImportModal>

        </>
    );
};

export default DocBatchApp;
