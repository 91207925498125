import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Modal, Form, Alert, Dropdown, Badge} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Table from "../../../components/Table";
import {FormProvider, useForm} from "react-hook-form";
import {users} from "../AiChat/data";
import Conf from "../Utils/Conf";
import ReactDOM from "react-dom/client";
import {withSwal} from "react-sweetalert2";
import Spinner from "../../../components/Spinner";
import {APICore} from "../../../helpers/api/apiCore";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import useUploading from "../../../utils/uploading";
import useUploadingMany, {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import UploadedItemCard from "./UploadedItemCard";
import StatusModal from "../../../utils/StatusModal";
import useWebAppConfig from "../../../utils/WebAppConfig";
import SimpleBar from "simplebar-react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import MoreMenu from "../../../utils/MoreMenu";
import uploading from "../../../utils/uploading";



const KbAdminApp = withSwal((props: any) => {
    const { swal } = props;


    const [newDocBaseVisible, setNewDocBaseVisible] = useState<boolean>(false);
    const [addDocVisible, setAddDocVisible] = useState<boolean>(false);
    const [docBases, setDocBases] = useState<any>([]);
    const [docs, setDocs] = useState<any>([]);
    const [newDocGroups, setNewDocGroups] = useState<any>([]);
    const [groups, setGroups] = useState<any>([{ id: 0, text: "我的组织" }]);

    const [currentDocBaseUuid, setCurrentDocBaseUuid] = useState<string>("");
    const [currentDocBaseName, setCurrentDocBaseName] = useState<string>("");
    const [currentDocBaseDesc, setCurrentDocBaseDesc] = useState<string>("");
    const [isDocBaseBuilding, setIsDocBaseBuilding] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDocBaseModalEditing, setIsDocBaseModalEditing] = useState<boolean>(false);
    const [currentEditingDocBaseUuid, setCurrentEditingDocBaseUuid] = useState<string>("");
    const docsUploader = useUploadingMany(Conf.urlSecaiFoSend)
    const webAppConfig = useWebAppConfig()
    const [chunkSize, setChunkSize] = useState(256)
    const [chunkOverlap, setChunkOverlap] = useState(64)
    const [zhTitleEnhance, setZhTitleEnhance] = useState(false)

    const supportedFileTypes = {
        "HTML文档": ['.html'],
        "Markdown文档": ['.md'],
        "JSON数据": [".json"],
        "JSONL文档": [".jsonl"],
        // "CSV表格": [".csv"],
        "PDF文档": [".pdf"],
        "图像": ['.png', '.jpg', '.jpeg', '.bmp'],
        // "电子邮件": ['.eml', '.msg'],
        // "电子书": ['.epub'],
        // "Excel文档": ['.xlsx', '.xlsd'],
        "Jupyter Notebook": ['.ipynb'],
        // "OpenDocument文档": ['.odt'],
        "Python文件": ['.py'],
        "Rust文件": ['.rst'],
        // "RTF文档": ['.rtf'],
        // "SRT歌词": ['.srt'],
        // "TOML文档": ['.toml'],
        // "TSV表格": ['.tsv'],
        "Word文档": ['.docx'],
        "XML文档": ['.xml'],
        "PowerPoint": ['.ppt', '.pptx'],
        "文本文件": ['.txt']
    }
    useEffect(() => {
        webAppConfig.loadWebAppConfig()
    }, []);


    const getGroupsAll = useCallback(() => {
        auth_fetch_post_json(Conf.urlUsersGrouplist, {
        })
            .then((data: any) => {
                console.log(data)
                setGroups(data.flatGroups)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);



    const getDocBases = useCallback(() => {
        const api = new APICore()

        auth_fetch_post_json(Conf.urlKbList, {
            'group': 0

        })
            .then((data: any) => {
                setDocBases(data)
                if(data.length > 0) {
                    setCurrentDocBaseUuid(data[0].uuid)
                    setCurrentDocBaseName(data[0].name)
                    setCurrentDocBaseDesc(data[0].desc)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);


    const getDocs = useCallback(() => {
        console.log("getDocs... " + currentDocBaseUuid + " " + currentDocBaseName)
        if(currentDocBaseUuid == "") {
            return
        }
        auth_fetch_post_json(Conf.urlKbDocList, {
            'docbase_uuid': currentDocBaseUuid,
        })
            .then((data: any) => {
                setDocs(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [currentDocBaseUuid]);



    useEffect(() => {
        getGroupsAll();
    }, [getGroupsAll]);

    useEffect(() => {
        getDocBases();
    }, [getDocBases]);

    useEffect(() => {
        getDocs();
    }, [getDocs]);


    const methodsDocBase = useForm({});
    const methodsDocAdd = useForm({});


    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        reset,
    } = methodsDocBase;

    const {
        handleSubmit: handleSubmitDocAdd,
        register: registerDocAdd,
        control: controlDocAdd,
        formState: { errors: errorsDocAdd },
        reset: resetDocAdd,

    } = methodsDocAdd;

    const toggleNewDocBaseModal = () => {
        setNewDocBaseVisible(!newDocBaseVisible)
    };

    const toggleAddDocModal = () => {
        setAddDocVisible(!addDocVisible)
    };

    const newDocBase = async (values: any, event: any) => {
        console.log("newDocBase: ")
        console.log(values)
        console.log(event)
        console.log("newDocGroups")
        console.log(newDocGroups)

        const json: any = {}
        json["name"] = values.name
        json["groups"] = newDocGroups
        json["desc"] = values.desc


        if (isDocBaseModalEditing) {
            json["docbase_uuid"] = currentEditingDocBaseUuid
        }

        const url = isDocBaseModalEditing? Conf.urlKbUpdate : Conf.urlKbCreate
        auth_fetch_post_json(url, json)
            .then(data => {
                setDocBases(data)
                setNewDocBaseVisible(false)
                reset();
            })
            .catch((error) => {
                alert(error)
                reset();
            })
        return false
    }

    const handleResetNewDocBase = () => {
        reset();
        setNewDocBaseVisible(false)
    }
    const removeDocBases = (uuids: string[]) => {
        console.log("removeDocBases: ")
        console.log(uuids)
        auth_fetch_post_json(Conf.urlKbRemove, {
            'uuids': uuids
        })
            .then((data: any) => {
                setDocBases(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const buildDocBase = useCallback(()=> {
        setIsDocBaseBuilding(true)
        auth_fetch_post_json(Conf.urlKbBuild, {
            'docbase_uuid': currentDocBaseUuid

        })
            .then((data: any) => {
                /// setDocBases(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                setIsDocBaseBuilding(false)
            })

    }, [currentDocBaseUuid])
    const addDoc = async (values: any, event: any) => {
        console.log(values)
        console.log(event)
        setIsSaving(true)

        const json = {
            "docbase_uuid": currentDocBaseUuid,
            "chunk_size": chunkSize,
            "chunk_overlap": chunkOverlap,
            "zh_title_enhance": zhTitleEnhance,
            "items": [
                ...docsUploader.uploadedItems!.map((item: UploadingManyUploadedItem) => {
                    return {
                        "file_name": item.fileName,
                        "file_id": item.fileId
                    }
                })
            ]
        }


        auth_fetch_post_json(Conf.urlKbDocAddMany, json)
            .then((data: any) => {
                setDocs(data)
                setAddDocVisible(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(() => {
                setIsSaving(false)
                docsUploader.clean()
            })
        return false
    }

    const removeDocs = useCallback((docbase_uuid: string, uuids: string[]) => {
        console.log("removeDocs: ")
        console.log(docbase_uuid)
        console.log(uuids)
        auth_fetch_post_json(Conf.urlKbDocRemove, {
            "docbase_uuid": docbase_uuid,
            'uuids': uuids
        })
            .then((data: any) => {
                setDocs(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [currentDocBaseUuid])

    const showHelp = () => {
        let msg = '支持的文件类型: '
        Object.entries(supportedFileTypes).forEach(([fileType, extensions]) => {
            msg += "\n" + fileType + ' (' + extensions.join(', ') + ') '
        })
        alert(msg)
    }

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "应用", path: "/apps/docbase"},
                    {label: "知识库管理2.0", path: "/apps/docbase", active: true},
                ]}
                title={"知识库管理2.0"}
            />
            <div className={"mb-2"}>
                <button type="button" className="btn btn-outline-primary btn-sm me-2"
                        onClick={() => {
                            reset()
                            setIsDocBaseModalEditing(false)
                            toggleNewDocBaseModal()
                        }
                        }
                ><i className={"mdi mdi-plus"}></i>创建知识库
                </button>
                <button type={"button"} className={"btn btn-outline-primary btn-sm me-2"}
                        onClick={
                            () => {
                                docsUploader.clean()
                                toggleAddDocModal()
                            }

                        }><i className={"mdi mdi-plus"}></i>添加文档
                </button>

            </div>
            <div style={{
                display: "flex",
                maxHeight: "60vh",
            }}>

                <SimpleBar className="bg-white" style={{
                    width: "300px",
                    maxHeight: "100%",
                    overflowY: "auto"
                }}>

                    {(docBases).map((docBase: any, index: number) => {
                        return (
                            <Link
                                to="#"
                                key={index}
                                className="text-body"
                                onClick={(e: any) => {
                                    setCurrentDocBaseUuid(docBase.uuid)
                                    setCurrentDocBaseName(docBase.name)
                                    setCurrentDocBaseDesc(docBase.desc)
                                    getDocs()
                                }}
                            >
                                <div
                                    className={classnames(
                                        "d-flex",
                                        "align-items-start",
                                        "p-2",
                                        {
                                            "bg-light": currentDocBaseUuid == docBase.uuid
                                        }
                                    )}
                                >
                                    <img
                                        src={""}
                                        className="me-2 rounded-circle"
                                        height="42"
                                        alt=""
                                    />

                                    <div className="w-100">
                                        <div className={"float-end text-muted fw-normal font-12"}>
                                            <MoreMenu menuItems={
                                                [
                                                    {
                                                        text: "删除",
                                                        onClicked: () => {
                                                            swal.fire({
                                                                title: "确定要删除知识库吗?",
                                                                icon: "warning",
                                                                showCancelButton: true,
                                                                confirmButtonColor: "#3085d6",
                                                                cancelButtonColor: "#d33",
                                                                confirmButtonText: "确定",
                                                                cancelButtonText: "取消",
                                                            }).then((result: any) => {
                                                                if (result.isConfirmed) {
                                                                    removeDocBases([docBase.uuid])
                                                                }
                                                            });
                                                        }
                                                    },
                                                    {
                                                        text: "编辑",
                                                        onClicked: () => {
                                                            console.log(docBase)
                                                            const newData = docBase
                                                            setCurrentEditingDocBaseUuid(docBase.uuid)
                                                            setIsDocBaseModalEditing(true)
                                                            reset(newData)
                                                            setNewDocBaseVisible(true)
                                                        }
                                                    }
                                                ]
                                            }></MoreMenu>
                                        </div>
                                        <h5 className="mt-0 mb-0 font-14">{docBase.name}</h5>
                                        <p className="mt-1 mb-0 text-muted font-12"><span
                                            className="w-75">{docBase.desc}</span></p>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </SimpleBar>


                <SimpleBar className="bg-white" style={{
                    maxHeight: "100%",
                    flexGrow: 1,
                    marginLeft: "10px",
                    marginRight: "10px",
                    overflowY: "auto"
                }}>
                    <Row>
                        {(docs || []).map((doc: any, i: number) => {
                            return (
                                <Col key={i} xl={6} lg={4} md={2}>
                                    <Card className="m-1 shadow-none border">
                                        <div className="p-2">
                                            <div
                                                className={"float-end text-muted fw-normal font-12"}>
                                                <MoreMenu menuItems={
                                                    [
                                                        {
                                                            text: "删除",
                                                            onClicked: () => {
                                                                removeDocs(currentDocBaseUuid, [doc.uuid])
                                                            }
                                                        }
                                                    ]
                                                }> </MoreMenu>
                                            </div>

                                            <Row className="align-items-center">
                                                <Col className="col-auto pe-0">
                                                    <div className="avatar-sm">
                                                                  <span
                                                                      className="avatar-title bg-light text-secondary rounded">
                                                                    <i className={"mdi mdi-file"}></i>
                                                                  </span>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Link to="#" className="text-muted fw-bold">
                                                        {doc.name}
                                                    </Link>
                                                    <p className="mb-0 font-13">{doc.count}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>




                </SimpleBar>


            </div>

            <Modal show={addDocVisible} onHide={toggleAddDocModal} backdrop="static"
                   keyboard={false}>
                <FormProvider {...methodsDocAdd}>
                    <Form onSubmit={handleSubmitDocAdd(addDoc)}>
                        <Modal.Header closeButton>
                            <h4 className="modal-title">添加文档们到知识库</h4>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <Alert
                                variant={'danger'}
                                className={classNames(
                                    "bg-danger",
                                    "border-0",
                                    "text-white"
                                )}
                            >
                                <i className={"mdi mdi-alert"} hidden={!webAppConfig.hasSecret}></i>本产品密级为<strong>内部</strong>，
                                请不要上传更高安全级别的文件
                            </Alert>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-1">
                                        <label htmlFor="field-1" className="form-label">
                                            以下文档将被添加到知识库
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3"
                                         style={{maxHeight: "256px", overflowY: "auto"}}>
                                        {docsUploader.uploadedItems?.map((item: UploadingManyUploadedItem) => {
                                            return (
                                                <div><strong key={item.fileId}
                                                             color={'secondary'}>{item.fileName}</strong>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <button type={"button"}
                                            className={"btn btn-xs btn-outline-secondary waves-effect waves-light me-3"}
                                            disabled={docsUploader.isUploading || isSaving} onClick={() => {
                                        docsUploader.clean()
                                    }}><i className={"icon-trash me-1"}></i>清空列表
                                    </button>
                                    <button
                                        type={"button"}
                                        className={"btn btn-xs btn-outline-primary me-3"}
                                        disabled={docsUploader.isUploading || isSaving} onClick={() => {
                                        docsUploader.startUpload(
                                            Object.entries(supportedFileTypes).map(v => v[1].join(", ")).join(", ")
                                        )
                                    }}><i className={"icon-cloud-upload me-1"}></i>导入文件
                                    </button>

                                    <button type={"button"} className={"btn btn-xs btn-outline-info"} onClick={()=>showHelp()}>
                                        <i className="mdi mdi-help"></i>
                                    </button>

                                    <span hidden={!docsUploader.isUploading}><Spinner
                                        className="spinner-border-sm"/></span>
                                    <span>{docsUploader.statusText}</span>
                                </div>
                                <Form.Group className="mt-3 mb-2">
                                    <Form.Check
                                        type="switch"
                                        id="default-checkbox1"
                                        label="中文标题加强"
                                        checked={zhTitleEnhance}
                                        onChange={e=>setZhTitleEnhance(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-0">
                                    <Form.Label htmlFor="exampleRange" className="form-label">
                                        文本块长度({chunkSize})
                                    </Form.Label>
                                    <Form.Range value={chunkSize} min={32} max={1024} step={8} onChange={e=>setChunkSize(parseInt(e.target.value))} />
                                </Form.Group>
                                <Form.Group className="mb-0">
                                    <Form.Label htmlFor="exampleRange" className="form-label">
                                        相邻文本重合长度({chunkOverlap})
                                    </Form.Label>
                                    <Form.Range value={chunkOverlap} min={32} max={1024} step={8} onChange={e=>setChunkOverlap(parseInt(e.target.value))} />
                                </Form.Group>
                            </div>

                        </Modal.Body>

                        <Modal.Footer>
                            <span hidden={!isSaving}><Spinner className="spinner-border-sm"/></span>
                            <span hidden={!isSaving}>正在保存</span>
                            <button
                                type="button"
                                className="btn btn-secondary waves-effect"
                                disabled={isSaving}
                                onClick={() => {
                                    docsUploader.stopUpload()
                                    toggleAddDocModal()
                                }}
                            >
                                取消
                            </button>
                            <button type="submit"
                                    disabled={isSaving}
                                    className="btn btn-primary waves-effect waves-light">保存
                            </button>

                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal show={newDocBaseVisible} onHide={toggleNewDocBaseModal}>
                <FormProvider {...methodsDocBase}>
                <Form onSubmit={handleSubmit(newDocBase)}>
                        <Modal.Header closeButton>
                            <h4 className="modal-title">{isDocBaseModalEditing ? "编辑知识库" : "添加知识库"}</h4>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="field-docbase-name" className="form-label">
                                            知识库名
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="field-docbase-name"
                                            placeholder="输入知识库的名称"
                                            required
                                            {...register("name", {required: true})}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="field-docbase-groups" className="form-label">
                                            可用部门
                                        </label>
                                        <Select
                                            isMulti={true}
                                            options={
                                                groups.map((item: any) => {
                                                    return {value: item.id, label: item.fullPath}
                                                })
                                            }
                                            className="react-select react-select-container"
                                            classNamePrefix="react-select"
                                            onChange={(e) => setNewDocGroups(e.map((item: any) => item.value))}
                                        ></Select>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="">
                                        <label htmlFor="field-7" className="form-label">
                                            描述
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="field-7"
                                            placeholder="输入知识库的一些介绍"
                                            {...register("desc", {required: true})}

                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-secondary waves-effect"
                                onClick={handleResetNewDocBase}
                            >
                                关闭
                            </button>
                            <button type="submit" className="btn btn-info waves-effect waves-light">保存</button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>

            <StatusModal
                show={isDocBaseBuilding}
                title={"编译中..."}
                message={"正在编译知识库, 可能会花比较长的时间, 请耐心等候."}
            ></StatusModal>

        </>
    );
});

export default KbAdminApp;
