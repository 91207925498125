import {auth_fetch_post_json} from "./auth_fetch";
import Conf from "../pages/apps/Utils/Conf";
import {useEffect, useState} from "react";

export type TaskType = 'video_trans' | 'audio_trans' | 'txt_trans' | 'pdf_txt_trans'|  'pdf_ocr_trans' | 'docx_trans' | 'pptx_trans' | 'xlsx_trans'
export interface TaskTypeFileInfo {
    type: TaskType
    name: string
    filters: { name: string, extensions: string[] }[]
}
export const taskTypeFileInfos: TaskTypeFileInfo[] = [
    {type:  'video_trans', name: '视频文件', filters: [ { name: '视频文件', extensions: ['mp4', 'avi', 'mov', 'flv', 'mkv', 'rmvb', 'wmv', 'webm'] } ]},
    {type: 'audio_trans', name: '音频文件', filters: [ { name: '音频文件', extensions: ['mp3', 'wav', 'flac', 'ape', 'wma', 'aac', 'm4a', 'ogg'] } ]},
    {type: 'txt_trans', name: '文本文件', filters: [ { name: '文本文件', extensions: ['txt', "text", "log"] } ]},
    {type: 'pdf_txt_trans', name: 'PDF文件(图文类)', filters: [ { name: 'PDF文件', extensions: ['pdf'] } ]},
    {type: 'pdf_ocr_trans', name: 'PDF文件(扫描类)', filters: [ { name: 'PDF文件', extensions: ['pdf'] } ]},
    {type: 'docx_trans', name: 'Word文件', filters: [ { name: 'Word文件', extensions: ['docx'] } ]},
    {type: 'pptx_trans', name: 'PPT', filters: [ { name: 'PPT文件', extensions: ['pptx'] } ]},
    {type: 'xlsx_trans', name: 'Excel文件', filters: [ { name: 'Excel文件', extensions: ['xlsx'] } ]},
]
interface VideoTransOption {
    from_lang_code: String
    from_lang_name: String
    trans_engine: String
    to_lang_code: String
    to_lang_name: String
}

interface AudioTransOption {
    from_lang_code: String
    from_lang_name: String
    trans_engine: String
    to_lang_code: String
    to_lang_name: String
}

interface DocTransOption {
    trans_from_lang_code: String
    trans_to_lang_code: String
}

interface TaskItem {
    base_model: string
    task_type: TaskType
    file_name: string
    file_id: string
    video_trans_option?: VideoTransOption
    audio_trans_option?: AudioTransOption
    doc_trans_option?: DocTransOption
}

export interface Sentence {
    start: number
    stop: number
    text: string
    to_text: string
}

const useDocBatchClient = () => {
    const [isAdding, setIsAdding] = useState<boolean>(false);

    const addTaskOne = async (item: TaskItem): Promise<string | null> => {
        setIsAdding(true)
        return await auth_fetch_post_json(Conf.urlSecAiDocBatchTasksAddOne, item)
            .then((data: any) => {
                console.log('addTaskOne return:', data);
                if (data['status']) {
                    return data['task_id']
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                return null
            }).finally(() => {
                setIsAdding(false)
            })
    }

    const getTaskTypeFileInfo = (type: TaskType): TaskTypeFileInfo => {
        return taskTypeFileInfos.find(info => info.type == type) as TaskTypeFileInfo
    }

    return {
        isAdding,
        addTaskOne,
        getTaskTypeFileInfo
    }
}

export default useDocBatchClient;