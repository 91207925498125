import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";
const asrLangs = [
    {"value": "zh", "label": "中文"},
    {"value": "en", "label": "英语"},
    {"value": "de", "label": "德语"},
    {"value": "es", "label": "西班牙语"},
    {"value": "ru", "label": "俄语"},
    {"value": "ko", "label": "韩语"},
    {"value": "fr", "label": "法语"},
    {"value": "ja", "label": "日语"},
    {"value": "pt", "label": "葡萄牙语"},
    {"value": "tr", "label": "土耳其语"},
    {"value": "pl", "label": "波兰语"},
    {"value": "ca", "label": "加泰罗尼亚语"},
    {"value": "nl", "label": "荷兰语"},
    {"value": "ar", "label": "阿拉伯语"},
    {"value": "sv", "label": "瑞典语"},
    {"value": "it", "label": "意大利语"},
    {"value": "id", "label": "印尼语"},
    {"value": "hi", "label": "印地语"},
    {"value": "fi", "label": "芬兰语"},
    {"value": "vi", "label": "越南语"},
    {"value": "he", "label": "希伯来语"},
    {"value": "uk", "label": "乌克兰语"},
    {"value": "el", "label": "希腊语"},
    {"value": "ms", "label": "马来语"},
    {"value": "cs", "label": "捷克语"},
    {"value": "ro", "label": "罗马尼亚语"},
    {"value": "da", "label": "丹麦语"},
    {"value": "hu", "label": "匈牙利语"},
    {"value": "ta", "label": "泰米尔语"},
    {"value": "no", "label": "挪威语"},
    {"value": "th", "label": "泰语"},
    {"value": "ur", "label": "乌尔都语"},
    {"value": "hr", "label": "克罗地亚语"},
    {"value": "bg", "label": "保加利亚语"},
    {"value": "lt", "label": "立陶宛语"},
    {"value": "la", "label": "拉丁语"},
    {"value": "mi", "label": "毛利语"},
    {"value": "ml", "label": "马拉雅拉姆语"},
    {"value": "cy", "label": "威尔士语"},
    {"value": "sk", "label": "斯洛伐克语"},
    {"value": "te", "label": "泰卢固语"},
    {"value": "fa", "label": "波斯语"},
    {"value": "lv", "label": "拉脱维亚语"},
    {"value": "bn", "label": "孟加拉语"},
    {"value": "sr", "label": "塞尔维亚语"},
    {"value": "az", "label": "阿塞拜疆语"},
    {"value": "sl", "label": "斯洛文尼亚语"},
    {"value": "kn", "label": "卡纳达语"},
    {"value": "et", "label": "爱沙尼亚语"},
    {"value": "mk", "label": "马其顿语"},
    {"value": "br", "label": "布列塔尼语"},
    {"value": "eu", "label": "巴斯克语"},
    {"value": "is", "label": "冰岛语"},
    {"value": "hy", "label": "亚美尼亚语"},
    {"value": "ne", "label": "尼泊尔语"},
    {"value": "mn", "label": "蒙古语"},
    {"value": "bs", "label": "波斯尼亚语"},
    {"value": "kk", "label": "哈萨克语"},
    {"value": "sq", "label": "阿尔巴尼亚语"},
    {"value": "sw", "label": "斯瓦希里语"},
    {"value": "gl", "label": "加利西亚语"},
    {"value": "mr", "label": "马拉提语"},
    {"value": "pa", "label": "旁遮普语"},
    {"value": "si", "label": "僧伽罗语"},
    {"value": "km", "label": "高棉语"},
    {"value": "sn", "label": "修纳语"},
    {"value": "yo", "label": "约鲁巴语"},
    {"value": "so", "label": "索马里语"},
    {"value": "af", "label": "阿非利卡语"},
    {"value": "oc", "label": "奥克西唐语"},
    {"value": "ka", "label": "格鲁吉亚语"},
    {"value": "be", "label": "白俄罗斯语"},
    {"value": "tg", "label": "塔吉克语"},
    {"value": "sd", "label": "信德语"},
    {"value": "gu", "label": "古吉拉特语"},
    {"value": "am", "label": "阿姆哈拉语"},
    {"value": "yi", "label": "依地语"},
    {"value": "lo", "label": "老挝语"},
    {"value": "uz", "label": "乌兹别克语"},
    {"value": "fo", "label": "法罗语"},
    {"value": "ht", "label": "海地克里奥尔语"},
    {"value": "ps", "label": "普什图语"},
    {"value": "tk", "label": "土库曼语"},
    {"value": "nn", "label": "新挪威语"},
    {"value": "mt", "label": "马耳他语"},
    {"value": "sa", "label": "梵语"},
    {"value": "lb", "label": "卢森堡语"},
    {"value": "my", "label": "缅甸语"},
    {"value": "bo", "label": "藏语"},
    {"value": "tl", "label": "塔加路语"},
    {"value": "mg", "label": "马尔加什语"},
    {"value": "as", "label": "阿萨姆语"},
    {"value": "tt", "label": "鞑靼语"},
    {"value": "haw", "label": "夏威夷语"},
    {"value": "ln", "label": "林加拉语"},
    {"value": "ha", "label": "豪萨语"},
    {"value": "ba", "label": "巴什基尔语"},
    {"value": "jw", "label": "爪哇语"},
    {"value": "su", "label": "巽他语"}
]
function useAsr() {
    const [asrProgress, setAsrProgress] = useState(0);
    const [asrStatus, setAsrStatus] = useState("");
    const [asrSentences, setAsrSentences] = useState([]);
    const [asrResult, setAsrResult] = useState("");
    const [isAsring, setIsAsring] = useState(false)
    // @ts-ignore
    const socketRef = useRef<WebSocket | null>(null);  // Explicitly type the ref here

    const startAsrByFile = useCallback((fileId: string, langCode: string) => {
        setAsrStatus("准备转写")
        setIsAsring(true)
        try {
            if (socketRef.current) {
                socketRef.current.close();
            }
        } catch (e) {
            console.log(e)
        }
        try {
            setAsrStatus("正在连接")
            const socket = new WebSocket(Conf.urlSecaiAsrConvert);
            socketRef.current = socket;
            let timer_ping: NodeJS.Timeout
            socket.onopen = () => {
                timer_ping = setInterval(() => {
                    try {
                        socket.send(JSON.stringify({
                            action: "ping"
                        }))
                    } catch(e) {
                        console.log(e)
                    }
                }, 5000)
                setAsrStatus("发送转写请求")
                socket.send(JSON.stringify({
                    action: "convertFile",
                    source: fileId,
                    langCode: langCode
                }));
            }


            socket.onmessage = (event: any) => {

                const json = JSON.parse(event.data);
                if(json.action === "progress") {
                    setAsrProgress(json.progress);
                } else if(json.action === "finished") {
                    setAsrProgress(100);
                    setAsrResult(json.result)
                    setAsrSentences(json.sentences)
                    setAsrStatus("转写成功")
                } else if(json.action === "pong") {
                    console.log("get pong")
                } else {
                    console.log("unknown action")
                }
            }

            socket.onerror = (err: any) => {
                setAsrStatus("转写失败 " + err)
                setIsAsring(false)
            }

            socket.onclose = () => {
                console.log("closed..")
                if(timer_ping) {
                    clearInterval(timer_ping)
                }
                setIsAsring(false)
            }
        } catch(err) {
            console.log(err)
            setIsAsring(false)
            setAsrStatus("转写失败 " + err)
        }


    }, []);

    const stopAsr = useCallback(() => {
        if (socketRef.current) {
            socketRef.current.close();
        }
    }, []);


    return {
        asrProgress,
        startAsrByFile,
        stopAsr, asrLangs,
        asrResult,
        asrSentences,
        isAsring
    };
}

export default useAsr;
