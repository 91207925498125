


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_createhelper.png";

interface CreateHelperWidgetProps extends WidgetProps {
    pad?: string;
}
export class CreateHelperWidget extends BaseWidget<any, CreateHelperWidgetProps> {
    title: string = "创作助手";
    desc: string = "帮你进行创作";
    routePath: string = "/apps/aitool/widgets/createhelper/createhelperapp";
    iconUrl: string = avatar;


}



