import {useState} from "react";

type ChatWsOnMessage = (e: string) => void;
type ChatWsOnError = (e: string) => void;
type ChatWsOnClosed = () => void;



export const chatWsSendJson = (url: string, json: any,
                               onMessage: ChatWsOnMessage,
                                 onError: ChatWsOnError,
                                    onClose: ChatWsOnClosed
                               ) => {
    const ws = new WebSocket(url);
    ws.onopen = () => {
        console.log("open...")
        ws.send(JSON.stringify(json));
    }
    ws.onclose = () => {
        console.log("close...")
        onClose()
    }
    ws.onerror = (e ) => {
        console.log("error..." + e)
        console.log(e)
        onError("" + e)
    }
    ws.onmessage = (e) => {
        console.log("onMessage")
        onMessage(e.data)
    }

}


