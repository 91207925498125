import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";

function useT2tt() {
    const [t2ttInfo, setT2ttInfo] = useState<any>(null);



    useEffect(() => {
        fetch(Conf.urlTransTranslateDirs, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(resp => resp.json())
            .then(json => {
                console.log(json)
                if(json['status']) {
                    setT2ttInfo(json.data)
                }
            })

    }, []);

    return {
        t2ttInfo
    };
}

export default useT2tt;




