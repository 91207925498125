import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

// components
import PageTitle from "../../../components/PageTitle";

import ChatUsers from "./ChatUsers";
import ChatArea from "./ChatArea";
// dummy data
import { users, ChatUser } from "./data";

// AiChatApp
const AiChatApp = () => {
  const [selectedUser, setSelectedUser] = useState<ChatUser>(users[0]);

  /**
   * On user change
   */
  const onUserChange = (user: ChatUser) => {
    setSelectedUser(user);
  };

  return (
    <div style={{height: '100%'}}>
      <PageTitle
        breadCrumbItems={[
          { label: "应用", path: "/apps/aichat" },
          { label: "知识助手", path: "/apps/aichat", active: true },
        ]}
        title={"知识助手"}
      />

      <Row>
        <Col lg={4} xl={3} style={{display: "flex", flexDirection: "column", height: "100%"}}>
          <ChatUsers onUserSelect={onUserChange} />
        </Col>
        <Col lg={8} xl={9}>
          <ChatArea selectedUser={selectedUser} />
        </Col>
      </Row>
    </div>
  );
};

export default AiChatApp;
