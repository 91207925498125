import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Table from "../../../components/Table";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";
import {users} from "../AiChat/data";
import {Engine, LangInfo} from "../../../utils/TransLangSelectGroup";
import useT2tt from "../../../utils/t2tt";
import useUploading from "../../../utils/uploading";
import Conf from "../Utils/Conf";
import UploadingProgressModal from "../../../utils/UploadingProgressModal";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import StatusModal from "../../../utils/StatusModal";
import {withSwal} from "react-sweetalert2";



const HootToolApp = withSwal((props: any) => {
    const {swal} = props

    const [hots, setHots] = useState([])
    const defaultEngine = {
        value: 'llm',
        label: 'LLM大模型'
    }
    const [fromLang, setFromLang] = useState<LangInfo | null>(null);
    const [toLang, setToLang] = useState<LangInfo | null>(null);
    const [currentEngine, setCurrentEngine] = useState<Engine>(defaultEngine);
    const t2tt = useT2tt()
    const uploading = useUploading(Conf.urlSecaiFoSend);

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "500",
            value: 500,
        },
        {
            text: "1000",
            value: 1000,
        },
        {
            text: "All",
            value: hots.length,
        },
    ];


    const columns = React.useMemo(
        () => [
            {
                Header: "词库编号",
                accessor: "hot_id",
                sort: false,
            },

            {
                Header: "源语言代码",
                accessor: "from_lang_code",
                sort: false,
            },
            {
                Header: "源语言名",
                accessor: "from_lang_name",
                sort: false,
            },
            {
                Header: "目标语言代码",
                accessor: "to_lang_code",
                sort: false,
            },
            {
                Header: "目标语言名",
                accessor: "to_lang_name",
                sort: false,
            },
            {
                Header: "词库文件文明",
                accessor: "file_name",
                sort: false,
            },
            {
                Header: "词库条目数",
                accessor: "hot_word_count",
                sort: false,
            },
            {
                Header: "操作",
                accessor: "action",
                sort: false,
                Cell: (data: any) => (
                    <div>
                        <Button className={"btn btn-danger"} size={"sm"}><i className={"mdi mdi-trash-can"}
                                                                            onClick={async ()=>{
                                                                                const res = await swal.fire({
                                                                                    title: "确定删除吗?",
                                                                                    text: "删除后无法恢复",
                                                                                    icon: "warning",
                                                                                    showCancelButton: true,
                                                                                    confirmButtonColor: "#4a4fea",
                                                                                    cancelButtonColor: "#d33",
                                                                                    confirmButtonText: "确定",
                                                                                    cancelButtonText: "取消",
                                                                                });
                                                                                if(res.isConfirmed) {
                                                                                    auth_fetch_post_json(Conf.urlSecaiHotRemove, {
                                                                                        hot_id: data.row.original.hot_id
                                                                                    })
                                                                                        .then((res: any) => {
                                                                                            if(res.status) {
                                                                                                setHots(res.hot_list)
                                                                                            } else {
                                                                                                swal.fire({
                                                                                                    icon: "warning",
                                                                                                    title: "删除异常",
                                                                                                    text: '删除时发生错误 ' + res.reason,
                                                                                                    confirmButtonColor: "#4a4fea",
                                                                                                    confirmButtonText: "确定",
                                                                                                });
                                                                                            }
                                                                                        })
                                                                                        .catch(e=> {
                                                                                            swal.fire({
                                                                                                icon: "warning",
                                                                                                title: "删除异常",
                                                                                                text: '删除时发生错误 ' + e,
                                                                                                confirmButtonColor: "#4a4fea",
                                                                                                confirmButtonText: "确定",
                                                                                            });
                                                                                        })
                                                                                }
                                                                            }}
                        ></i></Button>
                    </div>
                )
            }
        ],
        []
    );

    const handleSwitchLang = () => {
        const tmpLang = fromLang
        setFromLang(toLang)
        setToLang(tmpLang)

    }

    const handleOnUpload = async () => {
        uploading.startUpload("application/json")
    }

    const handleOnClean = async () => {
        const res = await swal.fire({
            title: "确定清空热词库吗?",
            text: "清空后无法恢复",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4a4fea",
            cancelButtonColor: "#d33",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
        });
        if(res.isConfirmed) {
            auth_fetch_post_json(Conf.urlSecaiHotClean, {})
                .then((res: any) => {
                    if(res.status) {
                        setHots([])
                    } else {
                        swal.fire({
                            icon: "warning",
                            title: "清空异常",
                            text: '清空时发生错误 ' + res.reason,
                            confirmButtonColor: "#4a4fea",
                            confirmButtonText: "确定",
                        });
                    }
                })
                .catch(e=> {
                    swal.fire({
                        icon: "warning",
                        title: "清空异常",
                        text: '清空时发生错误 ' + e,
                        confirmButtonColor: "#4a4fea",
                        confirmButtonText: "确定",
                    });
                })
        }

    }

    useEffect(() => {
        if(t2tt.t2ttInfo) {
            setTimeout(() => {
                setFromLang(t2tt.t2ttInfo['engines_info']['llm']['default_src'])
                setToLang(t2tt.t2ttInfo['engines_info']['llm']['default_tgt'])
            }, 1000);

        }
    }, [t2tt.t2ttInfo]);

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('热词管理'))
    }, []);

    useEffect(() => {
        // load hot list
        auth_fetch_post_json(Conf.urlSecaiHotList, {})
            .then((res: any) => {
                if(res.status) {
                    setHots(res.hot_list)
                } else {
                    swal.fire({
                        icon: "warning",
                        title: "加载异常",
                        text: '加载时发生错误 ' + res.reason,
                        confirmButtonColor: "#4a4fea",
                        confirmButtonText: "确定",
                    });
                }
            })
            .catch(e=> {
                swal.fire({
                    icon: "warning",
                    title: "加载异常",
                    text: '加载时发生错误 ' + e,
                    confirmButtonColor: "#4a4fea",
                    confirmButtonText: "确定",
                });
            })
    }, []);


    useEffect(() => {

        if(uploading.fileId) {
            if(!fromLang || !toLang) {
                swal.fire({
                    icon: "warning",
                    title: "导入异常",
                    text: '未设置语言',
                    confirmButtonColor: "#4a4fea",
                    confirmButtonText: "确定",
                });
                return
            }
            auth_fetch_post_json(Conf.urlSecaiHotImport, {
                fo_id: uploading.fileId,
                from_lang_code: fromLang?.value,
                from_lang_name: fromLang?.label,
                to_lang_code: toLang?.value,
                to_lang_name: toLang?.label,
            })
                .then((res: any) => {
                    if(res.status) {
                        setHots(res.hot_list)
                    } else {
                        swal.fire({
                            icon: "warning",
                            title: "导入异常",
                            text: '导入时发生错误 ' + res.reason,
                            confirmButtonColor: "#4a4fea",
                            confirmButtonText: "确定",
                        });
                    }
                })
                .catch(e=> {
                    swal.fire({
                        icon: "warning",
                        title: "导入异常",
                        text: '导入时发生错误 ' + e,
                        confirmButtonColor: "#4a4fea",
                        confirmButtonText: "确定",
                    });
                })
        }
    }, [uploading.fileId]);
    return (
        <>
            <Row>
                <Col md={12} xl={12}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>

                        <Select
                            className="react-select react-select-container me-2"
                            classNamePrefix="react-select"
                            isClearable={false}
                            isSearchable={true}
                            value={fromLang}

                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '200px',
                                }),
                            }}
                            options={
                                t2tt.t2ttInfo?
                                    t2tt.t2ttInfo[currentEngine.value]['src_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['src_langs'][code]['language']}))
                                    :[]
                            }
                            onChange={(e) => {
                                setFromLang(e)
                            }}
                        ></Select>

                        <Button onClick={() => handleSwitchLang()} variant="default" className="btn-info waves-effect waves-light  me-2">
                            <i className="fas fa-exchange-alt"></i>
                        </Button>
                        <Select
                            className="react-select react-select-container me-4"
                            classNamePrefix="react-select"
                            isClearable={false}
                            isSearchable={true}
                            value={toLang}


                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '200px',
                                }),
                            }}
                            options={
                                t2tt.t2ttInfo?
                                    t2tt.t2ttInfo[currentEngine.value]['tgt_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['tgt_langs'][code]['language']}))
                                    :[]
                            }
                            onChange={(e) => {
                                setToLang(e)
                            }}
                        ></Select>

                        <Button
                            variant={'outline-secondary'}
                            className={'me-1'}
                            onClick={handleOnUpload}
                        ><i className={'mdi mdi-plus'}></i>导入</Button>
                        <Button
                            variant={'outline-secondary'}
                            onClick={handleOnClean}
                        ><i className={'mdi mdi-trash-can'}></i>清空</Button>
                    </div>


                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}>
                    <Table
                        columns={columns}
                        data={hots? hots: []}
                        pageSize={50}
                        sizePerPageList={sizePerPageList}
                        isSortable={false}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}

                    />
                </Col>
            </Row>

            <UploadingProgressModal
                fileName={uploading.fileName}
                fileSize={uploading.fileSize}
                progress={uploading.progress}
                statusText={uploading.statusText}
                show={uploading.uploading}
                onHide={() => {
                }}
                onStopClicked={() => {
                }}></UploadingProgressModal>

        </>
    );
});

export default HootToolApp;
