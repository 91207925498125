import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";


interface TransResult {
    imageUrl?: string
    txtUrl?: string
    jsonUrl?: string
    txt?: string
}


function useDocTrans() {
    const [transProgress, setTransProgress] = useState(0);
    const [transResult, setTransResult] = useState<TransResult>({});
    const [isTransing, setIsTransing] = useState(false);
    const [statusText, setStatusText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // @ts-ignore
    const socketRef = useRef<WebSocket | null>(null);  // Explicitly type the ref here

    const docTransLangs = [
        {'value': 'vi', 'label': '越南语'},
        {'value': 'th', 'label': '泰语'},
        {'value': 'id', 'label': '印度尼西亚语'},
        {'value': 'ar', 'label': '阿拉伯语'},
        {'value': 'nl', 'label': '荷兰语'},
        {'value': 'en', 'label': '英语'},
        {'value': 'fr', 'label': '法语'},
        {'value': 'de', 'label': '德语'},
        {'value': 'it', 'label': '意大利语'},
        {'value': 'ja', 'label': '日语'},
        {'value': 'ko', 'label': '韩语'},
        {'value': 'pl', 'label': '波兰语'},
        {'value': 'pt', 'label': '葡萄牙语'},
        {'value': 'ru', 'label': '俄语'},
        {'value': 'es', 'label': '西班牙语'},
        {'value': 'tr', 'label': '土耳其语'},
        {'value': 'zh', 'label': '中文'},
        {'value': 'cht', 'label': '繁体中文'}
    ]
    const findLang = (langCode: string) => {
        const found = docTransLangs.find((lang) => lang.value === langCode)
        if(found) {
            return found['label']
        } else {
            return langCode
        }
    }


    return {
        findLang,
        docTransLangs,
        statusText,
        errorMessage
    };
}

export default useDocTrans;
