


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_mindmap.png";

interface MindMapWidgetProps extends WidgetProps {
    avatar: string;
    name: string;
    position: string;
}
export class MindMapWidget extends BaseWidget<any, MindMapWidgetProps> {
    title: string = "思维导图";
    desc: string = "帮助你生成思维导图";
    routePath: string = "/apps/aitool/widgets/mindmap/mindmapapp";
    iconUrl: string = avatar;


}



