


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_researchreport.png";

interface ResearchReportWidgetProps extends WidgetProps {
    pad?: string;
}
export class ResearchReportWidget extends BaseWidget<any, ResearchReportWidgetProps> {
    title: string = "调研报告";
    desc: string = "帮助你生成调研报告";
    routePath: string = "/apps/aitool/widgets/researchreport/researchreportapp";
    iconUrl: string = avatar;


}



