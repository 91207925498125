


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_netsectool.png";

interface NetSecToolWidgetProps extends WidgetProps {
    avatar: string;
    name: string;
    position: string;
}
export class NetSecToolWidget extends BaseWidget<any, NetSecToolWidgetProps> {
    title: string = "网络安全助手";
    desc: string = "协助生成网络安全命令";
    routePath: string = "/apps/aitool/widgets/netsectool/netsectoolapp";
    iconUrl: string = avatar;


}



