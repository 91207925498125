import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Badge} from "react-bootstrap";
import "@fullcalendar/react";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import useDocTrans from "../../../utils/doctrans";
import DocImportModal from "./DocImportModal";
import Conf from "../Utils/Conf";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import {FormatUtil} from "../Utils/FormatUtil";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";
import Select from "react-select";
import './DocBatch.css'
import {ModelSelector} from "../AiChat/ModelSelector";
import {ModelConfig} from "../../../utils/WebAppConfig";

const DocBatchApp = () => {
    const [docTransTasks, setDocTransTasks] = useState<any>([]);
    const docTransTable = useRef<any>(null);
    const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
    const docTrans = useDocTrans()
    const [modelConfig, setModelConfig] = useState<ModelConfig | null>(null);


    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: docTransTasks.length,
        },
    ];


    const columns = React.useMemo(
        () => [
            {
                Header: "任务ID",
                accessor: "id",
                sort: false,
            },
            {
                Header: "文件名",
                accessor: "file_name",
                sort: false,
            },
            {
                Header: "原始语言",
                accessor: "from_lang_code_action",
                Cell: (data: any) => (
                    <span>{docTrans.findLang(data.row.original.from_lang_code)}</span>
                )
            },
            {
                Header: "目标语言",
                accessor: "to_lang_code_action",
                Cell: (data: any) => (
                    <span>{docTrans.findLang(data.row.original.to_lang_code)}</span>
                )
            },
            {
                Header: "提交时间",
                accessor: "timestr",
                sort: false,
            },
            {
                Header: "状态",
                accessor: "status_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (<Badge bg={"success"}>翻译成功</Badge >)}
                        {data.row.original.status == "idle" && (<Badge bg={"info"}>正在排队</Badge>)}
                        {data.row.original.status == "processing" && (<Badge bg={"primary"}>{data.row.original.progress_text}</Badge>)}
                        {data.row.original.status == "error" && (<Badge bg={'danger'}>翻译出错</Badge>)}
                    </div>
                )
            },
            {
                Header: "结果",
                accessor: "result_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (<a href={data.row.original.result_url} target="_blank">下载</a>)}
                        {data.row.original.status == "processing" && (<span>{FormatUtil.formatProgress(data.row.original.progress_value)}</span>)}
                    </div>
                )
            }
        ],
        []
    );


    const getTasks = useCallback(() => {
        console.log("getTasks")
        auth_fetch_post_json(Conf.urlSecAiDocBatchTasksList, {
        })
            .then((data: any) => {
                setDocTransTasks(data.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const cleanTasks = useCallback(() => {
        auth_fetch_post_json(Conf.urlSecAiDocBatchTasksClean, {
        })
            .then((data: any) => {
                setDocTransTasks(data.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    useEffect(() => {

        const timer = setInterval(() => getTasks(), 1000)
        return () => {
            clearInterval(timer);
        };
    }, [getTasks]);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('批量翻译'))
    }, []);
    return (
        <>
            <Row>
                <Col className="d-flex align-items-center">
                    <ModelSelector usingInTrans={true} onModelChanged={model=>{setModelConfig(model)}} />
                    <Button variant="outline-secondary" className="ms-2 me-2"
                            onClick={() => setImportModalVisible(true)}>
                        添加任务
                    </Button>
                    <Button variant="outline-danger" className="" onClick={() => cleanTasks()}>
                        清除任务
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col md={12} xl={12}>
                    <Table
                        columns={columns}
                        data={docTransTasks}
                        pageSize={25}
                        sizePerPageList={sizePerPageList}
                        isSortable={true}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}
                        ref={docTransTable}
                    />
                </Col>
            </Row>

            {modelConfig && (<DocImportModal
                baseModel={modelConfig!.modelName}
                visible={importModalVisible} onHide={() => setImportModalVisible(false)}
                onTasks={tasks => setDocTransTasks(tasks)} />)}


        </>
    );
};

export default DocBatchApp;
