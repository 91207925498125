import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";
const imageTransLangs = [
    {"value": "CHS","label":  "简体中文"},
    {"value": "CHT","label":  "繁體中文"},
    {"value": "CSY","label":  "捷克语"},
    {"value": "NLD","label":  "荷兰语"},
    {"value": "ENG","label":  "英语"},
    {"value": "FRA","label":  "法语"},
    {"value": "DEU","label":  "德语"},
    {"value": "HUN","label":  "匈牙利语"},
    {"value": "ITA","label":  "意大利语"},
    {"value": "JPN","label":  "日语"},
    {"value": "KOR","label":  "韩语"},
    {"value": "PLK","label":  "波兰语"},
    {"value": "PTB","label":  "葡萄牙语（巴西）"},
    {"value": "ROM","label":  "罗马尼亚语"},
    {"value": "RUS","label":  "俄语"},
    {"value": "ESP","label":  "西班牙语"},
    {"value": "TRK","label":  "土耳其语"},
    {"value": "UKR","label":  "乌克兰语"},
    {"value": "VIN","label":  "越南语"},
    {"value": "ARA","label":  "阿拉伯语"}
]
function useImageTrans() {
    const [imageTransProgress, setImageTransProgress] = useState(0);
    const [imageTransStatus, setImageTransStatus] = useState("");
    const [imageTransResult, setImageTransResult] = useState("");
    const [isTranslating, setIsTranslating] = useState(false);
    const [statusText, setStatusText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // @ts-ignore
    const socketRef = useRef<WebSocket | null>(null);  // Explicitly type the ref here

    const startImageTrans = useCallback((fileId: string, langCode: string) => {
        if (socketRef.current) {
            socketRef.current.close();
        }
        setImageTransResult("")
        // @ts-ignore
        const socket = new WebSocket(Conf.urlSecaiImageTransFileTranslate);
        socketRef.current = socket;

        socket.onopen = () => {
            setIsTranslating(true)
            socket.send(JSON.stringify({
                action: "translate",
                source: fileId,
                langCode: langCode
            }));
        }

        socket.onmessage = (event: any) => {
            console.log("onmessage " + event.data)
            const json = JSON.parse(event.data);
            if(json.action === "progress") {
                setImageTransProgress(json.progress);
            } else if(json.action === "finished") {
                setImageTransProgress(100);
                setImageTransResult(json.result)
                socket.close()
            } else if(json.action === "error") {
                setErrorMessage(json['reason'])
                socket.close()
            }
        }
        socket.onclose = () => {
            setIsTranslating(false)
        }
    }, []);

    const stopImageTrans = useCallback(() => {
        if (socketRef.current) {
            socketRef.current.close();
        }
    }, []);


    return {
        imageTransProgress,
        startImageTrans,
        stopImageTrans, imageTransLangs,
        imageTransResult,
        isTranslating,
        statusText,
        errorMessage
    };
}

export default useImageTrans;
