import {Alert, Button, Form, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import classNames from "classnames";
import useUploadingMany, {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import Spinner from "../../../components/Spinner";
import React, {useCallback, useEffect, useState} from "react";
import Conf from "../Utils/Conf";
import Select from "react-select";
import useOcr from "../../../utils/ocr";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import useDocTrans from "../../../utils/doctrans";

interface DocImportModalProps {
    visible: boolean
    onHide: () => void
    onTasks: (tasks: any) => void
}


interface LangInfo {
    value: string
    label: string
}


const DocImportModal = (props: DocImportModalProps) => {
    const methodsDocImport = useForm({});
    const docsUploader = useUploadingMany(Conf.urlSecaiFoSend)
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const docTrans = useDocTrans()
    const defaultFromLang =    {"value": "en", "label": "英语"}
    const defaultToLang =    {"value": "zh", "label": "中文"}

    const [fromLang, setFromLang] = useState<LangInfo>(defaultFromLang);
    const [toLang, setToLang] = useState<LangInfo>(defaultToLang);

    const supportedFileTypes = {
        "文本文件": ['.txt', '.text', '.log']
    }
    const showHelp = () => {
        let msg = '支持的文件类型: '
        Object.entries(supportedFileTypes).forEach(([fileType, extensions]) => {
            msg += "\n" + fileType + ' (' + extensions.join(', ') + ') '
        })
        alert(msg)
    }




    const addTask = async (values: any, event: any) => {
        console.log(values)
        console.log(event)
        setIsSaving(true)

        const json = {
            "from_lang_code": fromLang.value,
            "to_lang_code": toLang.value,
            "items": [
                ...docsUploader.uploadedItems!.map((item: UploadingManyUploadedItem) => {
                    return {
                        "file_name": item.fileName,
                        "file_id": item.fileId
                    }
                })
            ]
        }


        auth_fetch_post_json(Conf.urlSecAiDocBatchTasksAdd, json)
            .then((data: any) => {
                console.log("tasksAdd:");

                console.log(data)
                props.onTasks(data.data)
                props.onHide()
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(() => {
            setIsSaving(false)
            docsUploader.clean()
        })
        return false
    }

    const handleSwitchLang = () => {
        const tmpLang = fromLang
        setFromLang(toLang)
        setToLang(tmpLang)

    }
    return (
        <Modal show={props.visible} onHide={props.onHide} backdrop="static" size={"lg"}
               keyboard={false}>
            <FormProvider {...methodsDocImport}>
                <Form onSubmit={methodsDocImport.handleSubmit(addTask)}>
                    <Modal.Header closeButton>
                        <h4 className="modal-title">批量添加文档翻译任务</h4>
                        <button type={"button"} className={"btn btn-outline-info btn-xs"}
                                onClick={() => showHelp()}>
                            <i className="mdi mdi-help"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <div className="row">
                            <div className="col">
                                <div style={{
                                    borderRadius: '0.5rem',
                                    backgroundColor: '#dddddd',
                                    padding: '1rem'
                                }}>
                                    <div className="col d-flex" style={
                                        {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }
                                    }>
                                        <button
                                            type={"button"}
                                            className={"btn btn-outline-primary me-3"}
                                            disabled={docsUploader.isUploading || isSaving} onClick={() => {
                                            docsUploader.startUpload(
                                                Object.entries(supportedFileTypes).map(v => v[1].join(", ")).join(", ")
                                            )
                                        }}><i className={"icon-cloud-upload me-1"}></i>添加
                                        </button>
                                        <button type={"button"}
                                                className={"btn d-inline-block btn-outline-danger waves-effect waves-light me-3"}
                                                disabled={docsUploader.isUploading || isSaving} onClick={() => {
                                            docsUploader.clean()
                                        }}><i className={"icon-trash me-1"}></i>清空
                                        </button>





                                        <Select
                                            className="react-select react-select-container me-2"
                                            classNamePrefix="react-select"
                                            options={docTrans.docTransLangs}
                                            defaultValue={defaultFromLang}
                                            value={fromLang}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '160px',
                                                }),
                                            }}
                                            onChange={x => {
                                                setFromLang(x ? x : defaultFromLang)
                                            }}
                                        ></Select>
                                        <Button onClick={() => handleSwitchLang()} variant="default"
                                                className="btn-info waves-effect waves-light  me-2">
                                            <i className="fas fa-exchange-alt"></i>
                                        </Button>
                                        <Select
                                            className="react-select react-select-container"
                                            classNamePrefix="react-select"
                                            options={docTrans.docTransLangs}
                                            value={toLang}
                                            defaultValue={defaultToLang}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '160px',
                                                }),
                                            }}
                                            onChange={x => setToLang(x ? x : defaultToLang)}
                                        ></Select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3"
                                     style={{maxHeight: "256px", overflowY: "auto"}}>
                                    {docsUploader.uploadedItems?.map((item: UploadingManyUploadedItem) => {
                                        return (
                                            <div key={item.fileId}><strong
                                                color={'secondary'}>{item.fileName}</strong>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>


                    </Modal.Body>

                    <Modal.Footer>
                        <span hidden={!docsUploader.isUploading}><Spinner  className="spinner-border-sm"/></span>
                        <span>{docsUploader.statusText}</span>
                        <span hidden={!isSaving}><Spinner className="spinner-border-sm"/></span>
                        <span hidden={!isSaving}>正在保存</span>
                        <button
                            type="button"
                            className="btn btn-secondary waves-effect"
                            disabled={isSaving}
                            onClick={() => {
                                docsUploader.stopUpload()
                                props.onHide()
                            }}
                        >
                            取消
                        </button>
                        <button type="submit"
                                disabled={isSaving}
                                className="btn btn-primary waves-effect waves-light">保存
                        </button>

                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}

export default DocImportModal;