import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Card, Button, Form} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import {users} from "../AiChat/data";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import useLogDownloader from "../../../utils/loaddownloader";
import {FormInput} from "../../../components";
import HyperDatepicker from "../../../components/Datepicker";


const sizePerPageList = [
    {
        text: "5",
        value: 5,
    },
    {
        text: "10",
        value: 10,
    },
    {
        text: "25",
        value: 25,
    },
    {
        text: "All",
        value: users.length,
    },
];



const NetLogsApp = () => {

    const [searchIdEnabled, setSearchIdEnabled] = useState(false)
    const [searchUserIpEnabled, setSearchUserIpEnabled] = useState(false)
    const [searchUserIdEnabled, setSearchUserIdEnabled] = useState(false)
    const [searchUrlEnabled, setSearchUrlEnabled] = useState(false)
    const [searchMethodEnabled, setSearchMethodEnabled] = useState(false)
    const [searchDetailEnabled, setSearchDetailEnabled] = useState(false)
    const [searchTimeStartEnabled, setSearchTimeStartEnabled] = useState(false)
    const [searchTimeStopEnabled, setSearchTimeStopEnabled] = useState(false)

    const [searchId, setSearchId] = useState<string>("")
    const [searchUserIp, setSearchUserIp] = useState<string>("")
    const [searchUserId, setSearchUserId] = useState<string>("")
    const [searchUrl, setSearchUrl] = useState<string>("")
    const [searchMethod, setSearchMethod] = useState<string>("")
    const [searchDetail, setSearchDetail] = useState<string>("")
    const [searchTimeStart, setSearchTimeStart] = useState<Date>(new Date());
    const [searchTimeStop, setSearchTimeStop] = useState<Date>(new Date());


    const onSearchTimeStartChanged = (date: Date) => {
        if (date) {
            setSearchTimeStart(date);
        }
    };
    const onSearchTimeStopChanged = (date: Date) => {
        if (date) {
            setSearchTimeStop(date);
        }
    };

    const exporter = useLogDownloader()
    const [netLogs, setNetLogs] = useState<any>()
    const columns = React.useMemo(
        () => [
            {
                Header: "日志编号",
                accessor: "id",
                sort: false,
            },
            {
                Header: "客户端IP",
                accessor: "client_ip",
                sort: false,
            },
            {
                Header: "用户编号",
                accessor: "user_id",
                sort: false,
            },
            {
                Header: "请求地址",
                accessor: "url",
                sort: false,
            },
            {
                Header: "请求方法",
                accessor: "method",
                sort: false,
            },
            {
                Header: "发生时间",
                accessor: "timestr",
                sort: false,
            },
            {
                Header: "操作",
                accessor: "op_details",
                Cell: (data: any) => (
                    <div>
                        <Button
                            type={"button"} className={"btn btn-primary"}
                            onClick={()=> {
                                alert(data.row.original.headers)
                            }}
                        ><i className={"mdi mdi-eye"}></i></Button>
                    </div>
                )
            },
        ],
        []
    );



    const getNetLogs = useCallback(() => {
        const conditions = {
            "searchIdEnabled": searchIdEnabled,
            "searchUserIpEnabled": searchUserIpEnabled,
            "searchUserIdEnabled": searchUserIdEnabled,
            "searchUrlEnabled": searchUrlEnabled,
            "searchMethodEnabled": searchMethodEnabled,
            "searchDetailEnabled": searchDetailEnabled,
            "searchTimeStartEnabled": searchTimeStartEnabled,
            "searchTimeStopEnabled": searchTimeStopEnabled,
            "searchId": searchId,
            "searchUserIp": searchUserIp,
            "searchUserId": searchUserId,
            "searchUrl": searchUrl,
            "searchMethod": searchMethod,
            "searchDetail": searchDetail,
            "searchTimeStart": searchTimeStart.getTime(),
            "searchTimeStop": searchTimeStop.getTime()
        }
        auth_fetch_post_json(Conf.urlNetLogs, conditions)
            .then((json: any) => {
                setNetLogs(json)

            })
            .catch((error) => {
                console.log(error)
            });
    }, [
        searchIdEnabled,
        searchUserIpEnabled,
        searchUserIdEnabled,
        searchUrlEnabled,
        searchMethodEnabled,
        searchDetailEnabled,
        searchTimeStartEnabled,
        searchTimeStopEnabled,
        searchId,
        searchUserIp,
        searchUserId,
        searchUrl,
        searchMethod,
        searchDetail,
        searchTimeStart,
        searchTimeStop,

    ]);


    useEffect(() => {
        getNetLogs();
    }, []);




    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/netlogs" },
                    { label: "网络日志", path: "/apps/netlogs", active: true },
                ]}
                title={"网络日志"}
            />

            <Row>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"日志编号"}
                        checked={searchIdEnabled}
                        onChange={e => setSearchIdEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchId} onChange={e => setSearchId(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"客户端IP"}
                        checked={searchUserIpEnabled}
                        onChange={e => setSearchUserIpEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchUserIp} onChange={e => setSearchUserIp(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"用户编号"}
                        checked={searchUserIdEnabled}
                        onChange={e => setSearchUserIdEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchUserId} onChange={e => setSearchUserId(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"请求地址"}
                        checked={searchUrlEnabled}
                        onChange={e => setSearchUrlEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchUrl} onChange={e => setSearchUrl(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"请求方法"}
                        checked={searchMethodEnabled}
                        onChange={e => setSearchMethodEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchUrl} onChange={e => setSearchMethod(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"详情"}
                        checked={searchDetailEnabled}
                        onChange={e => setSearchDetailEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchUrl} onChange={e => setSearchDetail(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label={"发生时间开始"}
                            checked={searchTimeStartEnabled}
                            onChange={e => setSearchTimeStartEnabled(e.target.checked)}

                        />
                        <HyperDatepicker
                            hideAddon={true}
                            showTimeSelect
                            timeFormat="HH:mm"
                            tI={60}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            value={searchTimeStart}
                            onChange={(date) => {
                                onSearchTimeStartChanged(date);
                            }}
                        />
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label={"发生时间结束"}
                            checked={searchTimeStopEnabled}
                            onChange={e => setSearchTimeStopEnabled(e.target.checked)}
                        />
                        <HyperDatepicker
                            hideAddon={true}
                            showTimeSelect
                            timeFormat="HH:mm"
                            tI={60}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            value={searchTimeStop}
                            onChange={(date) => {
                                onSearchTimeStopChanged(date);
                            }}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button className={"btn btn-primary"} onClick={() => getNetLogs()}><i className={"mdi mdi-search me-1"}></i>查询</Button>
                </Col>
            </Row>


            <Row>
                <Col sm={12} md={6} lg={9}>

                    <Table
                        columns={columns}
                        data={netLogs? netLogs: []}
                        pageSize={10}
                        sizePerPageList={sizePerPageList}
                        isSortable={false}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}

                    />
                </Col>

            </Row>

        </>
    );
};

export default NetLogsApp;
