import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";
import { withSwal } from "react-sweetalert2";

// components
import PageTitle from "../../../components/PageTitle";

import Blank from "./ImageTrans";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Conf from "../Utils/Conf";
import useUploading from "../../../utils/uploading";
import SweetAlerts from "../../uikit/SweetAlerts";
import UploadingProgressModal from "../../../utils/UploadingProgressModal";
import ErrorModal from "../../../utils/ErrorModal";
import useImageTrans from "../../../utils/imagetrans";
import useFilePoster from "../../../utils/fileposter";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";


const ImageTransApp = () => {
    const uploading = useUploading(Conf.urlSecaiFoSend)
    const imageTrans = useImageTrans()
    const [langCode, setLangCode] = useState("CHS")
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('图像翻译'))
    }, []);
    return (
        <>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <Card>
                        <Card.Header>翻译前图片</Card.Header>
                        <Card.Body>
                            <img src={uploading.fileUrl} width={"100%"} height={"100%"} hidden={uploading.fileUrl == ""}/>

                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col>
                                    <Button variant="primary" className="mr-2 me-3" onClick={() => uploading.startUpload('image/png, image/jpeg')}><i className={"bi bi-upload me-1"}></i>上传</Button>
                                </Col>
                                <Col>
                                    <Select
                                        className="react-select react-select-container"
                                        classNamePrefix="react-select"
                                        options={imageTrans.imageTransLangs}
                                        defaultValue={
                                            {"value": "CHS", "label": "简体中文"}
                                        }
                                        onChange={x => setLangCode(x? x.value: 'zh')}
                                    ></Select>
                                </Col>
                                <Col>
                                    <Button variant="danger" className="mr-1" disabled={imageTrans.isTranslating} onClick={() => imageTrans.startImageTrans(uploading.fileId, langCode)}><i className={"bi bi-translate me-1"}></i>翻译</Button>

                                </Col>

                            </Row>

                        </Card.Footer>
                    </Card>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <Card>
                        <Card.Header>翻译后图片</Card.Header>
                        <Card.Body>
                            <img src={imageTrans.imageTransResult} width={"100%"} height={"100%"} hidden={imageTrans.imageTransResult == ""}/>
                        </Card.Body>
                        <Card.Footer>
                            <Button onClick={()=>window.open(imageTrans.imageTransResult)} variant="primary" className="mr-1" disabled={imageTrans.imageTransResult == ""}><i className={"bi bi-cloud-download me-1"}></i>下载</Button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <UploadingProgressModal fileName={uploading.fileName} fileSize={uploading.fileSize} progress={uploading.progress} statusText={uploading.statusText} show={uploading.uploading} onHide={()=> {}} onStopClicked={()=> {}}></UploadingProgressModal>
            <ErrorModal show={uploading.errorMessage!=''} title={"上传错误"} message={uploading.errorMessage}></ErrorModal>
            <ErrorModal show={imageTrans.errorMessage!=''} title={"翻译错误"} message={imageTrans.errorMessage}></ErrorModal>

        </>
    );
};

export default ImageTransApp;
