import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import avatar2 from "../../../assets/images/users/user-4.jpg";
import Select from "react-select";
import {users} from "../AiChat/data";
import {auth_fetch_post_json, useAuthFetchPost} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import useTts from "../../../utils/tts";
import SpeakerCard from "./SpeakerCard";


interface TtsSpeaker {
    speakerId: string
    name: string
    avatarUrl: string
    desc: string
    sex: string

}
const TtsApp = () => {
    const [ttsSpeakers, setTtsSpeakers] = useState<TtsSpeaker[]>([])
    const tts = useTts()
    const [speaker, setSpeaker] = useState<TtsSpeaker>()
    const [textToConvert, setTextToConvert] = useState<string>('')

    const [selectedValue, setSelectedValue] = useState(speaker ? {
        value: speaker.speakerId,
        label: speaker.name
    } : null);

    const selectSpeaker = (speakerId:string) => {
        const speaker = ttsSpeakers.find(speaker => speaker.speakerId == speakerId)
        if(speaker) {
            setSpeaker(speaker)
            setSelectedValue({
                value: speaker.speakerId,
                label: speaker.name
            })
        }
    }
    useEffect(() => {
        auth_fetch_post_json( Conf.urlTtsSpeakers, {}).then((data: any) => {
            setTtsSpeakers(data)
            setSpeaker(data[0])
            setSelectedValue({
                value: data[0].speakerId,
                label: data[0].name
            })
        })
    }, []);
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps" },
                    { label: "语音合成", path: "/apps/tts", active: true },
                ]}
                title={"语音合成"}
            />

            <Row>
                <Col md={3} xl={3}>
                    <h4 className="header-title mb-3">选择播音员</h4>
                    <Select
                        className="react-select react-select-container"
                        classNamePrefix="react-select"
                        options={
                            ttsSpeakers.map(speaker => {
                                return {
                                    'key': speaker.speakerId,
                                    'value': speaker.speakerId,
                                    'label': speaker.name
                                }
                            })
                        }

                        value={selectedValue}
                        onChange={(e: any) => {
                            selectSpeaker(e.value)
                            setSelectedValue(e);
                        }}

                    ></Select>

                    <SpeakerCard
                        avatar={speaker? speaker.avatarUrl: ''}
                        name={speaker? speaker.name: ''}
                        sex={speaker?speaker.sex : ''}
                        desc={speaker?speaker.desc : ''}
                    />
                    <Button
                        disabled={tts.isTtsing}
                        color={"primary"} size={"sm"} onClick={()=> {
                        if(speaker) {
                            tts.startTts(speaker.speakerId, textToConvert)
                        } else {
                            alert('请选择播音员')
                        }
                    }}>开始合成</Button>
                </Col>
                <Col  md={9} xl={9}>
                    <h4 className="header-title mb-3">合成文本</h4>
                    <textarea
                        style={{width: "100%", height: "320px"}}
                        onChange={(e) => {
                            setTextToConvert(e.target.value)
                        }}
                    ></textarea>
                    <audio src={tts.ttsResult}  controls={true} style={{width: '100%'}}></audio>
                </Col>
            </Row>

        </>
    );
};

export default TtsApp;
