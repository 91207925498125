


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_ruleswrite.png";

interface RulesWriteWidgetProps extends WidgetProps {
    pad?: string;
}
export class RulesWriteWidget extends BaseWidget<any, RulesWriteWidgetProps> {
    title: string = "规章制度助手";
    desc: string = "帮您编写规章制度";
    routePath: string = "/apps/aitool/widgets/ruleswrite/ruleswriteapp";
    iconUrl: string = avatar;


}



