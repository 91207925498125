import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Modal, Form, Alert} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import DocBase from "./DocBase";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Table from "../../../components/Table";
import {FormProvider, useForm} from "react-hook-form";
import {users} from "../AiChat/data";
import Conf from "../Utils/Conf";
import ReactDOM from "react-dom/client";
import {withSwal} from "react-sweetalert2";
import Spinner from "../../../components/Spinner";
import {APICore} from "../../../helpers/api/apiCore";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import useUploading from "../../../utils/uploading";
import useUploadingMany, {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import UploadedItemCard from "./UploadedItemCard";
import StatusModal from "../../../utils/StatusModal";
import useWebAppConfig from "../../../utils/WebAppConfig";



const DocBaseApp = withSwal((props: any) => {
    const { swal } = props;

    const docBaseTable = useRef<any>(null);
    const docsTable = useRef<any>(null);
    const [newDocBaseVisible, setNewDocBaseVisible] = useState<boolean>(false);
    const [addDocVisible, setAddDocVisible] = useState<boolean>(false);
    const [docBases, setDocBases] = useState<any>([]);
    const [docs, setDocs] = useState<any>([]);
    const [newDocGroups, setNewDocGroups] = useState<any>([]);
    const [groups, setGroups] = useState<any>([{ id: 0, text: "我的组织" }]);

    const [currentDocBaseUuid, setCurrentDocBaseUuid] = useState<string>("");
    const [currentDocBaseName, setCurrentDocBaseName] = useState<string>("");
    const [currentDocBaseDesc, setCurrentDocBaseDesc] = useState<string>("");
    const [isDocBaseBuilding, setIsDocBaseBuilding] = useState<boolean>(false);
    const [isDocBaseModalEditing, setIsDocBaseModalEditing] = useState<boolean>(false);
    const [currentEditingDocBaseUuid, setCurrentEditingDocBaseUuid] = useState<string>("");
    const docsUploader = useUploadingMany(Conf.urlSecaiFoSend)
    const webAppConfig = useWebAppConfig()
    useEffect(() => {
        webAppConfig.loadWebAppConfig()
    }, []);


    const columnsDocBase = React.useMemo(
        () => [
            {
                Header: "知识库名",
                accessor: "name",
                sort: true,
            },
            {
                Header: "允许部门",
                accessor: "groupNames",
                sort: true,
            },

            {
                Header: '操作', // 列的标题
                accessor: 'actions', // 用于数据访问的键，但由于我们正在渲染自定义内容，所以这实际上不会被使用
                Cell: (data:any) => (
                    <div>
                        <button
                            type="button" className="btn btn-xs btn-outline-primary me-2"
                            onClick= {
                                () => {
                                    console.log(data.row)
                                    const newData = data.row.original
                                    setCurrentEditingDocBaseUuid(newData.uuid)
                                    setIsDocBaseModalEditing(true)
                                    reset(newData)
                                    setNewDocBaseVisible(true)


                                }
                            }
                        >
                            <i className="mdi mdi-pencil"></i>
                        </button>
                        <button
                            type="button" className="btn btn-xs btn-outline-danger"
                            onClick= {
                                () => {
                                    console.log(data.row)
                                    swal.fire({
                                        title: "确定要删除知识库吗?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消",
                                    }).then((result: any) => {
                                        if (result.isConfirmed) {
                                            removeDocBases([data.row.original.uuid])
                                        }
                                    });
                                }
                            }
                        >
                            <i className="mdi mdi-close"></i>
                        </button>
                    </div>

                ),
            },
        ],
        []
    );





    const columnsDocs = React.useMemo(
        () => [
            {
                Header: "文件名",
                accessor: "name",
                sort: true,
            },
            {
                Header: "文件大小",
                accessor: "docCount",
                sort: true,
            },
            {
                Header: "文字块",
                accessor: "chunkCount",
                sort: true,
            },
            {
                Header: '操作', // 列的标题
                accessor: 'actions', // 用于数据访问的键，但由于我们正在渲染自定义内容，所以这实际上不会被使用
                Cell: (data:any) => (
                    <div>
                        <button
                            type="button" className="btn btn-xs btn-outline-danger"
                            onClick= {
                                () => {
                                    console.log(data.row)
                                    swal.fire({
                                        title: "确定要删除文档吗?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消",
                                    }).then((result: any) => {
                                        if (result.isConfirmed) {
                                            removeDocs(currentDocBaseUuid, [data.row.original.uuid])
                                        }
                                    });

                                }
                            }
                        >
                            <i className="mdi mdi-close"></i>
                        </button>
                    </div>

                ),
            },
        ],
        []
    );
    const pageSizeDocBase = () => [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "全部",
            value: docBases.length,
        },
    ];

    const pageSizeDocs = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "全部",
            value: docs.length,
        },
    ];


    const getGroupsAll = useCallback(() => {
        auth_fetch_post_json(Conf.urlUsersGrouplist, {
        })
            .then((data: any) => {
                console.log(data)
                setGroups(data.flatGroups)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);



    const getDocBases = useCallback(() => {
        const api = new APICore()

        auth_fetch_post_json(Conf.urlFileChatDocBaseList, {
            'group': 0

        })
            .then((data: any) => {
                setDocBases(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);


    const getDocs = useCallback(() => {
        console.log("getDocs... " + currentDocBaseUuid + " " + currentDocBaseName)
        if(currentDocBaseUuid == "") {
            return
        }
        auth_fetch_post_json(Conf.urlFileChatDocBaseDocList, {
            'docbase_uuid': currentDocBaseUuid,
        })
            .then((data: any) => {
                setDocs(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [currentDocBaseUuid]);



    useEffect(() => {
        getGroupsAll();
    }, [getGroupsAll]);

    useEffect(() => {
        getDocBases();
    }, [getDocBases]);

    useEffect(() => {
        getDocs();
    }, [getDocs]);


    const methodsDocBase = useForm({});
    const methodsDocAdd = useForm({});


    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        reset,
    } = methodsDocBase;

    const {
        handleSubmit: handleSubmitDocAdd,
        register: registerDocAdd,
        control: controlDocAdd,
        formState: { errors: errorsDocAdd },
        reset: resetDocAdd,

    } = methodsDocAdd;

    const toggleNewDocBaseModal = () => {
        setNewDocBaseVisible(!newDocBaseVisible)
    };

    const toggleAddDocModal = () => {
        setAddDocVisible(!addDocVisible)
    };

    const newDocBase = async (values: any, event: any) => {
        console.log("newDocBase: ")
        console.log(values)
        console.log(event)
        console.log("newDocGroups")
        console.log(newDocGroups)

        const json: any = {}
        json["name"] = values.name
        json["groups"] = newDocGroups
        json["desc"] = values.desc


        if (isDocBaseModalEditing) {
            json["docbase_uuid"] = currentEditingDocBaseUuid
        }

        const url = isDocBaseModalEditing? Conf.urlFileChatDocBaseUpdate : Conf.urlFileChatDocBaseCreate
        auth_fetch_post_json(url, json)
            .then(data => {
                setDocBases(data)
                setNewDocBaseVisible(false)
                reset();
            })
            .catch((error) => {
                alert(error)
                reset();
            })
        return false
    }

    const handleResetNewDocBase = () => {
        reset();
        setNewDocBaseVisible(false)
    }
    const removeDocBases = (uuids: string[]) => {
        console.log("removeDocBases: ")
        console.log(uuids)
        auth_fetch_post_json(Conf.urlFileChatDocBaseRemove, {
            'uuids': uuids
        })
            .then((data: any) => {
                setDocBases(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const buildDocBase = useCallback(()=> {
        setIsDocBaseBuilding(true)
        auth_fetch_post_json(Conf.urlFileChatDocBaseBuild, {
            'docbase_uuid': currentDocBaseUuid

        })
            .then((data: any) => {
                /// setDocBases(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                setIsDocBaseBuilding(false)
            })

    }, [currentDocBaseUuid])
    const addDoc = async (values: any, event: any) => {
        console.log(values)
        console.log(event)

        const json = {
            "docbase_uuid": currentDocBaseUuid,
            "items": [
                ...docsUploader.uploadedItems!.map((item: UploadingManyUploadedItem) => {
                    return {
                        "file_name": item.fileName,
                        "file_id": item.fileId
                    }
                })
            ]
        }


        auth_fetch_post_json(Conf.urlFileChatDocBaseDocAddMany, json)
            .then((data: any) => {
                setDocs(data)
                setAddDocVisible(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(() => {
                docsUploader.clean()
        })
        return false
    }

    const removeDocs = useCallback((docbase_uuid: string, uuids: string[]) => {
        console.log("removeDocs: ")
        console.log(docbase_uuid)
        console.log(uuids)
        auth_fetch_post_json(Conf.urlFileChatDocBaseDocRemove, {
            "docbase_uuid": docbase_uuid,
            'uuids': uuids
        })
            .then((data: any) => {
                setDocs(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [currentDocBaseUuid])

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/docbase" },
                    { label: "DocBase", path: "/apps/docbase", active: true },
                ]}
                title={"知识库管理"}
            />

            <Row>
                <Col sm={12} md={12} lg={12}>
                    <Card>
                        <Card.Header>
                            <h4 className="header-title">知识库</h4>
                            <button type="button" className="btn btn-outline-primary btn-sm me-2"
                                    onClick={ () => {
                                        reset()
                                        setIsDocBaseModalEditing(false)
                                        toggleNewDocBaseModal()
                                    }
                                    }
                            ><i className={"mdi mdi-plus"}></i>创建知识库
                            </button>

                            <button type="button" className="btn btn-outline-danger btn-sm me-2"  onClick={()=> {
                                const selectedData = docBaseTable.current!.getSelectedRows();
                                const selectedIds = selectedData.map((item: any) => item.uuid);
                                if(selectedIds.length == 0) {
                                    swal.fire({
                                        title: "请在下方勾选要删除的知识库",
                                        icon: "warning",
                                        confirmButtonColor: "#3085d6",
                                        confirmButtonText: "确定",
                                    });
                                    return
                                } else {
                                    swal.fire({
                                        title: "确定要删除选中的知识库吗?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消",
                                    }).then((result: any) => {
                                        if (result.isConfirmed) {
                                            removeDocBases(selectedIds)
                                        }
                                    });
                                }
                            }}><i className={"mdi mdi-trash-can"}></i>删除知识库</button>



                        </Card.Header>
                        <Card.Body>
                            <Table
                                columns={columnsDocBase}
                                data={docBases}
                                pageSize={5}
                                sizePerPageList={pageSizeDocBase()}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                                isSelectable={true}
                                ref={docBaseTable}
                                onRowClicked={
                                    (row: any) => {
                                        console.log(row)
                                        setCurrentDocBaseUuid(row.original.uuid)
                                        setCurrentDocBaseName(row.original.name)
                                        setCurrentDocBaseDesc(row.original.desc)
                                        getDocs()

                                    }
                                }
                            />
                            <Modal show={newDocBaseVisible} onHide={toggleNewDocBaseModal}>
                                <FormProvider {...methodsDocBase}>
                                    <Form onSubmit={handleSubmit(newDocBase)}>
                                        <Modal.Header closeButton>
                                            <h4 className="modal-title">{isDocBaseModalEditing? "编辑知识库" : "添加知识库"}</h4>
                                        </Modal.Header>
                                        <Modal.Body className="p-4">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="field-docbase-name" className="form-label">
                                                            知识库名
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="field-docbase-name"
                                                            placeholder="输入知识库的名称"
                                                            required
                                                            {...register("name", { required: true })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="field-docbase-groups" className="form-label">
                                                            可用部门
                                                        </label>
                                                        <Select
                                                            isMulti={true}
                                                            options={
                                                                groups.map((item: any) => {
                                                                    return { value: item.id, label: item.fullPath }
                                                                })
                                                            }
                                                            className="react-select react-select-container"
                                                            classNamePrefix="react-select"
                                                            onChange={(e) => setNewDocGroups(e.map((item: any) => item.value))}
                                                        ></Select>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="">
                                                        <label htmlFor="field-7" className="form-label">
                                                            描述
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="field-7"
                                                            placeholder="输入知识库的一些介绍"
                                                            {...register("desc", { required: true })}

                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <button
                                                type="button"
                                                className="btn btn-secondary waves-effect"
                                                onClick={ handleResetNewDocBase}
                                            >
                                                关闭
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-info waves-effect waves-light"
                                            >
                                                保存
                                            </button>
                                        </Modal.Footer>
                                    </Form>
                                </FormProvider>
                            </Modal>

                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={12}>
                    <Card>
                        <Card.Header>
                            <h4 className="header-title">{currentDocBaseName}</h4>
                            <p className="text-muted">{currentDocBaseDesc}</p>


                            <button  type={"button"} className={"btn btn-primary btn-sm me-2"} onClick={
                                () => {

                                    toggleAddDocModal()
                                }

                            }><i className={"mdi mdi-plus"}></i>添加文档</button>
                            <button type={"button"} className={"btn btn-danger btn-sm me-2"}
                                    onClick={
                                        () => {
                                            const selectedData = docsTable.current!.getSelectedRows();
                                            const selectedIds = selectedData.map((item: any) => item.uuid);
                                            removeDocs(currentDocBaseUuid, selectedIds)
                                        }

                                    }
                            ><i className={"mdi mdi-trash-can"}></i>删除文档</button>
                            <button type={"button"} className={"btn btn-primary btn-sm me-2"}
                                    disabled={isDocBaseBuilding}
                                    onClick = {
                                        () => {
                                            buildDocBase()
                                        }
                                    }
                            >
                                    <span hidden={!isDocBaseBuilding}>
                                    <Spinner
                                        className="spinner-border-sm me-1"
                                        color="white"
                                    />
                                    </span>
                                    <span hidden={!isDocBaseBuilding}>编译中...</span>
                                    <span hidden={isDocBaseBuilding}><i className={"mdi mdi-set-square"}></i>编译知识库</span>


                            </button>

                        </Card.Header>
                        <Card.Body>
                            <Table
                                columns={columnsDocs}
                                data={docs}
                                pageSize={5}
                                sizePerPageList={pageSizeDocs}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                                isSelectable={true}
                                ref={docsTable}
                            />

                            <Modal show={addDocVisible} onHide={toggleAddDocModal} backdrop="static"  keyboard={false}>
                                <FormProvider {...methodsDocAdd}>
                                    <Form onSubmit={handleSubmitDocAdd(addDoc)}>
                                        <Modal.Header closeButton>
                                            <h4 className="modal-title">添加文档们到知识库</h4>
                                        </Modal.Header>
                                        <Modal.Body className="p-4">
                                            <Alert
                                                variant={'danger'}
                                                className={classNames(
                                                    "bg-danger",
                                                    "border-0",
                                                    "text-white"
                                                )}
                                            >
                                                <i className={"mdi mdi-alert"} hidden={!webAppConfig.hasSecret}></i>本产品密级为<strong>内部</strong>， 请不要上传更高安全级别的文件
                                            </Alert>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-1">
                                                        <label htmlFor="field-1" className="form-label">
                                                            以下文档将被添加到知识库
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3" style={{maxHeight: "256px", overflowY: "auto"}}>
                                                        {docsUploader.uploadedItems?.map((item: UploadingManyUploadedItem) => {
                                                            return (
                                                                <div><strong key={item.fileId} color={'secondary'}>{item.fileName}</strong></div>
                                                            )
                                                        })}
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button type={"button"} className={"btn btn-xs btn-outline-secondary waves-effect waves-light me-3"}
                                                        disabled={docsUploader.isUploading} onClick={() => {
                                                            docsUploader.clean()
                                                    }}><i className={"icon-trash me-1"}></i>清空列表</button>
                                                    <button
                                                        type={"button"} className={"btn btn-xs btn-outline-primary me-1"}
                                                        disabled={docsUploader.isUploading} onClick={() => {
                                                        docsUploader.startUpload(".txt, .pdf, .docx, .csv, .tsv, .xlsx, .pptx")
                                                    }}><i className={"icon-cloud-upload me-1"}></i>导入文件</button>
                                                    <span hidden={!docsUploader.isUploading}><Spinner className="spinner-border-sm" /></span>
                                                    <span>{docsUploader.statusText}</span>
                                                </div>
                                                <div className={"text-mute"}>当前支持的文件格式有: txt, pdf, docx, csv, tsv, xlsx, pptx</div>
                                            </div>

                                        </Modal.Body>

                                        <Modal.Footer>
                                            <button
                                                type="button"
                                                className="btn btn-secondary waves-effect"
                                                onClick={() => {
                                                    docsUploader.stopUpload()
                                                    toggleAddDocModal()
                                                }}
                                            >
                                                取消
                                            </button>
                                            <button type="submit" className="btn btn-primary waves-effect waves-light">保存</button>
                                        </Modal.Footer>
                                    </Form>
                                </FormProvider>
                            </Modal>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <StatusModal
                show={isDocBaseBuilding}
                title={"编译中..."}
                message={"正在编译知识库, 可能会花比较长的时间, 请耐心等候."}
            ></StatusModal>

        </>
    );
});

export default DocBaseApp;
