
class Conf {
    constructor() {

    }
    theme() {
        return "light"
    }
    backendApi = (path: string) => `${path}`

    wsPrefix = () => window.location.protocol=="http:" ? "ws" : "wss"
    wsApi = (path: string) =>  `${this.wsPrefix()}://${window.location.host}${path}`


    public urlLawHelper = `http://ai.picorock.com:1234/?__theme=${this.theme()}`

    // AUTH
    public urlJwtLogin = this.backendApi("/secai/backend/auth/token")
    public urlJwtLoginAdmin = this.backendApi("/secai/backend/auth/token_admin")
    public urlJwtMe = this.backendApi("/secai/backend/auth/users/me")
    public urlGoalLogin = this.backendApi("/secai/backend/auth/goal_login")
    public urlGoalMe = this.backendApi("/secai/backend/auth/goal_me")
    public urlGoalAuthenticated = this.backendApi("/secai/backend/auth/users/goal_authenticated")

    // GEN
    public urlTextGenerate = this.backendApi("/secai/backend/gen/text_generate")

    //CHAT
    public urlChatSse = this.backendApi("/secai/backend/chat/sse")
    public urlChatFromInfo = this.backendApi("/secai/backend/chat/from_info")
    public urlChatWs = this.wsApi("/secai/backend/chat/ws")
    public urlApiChatList = this.backendApi("/secai/backend/chat/list")
    public urlApiChatClean = this.backendApi("/secai/backend/chat/clean")

    //CHAT2
    public urlChat2Sse = this.backendApi("/secai/backend/chat2/sse")
    public urlChat2FromInfo = this.backendApi("/secai/backend/chat2/from_info")
    public urlChat2Ws = this.wsApi("/secai/backend/chat2/ws")
    public urlApiChat2List = this.backendApi("/secai/backend/chat2/list")
    public urlApiChat2Clean = this.backendApi("/secai/backend/chat2/clean")
    public urlApiChat2DialogList = this.backendApi("/secai/backend/chat2/dialog_list")
    public urlApiChat2DialogCreate = this.backendApi("/secai/backend/chat2/dialog_create")
    public urlApiChat2DialogClean = this.backendApi("/secai/backend/chat2/dialog_clean")
    public urlApiChat2DialogUsage = this.backendApi("/secai/backend/chat2/dialog_usage")
    public urlApiChat2DialogSwitch = this.backendApi("/secai/backend/chat2/dialog_switch")
    public urlApiChat2DialogRemove = this.backendApi("/secai/backend/chat2/dialog_remove")
    public urlApiChat2DialogTitleGen = this.backendApi("/secai/backend/chat2/dialog_title_gen")

    public urlOfFile = (path: string) => this.backendApi(path)


    public urlUsersGrouplist = this.backendApi("/secai/backend/users/group_list")
    public urlUsersGroupUpdate = this.backendApi("/secai/backend/users/group_update")
    public urlUsersGroupNew = this.backendApi("/secai/backend/users/group_new")
    public urlUsersGroupRemove = this.backendApi("/secai/backend/users/group_remove")
    public urlUsersUserRemove = this.backendApi("/secai/backend/users/user_remove")
    public urlUsersUserNew = this.backendApi("/secai/backend/users/user_new")
    public urlUsersUserUpdate = this.backendApi("/secai/backend/users/user_update")
    public urlUsersUserList = this.backendApi("/secai/backend/users/user_list")
    public urlOpPendings = this.backendApi("/secai/backend/op/pendings")
    public urlOpReject = this.backendApi("/secai/backend/op/reject")
    public urlOpAccept = this.backendApi("/secai/backend/op/accept")

    public urlFileChatDocBaseCreate = this.backendApi("/secai/backend/filechat/docbase_create")
    public urlFileChatDocBaseUpdate = this.backendApi("/secai/backend/filechat/docbase_update")
    public urlFileChatDocBaseRemove = this.backendApi("/secai/backend/filechat/docbase_remove")
    public urlFileChatDocBaseList = this.backendApi("/secai/backend/filechat/docbase_list")
    public urlFileChatDocBaseBuild = this.backendApi("/secai/backend/filechat/docbase_build")
    public urlFileChatDocBaseDocAdd = this.backendApi("/secai/backend/filechat/docbase_doc_add")
    public urlFileChatDocBaseDocAddMany = this.backendApi("/secai/backend/filechat/docbase_doc_add_many")
    public urlFileChatDocBaseDocRemove = this.backendApi("/secai/backend/filechat/docbase_doc_remove")
    public urlFileChatDocBaseDocList = this.backendApi("/secai/backend/filechat/docbase_doc_list")

    public urlTransTranslateDirs = this.backendApi("/secai/backend/trans/translate_dirs")
    public urlTransTranslateText = this.backendApi("/secai/backend/trans/translate_text");
    public urlApiLlmServerAdd = this.backendApi("/secai/backend/secai/llmserver_add");
    public urlApiLlmServerRemove = this.backendApi("/secai/backend/secai/llmserver_remove");
    public urlApiLlmServerList = this.backendApi("/secai/backend/secai/llmserver_list");
    public urlApiAsrServerGet = this.backendApi("/secai/backend/secai/asrserver_get");
    public urlSecaiAsrConvert = this.wsApi("/secai/asr/convert")
    public urlStaticAudioTitle = this.backendApi("/secai/backend/static/audio_title_setup.7z");
    public urlSecaiFoSend =  this.wsApi("/secai/backend/fo/send")
    public urlSecaiFoGet =  this.backendApi("/secai/backend/fo/get")

    public urlSecaiHotImport =  this.backendApi("/secai/backend/hot/import")
    public urlSecaiHotList =  this.backendApi("/secai/backend/hot/list")
    public urlSecaiHotClean =  this.backendApi("/secai/backend/hot/clean")
    public urlSecaiHotRemove =  this.backendApi("/secai/backend/hot/remove")


    public urlWebAppConfig = this.backendApi("/secai/backend/webapp_config")

    public urlTextToImage = this.backendApi(`/secai/sd/?__theme=${this.theme()}`)
    public urlVisualGlm = this.backendApi(`/secai/visual/?__theme=${this.theme()}`)


    // image trans
    public urlSecaiImageTransFileSend = this.wsApi("/secai/imagetrans/file_send");
    public urlSecaiImageTransFileTranslate = this.wsApi("/secai/imagetrans/file_translate");

    public urlSecAiOcrBatchTasksAdd = this.backendApi("/secai/backend/ocrbatch/tasks_add")
    public urlSecAiOcrBatchTasksList = this.backendApi("/secai/backend/ocrbatch/tasks_list")
    public urlSecAiOcrBatchTasksClean = this.backendApi("/secai/backend/ocrbatch/tasks_clean")

    public urlSecAiDocBatchTasksAdd = this.backendApi("/secai/backend/docbatch/tasks_add")
    public urlSecAiDocBatchTasksAddOne = this.backendApi("/secai/backend/docbatch/tasks_add_one")
    public urlSecAiDocBatchTasksList = this.backendApi("/secai/backend/docbatch/tasks_list")
    public urlSecAiDocBatchTasksClean = this.backendApi("/secai/backend/docbatch/tasks_clean")
    public urlSecAiDocBatchTaskLookAt = this.backendApi("/secai/backend/docbatch/look_at")

    // ocr
    public urlSecAiOcrFileConvert = this.wsApi("/secai/ocr/file_convert")


    // doconce
    public urlSecaiDocNodeConvert = this.wsApi("/secai/docnode/convert")


    // stats
    public urlStatsDiskUsage = this.backendApi("/secai/backend/stats/disk_usage")
    public urlNetLogs = this.backendApi("/secai/backend/logs/net_logs")
    public urlOpLogs = this.backendApi("/secai/backend/logs/op_logs")
    public urlUserLogs = this.backendApi("/secai/backend/logs/user_logs")
    public urlLogsExport = this.backendApi("/secai/backend/logs/export")
    public urlLogsReport = this.backendApi("/secai/backend/logs/report")

    //prompt demo
    public urlPromptDemoAdd = this.backendApi(" /secai/backend/prompt_demo/add")
    public urlPromptDemoList = this.backendApi(" /secai/backend/prompt_demo/list")
    public urlPromptDemoRemove = this.backendApi(" /secai/backend/prompt_demo/remove")

    //welcome demo
    public urlWelcomeFaqUpdate = this.backendApi("/secai/backend/welcome/faq_update")
    public urlWelcomeFaqNew = this.backendApi("/secai/backend/welcome/faq_new")
    public urlWelcomeFaqReset = this.backendApi("/secai/backend/welcome/faq_reset")
    public urlWelcomeFaqRemove = this.backendApi("/secai/backend/welcome/faq_remove")
    public urlWelcomeFaqList = this.backendApi("/secai/backend/welcome/faq_list")

    //tts
    public urlTtsSpeakers = this.backendApi("/secai/tts/speakers")
    public urlTtsConvert = this.wsApi("/secai/tts/convert");

    //webcustom
    public urlWebCustomSetStr = this.backendApi("/secai/backend/webcustom/setstr")
    public urlWebCustomGetStr = this.backendApi("/secai/backend/webcustom/getstr")
    public urlWebCustomReset = this.backendApi("/secai/backend/webcustom/reset")

    //kb
    public urlKbCreate = this.backendApi("/secai/backend/kbchat/kb_create")
    public urlKbUpdate = this.backendApi("/secai/backend/kbchat/kb_update")
    public urlKbRemove = this.backendApi("/secai/backend/kbchat/kb_remove")
    public urlKbList = this.backendApi("/secai/backend/kbchat/kb_list")
    public urlKbBuild = this.backendApi("/secai/backend/kbchat/kb_build")
    public urlKbDocAdd = this.backendApi("/secai/backend/kbchat/doc_add")
    public urlKbDocAddMany = this.backendApi("/secai/backend/kbchat/doc_add_many")
    public urlKbDocRemove = this.backendApi("/secai/backend/kbchat/doc_remove")
    public urlKbDocList = this.backendApi("/secai/backend/kbchat/doc_list")



}
const conf = new Conf()
export default conf;
