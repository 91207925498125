import {Row, Col, Card, Button, Tab, Nav, Form, ListGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import { withSwal } from "react-sweetalert2";
import {FormProvider, useForm} from "react-hook-form";

import Conf from "../Utils/Conf";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";

interface TabAsrProps {
    swal: any
}
const TabAsr = withSwal((props: TabAsrProps) => {
    const {swal} = props
    const [asrServer, setAsrServer] = useState<string>('')

    const methods = useForm({});

    const onSave = (values: any, events: any) => {
        console.log(values)
        return false
    }
    const handleOnResetClick = () => {

    }
    useEffect(() => {
        auth_fetch_post_json(Conf.urlApiAsrServerGet, {}).then(async (resp: any) => {
            if(resp.status !== 200) {
                console.log("获取服务器列表失败")
                return
            }
            const url = await resp.text()
            setAsrServer(url)
        })

    }, []);

    return (
        <Row>
            <Col>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSave)}>
                        <Card>
                            <Card.Header>
                                <h4>大语言模型</h4>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <h4 className="header-title">语音转写服务器</h4>
                                        <p className="sub-header">
                                            本系统支持自动调度模型服务器. 可以部署多台<code>大语言模型服务器</code>, 系统自动调度.
                                        </p>

                                    </Col>
                                    <Col>
                                        <label htmlFor="field-1" className="form-label">
                                            用户名
                                        </label>
                                        <input
                                            id="field-1"
                                            type={"text"}
                                            required
                                            className="form-control"
                                            {...methods.register("url")}

                                        >

                                        </input>
                                    </Col>


                                </Row>

                            </Card.Body>
                            <Card.Footer>
                                <Button type={"submit"} className="btn btn-primary btn-sm me-2"><i className={"mdi mdi-content-save"}></i>保存</Button>
                                <Button type={"reset"} className="btn btn-danger btn-sm"><i className={"mdi mdi-null "}></i>重设</Button>

                            </Card.Footer>
                        </Card>
                    </Form>
                </FormProvider>
            </Col>
        </Row>
    )
})

export default TabAsr;