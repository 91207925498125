import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import AiTool from "./AiTool";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import avatar1 from "../../../assets/images/users/user-3.jpg";
import {PptOutlineWidget} from "./widgets/pptoutline/PptOutlineWidget";
import {NetSecToolWidget} from "./widgets/netsectool/NetSecToolWidget";
import {PoetryToolWidget} from "./widgets/poetrytool/PoetryToolWidget";
import {MindMapWidget} from "./widgets/mindmap/MindMapWidget";
import {MeetingMinutesWidget} from "./widgets/meetingminutes/MeetingMinutesWidget";
import {ActivityPlanWidget} from "./widgets/activityplan/ActivityPlanWidget";
import {ResearchReportWidget} from "./widgets/researchreport/ResearchReportWidget";
import {SwotWidget} from "./widgets/swot/SwotWidget";
import {CreateHelperWidget} from "./widgets/createhelper/CreateHelperWidget";
import {StudyTestWidget} from "./widgets/studytest/StudyTestWidget";
import {RulesWriteWidget} from "./widgets/ruleswrite/RulesWriteWidget";
import {StudyMindMapWidget} from "./widgets/studymindmap/StudyMindMapWidget";
import {FlowChartWidget} from "./widgets/flowchart/FlowChartWidget";
import {EngChatWidget} from "./widgets/engchat/EngChatWidget";
import {PsychologyWikiWidget} from "./widgets/psychologywiki/PsychologyWikiWidget";

// import AddEditEvent from "./AddEditEvent";

// dummy data
// import { defaultEvents } from "./data";

const AiToolApp = () => {
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/aitool" },
                    { label: "AI工具箱", path: "/apps/aitool", active: true },
                ]}
                title={"AI工具箱"}
            />

            <Row>
                <Col md={6} xl={4}>
                    <PptOutlineWidget
                    />
                </Col>
                <Col md={6} xl={4}>
                    <NetSecToolWidget
                    />
                </Col>
                <Col md={6} xl={4}>
                    <PoetryToolWidget
                    />
                </Col>
                <Col md={6} xl={4}>
                    <MindMapWidget />
                </Col>
                <Col md={6} xl={4}>
                    <MeetingMinutesWidget />
                </Col>
                <Col md={6} xl={4}>
                    <ActivityPlanWidget />
                </Col>
                <Col md={6} xl={4}>
                    <ResearchReportWidget />
                </Col>
                <Col md={6} xl={4}>
                    <SwotWidget />
                </Col>
                <Col md={6} xl={4}>
                    <CreateHelperWidget />
                </Col>
                <Col md={6} xl={4}>
                    <StudyTestWidget />
                </Col>
                <Col md={6} xl={4}>
                    <RulesWriteWidget />
                </Col>
                <Col md={6} xl={4}>
                    <StudyMindMapWidget />
                </Col>
                <Col md={6} xl={4}>
                    <FlowChartWidget />
                </Col>
                <Col md={6} xl={4}>
                    <EngChatWidget />
                </Col>
                <Col md={6} xl={4}>
                    <PsychologyWikiWidget />
                </Col>
            </Row>

        </>
    );
};

export default AiToolApp;
