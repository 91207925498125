
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine} from "../WidgetAppParam";


export class StudyMindMapApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextLine("subject", "教学主题", "输入学习的主题", "教学主题"),
            new WidgetAppParamTextArea("content", "学的大致内容", "输入本次教学的大致内容", "教学的大致内容"),
        ];
    }

    template: string = "StudyMindMap";
    title: string = "教学思维导图";

}

export default StudyMindMapApp;

