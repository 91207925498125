import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Card, Button, Modal, Form} from "react-bootstrap";
import { withSwal } from "react-sweetalert2";

import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Nestable from "react-nestable";
import Table from "../../../components/Table";
import {FormProvider} from "react-hook-form";
import Conf from "../Utils/Conf";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import {users} from "../AiChat/data";


const sizePerPageList = [
    {
        text: "5",
        value: 5,
    },
    {
        text: "10",
        value: 10,
    },
    {
        text: "25",
        value: 25,
    },
    {
        text: "All",
        value: users.length,
    },
];


// import AddEditEvent from "./AddEditEvent";

// dummy data
// import { defaultEvents } from "./data";

const OpApproveApp = withSwal((props: any) => {
    const {swal} = props
    const [op_pendings, setOpPendings] = useState<any>([]);
    const rejectOp = (op_id: string) => {
        auth_fetch_post_json(Conf.urlOpReject, {op_id: op_id})
            .then((json: any) => {
                if(json['status']) {
                    swal.fire(
                        {
                            title: "操作成功",
                            text: "操作已拒绝",
                        }
                    )
                    getOpPendings()
                } else {
                    swal.fire(
                        {
                            title: "操作失败",
                            text: json['reason'],
                        }
                    )
                }
            })
            .catch((error) => {
                swal.fire(
                    {
                        title: "操作失败",
                        text: error,
                    }
                )
                }
            )
    }

    const acceptOp = (op_id: string) => {
        auth_fetch_post_json(Conf.urlOpAccept, {op_id: op_id})
            .then((json: any) => {
                if(json['status']) {
                    swal.fire(
                        {
                            title: "操作成功",
                            text: "审批通过.",
                        }
                    )
                    getOpPendings()
                } else {
                    swal.fire(
                        {
                            title: "操作失败",
                            text: json['reason'],
                        }
                    )
                }
            })
            .catch((error) => {
                    swal.fire(
                        {
                            title: "操作失败",
                            text: error,
                        }
                    )
                }
            )
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "操作人",
                accessor: "op_user_id",
                sort: false,
            },
            {
                Header: "操作时间",
                accessor: "op_time",
                sort: false,
            },
            {
                Header: "操作内容",
                accessor: "op_description",
                sort: false,
            },
            {
                Header: "审批",
                accessor: "op_action",
                Cell: (data: any) => (
                    <div>
                        <button className={'btn btn-info me-3'} onClick={() => {
                            alert(JSON.stringify(data.row.original.op_detail))
                        }}><i className={"ti-eye"}></i></button>
                        <button className={'btn btn-primary me-3'} onClick={()=> {
                            console.log(data.row.original)
                            acceptOp(data.row.original.op_id)

                        }}><i className={"ti-check"}></i></button>
                        <button className={'btn btn-danger'} onClick={() => {
                            console.log(data.row.original)
                            rejectOp(data.row.original.op_id)
                        }}><i className={"ti-close"}></i></button>
                    </div>
                )
            }
        ],
        []
    );


    const getOpPendings = useCallback(() => {
        auth_fetch_post_json(Conf.urlOpPendings, {})
            .then((json: any) => {
                if(json['status']) {
                    console.log("get pendings")
                    console.log(json)
                    setOpPendings(json.data)
                } else {
                    alert(json['reason'])
                }
            })
            .catch((error) => {
                alert(error)
            });
    }, []);


    useEffect(() => {
        getOpPendings();
    }, []);



    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "审批中心", path: "/apps/opapprove", active: true },
                ]}
                title={"审批中心"}
            />

            <Row>
                <Col sm={12} md={6} lg={9}>

                    <Table
                        columns={columns}
                        data={op_pendings}
                        pageSize={10}
                        sizePerPageList={sizePerPageList}
                        isSortable={false}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}
                    />
                </Col>

            </Row>

        </>
    );
})

export default OpApproveApp;
