import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import AsrOffline from "./AsrOffline";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Conf from "../Utils/Conf";
import FileSender from "../../../utils/filesender";
import useUploading from "../../../utils/uploading";
import {Link} from "react-router-dom";
import useAsr from "../../../utils/asr";
import UploadingProgressModal from "../../../utils/UploadingProgressModal";
import ErrorModal from "../../../utils/ErrorModal";
import useFilePoster from "../../../utils/fileposter";
import Draft from "draft-js";
import {formatFileSize} from "../../../utils/Helpers";
import Spinner from "../../../components/Spinner";
import useWebAppConfig from "../../../utils/WebAppConfig";
const AsrOfflineApp = () => {
    const uploading = useUploading(Conf.urlSecaiFoSend);
    const asr = useAsr();
    const [langCode, setLangCode] = useState("zh")
    const [currentSubTitle, setCurrentSubTitle] = useState("")
    const [currentSubTitleIndex, setCurrentSubTitleIndex] = useState(-1)
    const webAppConfig = useWebAppConfig()
    useEffect(() => {
        webAppConfig.loadWebAppConfig()
    }, [])
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/asroffline" },
                    { label: "语音转写", path: "/apps/asroffline", active: true },
                ]}
                title={"语音转写"}
            />
            <Row>
                <Col lg={6}>
                    <Card >
                        <Card.Body>
                            <h4 className="header-title mb-3">音频转写</h4>
                            <h5 className={"text-danger text-mute"} hidden={!webAppConfig.hasSecret}>本产品密级为<strong>内部</strong>， 请不要上传更高安全级别的文件</h5>
                            <Row>
                                <Col>
                                    <audio
                                        controls={true}
                                        style={{width: "100%"}}
                                        src={uploading.fileUrl}
                                        className={"mb-4"}
                                        onTimeUpdate={e => {
                                            console.log(e.currentTarget.currentTime)
                                            const currentTimeMills = e.currentTarget.currentTime * 1000
                                            console.log(asr.asrSentences)
                                            let i = 0
                                            for(let sen of asr.asrSentences) {
                                                if (currentTimeMills >= sen['start'] && currentTimeMills < sen['end']) {
                                                    setCurrentSubTitle(sen['text'])
                                                    setCurrentSubTitleIndex(i)
                                                    break
                                                }
                                                i++
                                            }
                                        }}
                                    ></audio>
                                    <div>{currentSubTitle}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} hidden={!webAppConfig.hasSecret}>
                                    <Select
                                        className="react-select react-select-container"
                                        classNamePrefix="react-select"
                                        options={
                                            [
                                                {"value": "private", "label": "内部"},
                                                {"value": "public", "label": "非涉密"}
                                            ]
                                        }
                                        defaultValue={
                                            {"value": "private", "label": "内部"}
                                        }
                                    ></Select>
                                </Col>
                                <Col xl={6}>
                                    <Button
                                        disabled={uploading.uploading || asr.isAsring}
                                        className={"btn btn-primary me-2 mb-3"} onClick={() => uploading.startUpload('audio/mpeg')}>
                                        <i className="bi bi-cloud-upload"></i>上传文件
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <Select
                                        className="react-select react-select-container"
                                        classNamePrefix="react-select"
                                        options={asr.asrLangs}
                                        defaultValue={
                                            {"value": "zh", "label": "中文"}
                                        }
                                        onChange={x => setLangCode(x? x.value: 'zh')}
                                    ></Select>

                                </Col>
                                <Col xl={6}>
                                    <Button
                                        className={"btn btn-danger"}
                                        onClick={() => asr.startAsrByFile(uploading.fileId, langCode)}
                                        disabled={uploading.uploading || asr.isAsring}
                                    >
                                        <span hidden={!asr.isAsring}>
                                            <i className="bi bi-pen-fill"></i>正在转写
                                        </span>
                                        <span hidden={asr.isAsring}>
                                            <i className="bi bi-pen-fill"></i>开始转写
                                        </span>
                                    </Button>
                                    {uploading.uploading && <div>上传<strong>{uploading.fileName}</strong>... {uploading.progress.toFixed(2)}%</div>}
                                    {uploading.errorMessage && <div style={{ color: 'red' }}>{uploading.errorMessage}</div>}
                                    {true && <div>转换进度 {asr.asrProgress.toFixed(2)}%</div>}
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>



                        </Card.Footer>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <Card.Body>
                            <h4 className="header-title mb-3">转写结果</h4>
                            {
                                asr.asrSentences.length > 0 && (
                                    <div>
                                        {
                                            asr.asrSentences.map((s, index) => {
                                                return (
                                                    <span key={'sen' + index}
                                                          className={currentSubTitleIndex==index?"text-info" : ""}>{s['text']}
                                            </span>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                            {
                                asr.asrSentences.length == 0 && <div>{asr.asrResult}</div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <UploadingProgressModal
                fileName={uploading.fileName}
                fileSize={uploading.fileSize}
                progress={uploading.progress}
                statusText={uploading.statusText}
                show={uploading.uploading} onHide={()=> {}} onStopClicked={()=> {}}></UploadingProgressModal>
            <ErrorModal show={uploading.errorMessage!=''} title={"上传错误"} message={uploading.errorMessage}></ErrorModal>
        </>
    );
};

export default AsrOfflineApp;
