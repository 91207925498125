
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea} from "../WidgetAppParam";


export class PoetryToolApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextArea("outline", "你想写一首什么样的诗?", "请输入诗词内容", "诗词内容"),
        ];
    }

    template: string = "PoetryTool";
    title: string = "诗词创作";
}

export default PoetryToolApp;

