import React, {useCallback, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Card, Row, Col, Collapse, Form} from "react-bootstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

// dummy data
import { users, ChatUser } from "./data";

import profilePic from "../../../assets/images/users/user-1.jpg";
import Select from "react-select";
import Conf from "../Utils/Conf";
import {APICore} from "../../../helpers/api/apiCore";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import classNames from "classnames";
import {KbChatConf} from "../AiChat/data";

export interface KbDocBase {
    uuid: string;
    name: string;
}
interface ChatUsersProps {
    onDocBaseSelect: (value: KbDocBase) => void;
    onKbChatConfChanged: (value: KbChatConf) => void;
}

// KbDocBases
const KbDocBases = ({ onDocBaseSelect, onKbChatConfChanged }: ChatUsersProps) => {
    const [collapse, setCollapse] = useState<boolean>(false);
    const toggleContent = () => {
        setCollapse(!collapse);
    };
    const [docBases, setDocBases] = useState<any>([]);
    const [docs, setDocs] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState(docBases.length > 0 ? {
        value: docBases[0].uuid,
        label: docBases[0].name
    } : null);

    const [history_len, set_history_len] = useState(1);
    const [temperature, set_temperature] = useState(0.35);
    const [top_k, set_top_k] = useState(3 );
    const [score_threshold, set_score_threshold] = useState(1.00 );


    const getDocBases = useCallback(() => {
        const api = new APICore()

        auth_fetch_post_json(Conf.urlKbList, {
            'group': 0
        })
            .then((data: any) => {
                setDocBases(data)
                if (data.length > 0) {
                    onDocBaseSelect({
                        uuid: data[0].uuid,
                        name: data[0].name
                    })
                    setSelectedValue({
                        value: data[0].uuid,
                        label: data[0].name
                    })
                } else {
                    onDocBaseSelect({
                        uuid: "",
                        name: ""
                    })
                    setSelectedValue(null)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                onDocBaseSelect({
                    uuid: "",
                    name: ""
                })
                setSelectedValue(null)
            });
    }, []);

    useEffect(() => {
        getDocBases();
    }, [])

    useEffect(() => {
        if (selectedValue) {
            auth_fetch_post_json(Conf.urlFileChatDocBaseDocList, {docbase_uuid: selectedValue.value}).then((data: any) => {
                setDocs(data)
            })

        }
    }, [selectedValue]);

    return (
        <>
            <Card>
                <Card.Body>
                    <h6 className="font-13 text-uppercase">查询知识库</h6>
                    <Select
                        className="react-select react-select-container"
                        classNamePrefix="react-select"
                        options={docBases.map((item: any) => {
                            return {
                                value: item.uuid,
                                label: item.name
                            }
                        })
                        }
                        value={selectedValue}

                        onChange={(e: any) => {
                            console.log("onChatDocBaseSelect change to " + e.value)
                            console.log(onDocBaseSelect)
                            onDocBaseSelect({
                                uuid: e.value,
                                name: e.label
                            })
                            setSelectedValue(e);
                        }}
                    ></Select>
                    <div className={"font-12 text-muted"}><a href="#" onClick={toggleContent}>
                        <span style={{display: "inline"}}>详细信息</span><i className={classNames("mdi", {
                        "mdi-chevron-up": collapse,
                        "mdi-chevron-down": !collapse,
                    })} style={{display: "inline"}}></i>
                    </a></div>
                    <Collapse in={collapse}>
                        <div>
                            {docs.map((doc: any, index: number) => {
                                return (
                                    <div key={"doc" + index} className={"font-11 text-muted"}>{doc.name}</div>
                                )
                            })}
                        </div>
                    </Collapse>

                    <Form.Group className="mb-0">
                        <Form.Label htmlFor="exampleRange" className="form-label font-13 text-uppercase  mt-4">
                            温度({temperature})
                        </Form.Label>
                        <Form.Range value={temperature} min={0.0} max={1.00} step={0.01}
                                    onChange={e => {
                                        set_temperature(parseFloat(e.target.value))
                                        onKbChatConfChanged({
                                            history_len: history_len,
                                            temperature: parseFloat(e.target.value),
                                            top_k: top_k,
                                            score_threshold: score_threshold
                                        })
                                    }
                                    }/>
                        <Form.Text className={'text-muted font-11'}>大模型参数, 温度越高, 随机性越高, 温度为0将产生相同的输出,
                            建议保持默认值</Form.Text>

                    </Form.Group>


                    <Form.Group className="mb-0">
                        <Form.Label htmlFor="exampleRange" className="form-label font-13 text-uppercase  mt-4">
                            匹配知识条数({top_k})
                        </Form.Label>
                        <Form.Range value={top_k} min={1} max={8} step={1}
                                    onChange={e => {
                                        set_top_k(parseInt(e.target.value))
                                        onKbChatConfChanged({
                                            history_len: history_len,
                                            temperature: temperature,
                                            top_k: parseInt(e.target.value),
                                            score_threshold: score_threshold
                                        })
                                    }
                                    }/>
                        <Form.Text className={'text-muted font-11'}>如果一条知识在多处出现, 建议放大此数值</Form.Text>

                    </Form.Group>


                    <Form.Group className="mb-0">
                        <Form.Label htmlFor="exampleRange" className="form-label font-13 text-uppercase  mt-4">
                            知识库分数({score_threshold})
                        </Form.Label>
                        <Form.Range value={score_threshold} min={0} max={2} step={0.01}
                                    onChange={e => {
                                        set_score_threshold(parseFloat(e.target.value))
                                        onKbChatConfChanged({
                                            history_len: history_len,
                                            temperature: temperature,
                                            top_k: top_k,
                                            score_threshold: parseFloat(e.target.value)
                                        })
                                    }
                                    }/>
                        <Form.Text className={'text-muted font-11'}>此参数控制模型自身知识与知识库知识的比重,
                            分数越高,越偏向使用知识库知识检索; 分数越低, 越偏向使用模型自身知识.</Form.Text>
                    </Form.Group>


                </Card.Body>
            </Card>
        </>
    );
};

export default KbDocBases;
