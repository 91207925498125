import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Card, Button, Modal, Form} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import Select from "react-select";
import {users} from "../AiChat/data";
import Table from "../../../components/Table";
import {FormProvider, useForm} from "react-hook-form";
import Conf from "../Utils/Conf";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";



const PromptDemoApp = () => {
    const methodsNewPrompt= useForm({});
    const [isEditing, setIsEditing] = useState(false)
    const [currentRobotId, setCurrentRobotId] = useState(users[0].robotId)



    const [prompts, setPrompts] = useState([])
    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "500",
            value: 500,
        },
        {
            text: "1000",
            value: 1000,
        },
        {
            text: "All",
            value: prompts.length,
        },
    ];

    const columns = React.useMemo(
        () => [
            {
                Header: "范例编号",
                accessor: "prompt_demo_id",
                sort: false,
            },
            {
                Header: "机器人编号",
                accessor: "robot_id",
                sort: false,
            },
            {
                Header: "机器人名",
                accessor: "robotName",
                sort: false,
            },
            {
                Header: "提示语",
                accessor: "prompt",
                sort: false,
            },
            {
                Header: "操作",
                accessor: "action",
                sort: false,
                Cell: (data: any) => (
                    <div>
                        <Button className={"btn btn-danger"} size={"sm"}><i className={"mdi mdi-trash-can"}
                            onClick={()=>{
                                removePrompt(data.row.original['prompt_demo_id'])
                            }}
                        ></i></Button>
                    </div>
                )
            }
        ],
        []
    );

    const toggleIsEditing = () => {
        setIsEditing(!isEditing)
    };

    const handleResetNewPrompt = () => {
        methodsNewPrompt.reset();
        setIsEditing(false)
    }
    const newPrompt = async (values: any, event: any) => {
        console.log("newPrompt: ")
        console.log(values)
        console.log(event)
        const json: any = {}
        json["prompt"] = values.prompt
        json["robot_id"] = currentRobotId
        auth_fetch_post_json(Conf.urlPromptDemoAdd, json)
            .then((data: any) => {
                console.log(data)
                setPrompts(data)
                setIsEditing(false)
                methodsNewPrompt.reset();
            })
            .catch((error) => {
                alert(error)
                methodsNewPrompt.reset();
            })
        return false
    }

    const getPrompts = useCallback(() => {
        auth_fetch_post_json(Conf.urlPromptDemoList, {
            "robot_id": currentRobotId,
        })
            .then((data: any) => {
                setPrompts(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [currentRobotId])

    useEffect(() => {
        getPrompts()
    }, [currentRobotId]);




    const removePrompt = useCallback((prompt_demo_id: number) => {
        console.log("removePrompt: ")
        auth_fetch_post_json(Conf.urlPromptDemoRemove, {
            "robot_id": currentRobotId,
            "prompt_demo_id": prompt_demo_id,
        })
            .then((data: any) => {
                setPrompts(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [currentRobotId])



    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "提示语范例", path: "/apps/promptdemo", active: true },
                ]}
                title={"提示语范例"}
            />

            <Row>
                <Col md={12} xl={6} className={"mb-3"}>
                    <label className="form-label">机器人</label> <br />
                    <Select
                        className="react-select react-select-container"
                        classNamePrefix="react-select"
                        options={
                            users.map(user => {
                                return {
                                    'value': user.robotId,
                                    'label': user.name
                                }
                            })
                        }
                        defaultValue={
                            {"value": users[0].robotId, "label":  users[0].name}
                        }
                        onChange={(e) => e && setCurrentRobotId(e.value)}
                    ></Select>
                </Col>
            </Row>

            <Row>
                <Col>
                    <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={()=>setIsEditing(true)}>
                        <i className={"mdi mdi-plus"}></i>添加提示语
                    </button>
                </Col>
            </Row>

            <Row>
                <Col md={12} xl={12}>
                    <Table
                        columns={columns}
                        data={prompts? prompts: []}
                        pageSize={50}
                        sizePerPageList={sizePerPageList}
                        isSortable={false}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}

                    />
                </Col>
            </Row>


            <Modal show={isEditing} onHide={toggleIsEditing}>
                <FormProvider {...methodsNewPrompt}>
                    <Form onSubmit={methodsNewPrompt.handleSubmit(newPrompt)}>
                        <Modal.Header closeButton>
                            <h4 className="modal-title">{"添加提示语"}</h4>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="field-docbase-name" className="form-label">
                                            提示语
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="field-docbase-name"
                                            placeholder="输入提示语内容"
                                            style={{height: 320}}
                                            required
                                            {...methodsNewPrompt.register("prompt", { required: true })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-secondary waves-effect"
                                onClick={ handleResetNewPrompt}
                            >
                                关闭
                            </button>
                            <button
                                type="submit"
                                className="btn btn-info waves-effect waves-light"
                            >
                                保存
                            </button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>

        </>
    );
};

export default PromptDemoApp;
