import {FormProvider} from "react-hook-form";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import Select from "react-select";
import {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import Spinner from "../../../components/Spinner";
import React, {useEffect, useState} from "react";
import {RobotSelector} from "./RobotSelector";
import {FormInput} from "../../../components";
import Table from "../../../components/Table";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import {users} from "./data";
import {ChatDialog} from "./ChatArea";

import './ChatHistoryDialog.css'
export interface ChatHistoryDialogProps {
    visible: boolean
    onHide: () => void
    onDialogSwitch: (dialogId: number, robotId: string) => void
}
export const ChatHistoryDialog = (props: ChatHistoryDialogProps) => {
    const [chatDialogs, setChatDialogs] = useState<ChatDialog[]>([])
    useEffect(() => {
        if(props.visible) {
            auth_fetch_post_json(Conf.urlApiChat2DialogList, {})
                .then((data: any) => {
                    setChatDialogs(data)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

    }, [props.visible]);

    const handleClean = async () => {
        const data = await auth_fetch_post_json(Conf.urlApiChat2DialogClean, {}) as any
        if(data.status) {
            setChatDialogs([])
        }
    }
    return (
        <Modal show={props.visible} onHide={props.onHide} backdrop="static"
               keyboard={false}>
            <Modal.Header closeButton>
                <h4 className="modal-title">历史会话</h4>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className={'chat-dlgs-container'}>
                    {
                        chatDialogs.map(dlg =>
                            <div key={'dlg-' + dlg.dialogId} style={{
                                height: '48px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}>
                                <div style={{flex: 1}}>{dlg.dialogName}</div>
                                <Button variant={'success'} className={'btn-sm'} onClick={()=> props.onDialogSwitch(dlg.dialogId, dlg.robotId)}>切换</Button>
                                <Button variant={'danger-outline'} className={'btn-sm'}>删除</Button>
                            </div>
                        )
                    }
                </div>
                <div className={'d-grid'}>
                    <Button variant={'danger'} className={'btn-sm'} onClick={handleClean}>清空</Button>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button
                    onClick={() => {
                        props.onHide()
                    }}
                >
                    确定
                </Button>
            </Modal.Footer>

        </Modal>
    )
}