import './VideoTransResultBox.css'
import React, {useEffect, useRef} from "react";
import {Sentence} from "../../../utils/docbatch_client";

interface VideoTransResultBoxProps {
    sentences: Sentence[]
    highLightIndex: number
}

const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

const VideoTransResultBox = (props: VideoTransResultBoxProps) => {
    const sentencesRef = useRef<HTMLDivElement>(null);

    /*
    useEffect(() => {
        if (sentencesRef.current) {
            const activeElement = sentencesRef.current.querySelector('.sentence-item-active');
            if (activeElement) {
                activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [props.highLightIndex]);
     */

    const SentencesPane = () => {
        return (
            <div className={'sentences-pane'} ref={sentencesRef}>
                {props.sentences.map((sentence, index) => (
                    <div key={index}
                         className={(props.highLightIndex === index) ? 'sentence-item-active' : 'sentence-item'}>
                        <div className={'start-time'}>{formatTime(sentence.start)}</div>
                        <div className="text-muted">
                            {sentence.text}
                        </div>
                        <div className="text-primary">
                            {sentence.to_text}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
    return (
        <div className={'video-trans-result-box-container'}>
        <SentencesPane />
        </div>
    )
}

export default VideoTransResultBox;