import {Dropdown} from "react-bootstrap";
import React from "react";

export interface MoreMenuItem {
    text: string;
    onClicked: ()=> void;
}

export interface MoreMenuProps extends React.HTMLAttributes<HTMLDivElement> {
    menuItems: MoreMenuItem[];
}
const MoreMenu = (props: MoreMenuProps) => {
    return (
        <Dropdown
            className="conversation-actions"
            align={"end"}
        >

            <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                <i className="mdi mdi-dots-vertical font-12"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    props.menuItems.map((item, index) => {
                        return (<Dropdown.Item key={index} onClick={item.onClicked}>
                            {item.text}
                        </Dropdown.Item>)
                    })

                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default MoreMenu;