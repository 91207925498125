import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine, WidgetAppParamType} from "../WidgetAppParam";


export class PptOutlineApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextLine("title", "PPT标题", "请输入PPT标题,例如: 新能源汽车发展趋势", "PPT标题"),
            new WidgetAppParamTextArea("outline", "PPT大纲", "请输入PPT大纲,例如:\n1.背景介绍2.新能源汽车现状\n3.动力电池技术", "PPT大纲"),

        ];
    }

    template: string = "PptOutline";
    title: string = "PPT大纲生成";
}

export default PptOutlineApp;
