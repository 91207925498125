import React, { useEffect, useState } from "react";
import {Row, Col, Card, Button, Dropdown, ButtonGroup, Form} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import Select from "react-select";
import {FormInput} from "../../../components";
import Conf from "../Utils/Conf";
import Spinner from "../../../components/Spinner";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import useT2tt from "../../../utils/t2tt";
import "./super_trans.css"
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";
import {ModelSelector} from "../AiChat/ModelSelector";
import {ModelConfig} from "../../../utils/WebAppConfig";


interface DiffControlProps {
    line: number
    fromLine: string
    toLine: string
}

interface LangInfo {
    value: string
    label: string
}

interface Engine {
    value: string
    label: string
}



const DiffControl = (props: DiffControlProps) => {
    return (
        <div>
            <div>
                {props.fromLine}
            </div>
            <div className={"text-success"}>
                {props.toLine}
            </div>
            <p></p>
        </div>
    )
}

const defaultEngine = {
    value: 'llm',
    label: 'LLM大模型'
}
const SuperTransApp = () => {
    const [currentEngine, setCurrentEngine] = useState<Engine>(defaultEngine);
    const [enableHot, setEnableHot] = useState<boolean>(false);
    const [realtimeMode, setRealtimeMode] = useState<boolean>(false);
    const [dictMode, setDictMode] = useState<boolean>(false);
    const [transDiff, setTransDiff] = useState<boolean>(false);
    const [summaryMode, setSummaryMode] = useState<boolean>(false);
    const [fromLength, setFromLength] = useState<number>(0);
    const [modelConfig, setModelConfig] = useState<ModelConfig | null>(null);
    let trans_timestamp = new Date().getTime();
    let trans_id: any
    const t2tt = useT2tt()

    const [fromText, setFromText] = useState("");
    const [toText, setToText] = useState<string>("");

    const [fromLang, setFromLang] = useState<LangInfo | null>(null);
    const [toLang, setToLang] = useState<LangInfo | null>(null);


    const [diff, setDiff] = useState<string[]>([]);

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleSwitchLang = () => {
        const tmpLang = fromLang
        setFromLang(toLang)
        setToLang(tmpLang)

    }

    const handleImportClick = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "text/plain,text/markdown,text/csv,text/html,text/xml,text/css,text/javascript";
        input.style.display = "none";
        input.addEventListener("change", (event: any) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if(e && e.target &&  e.target.result) {
                        const text = e.target.result;
                        setFromText(''+text)
                        setFromLength((''+text).length)
                        if(realtimeMode) {
                            tryTranslate(''+text)
                        }
                    }

                };
                reader.readAsText(file);
            }
        });
        input.click();
    };

    const handleStartTransClick = () => {
        tryTranslate(fromText)
    }
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(toText)
            .then(() => {
                alert('内容已复制到剪贴板!');
            })
            .catch((err) => {
                console.error('无法复制到剪贴板:', err);
            });
    };
    const handleDownloadFile = () => {
        const blob = new Blob([toText], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'content.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const tryTranslate = (text: string) => {
        if(trans_id) {
            clearTimeout(trans_id)
        }
        trans_id = setTimeout(()=> {
            trans_timestamp = new Date().getTime();
            const dataToPost = {
                'enable_hot': enableHot,
                'model_name': modelConfig?.modelName,
                'from_lang': fromLang!.value,
                'to_lang': toLang!.value,
                'from_text': text,
                'engine': currentEngine.value,
                'summary': summaryMode,
                'trans_timestamp': trans_timestamp
            }

            setIsBusy(true)
            if(text == '') {
                setToText('');
                setDiff([])
                setIsBusy(false)
            } else {
                auth_fetch_post_json(Conf.urlTransTranslateText, dataToPost)
                    .then((data: any) => {
                        console.log("Success:", data);
                        if(data.trans_timestamp == trans_timestamp) {
                            setToText(data.to_text);
                            setDiff(data.diff);
                            setIsBusy(false)
                        }
                    })
            }

        }, 500)
    }

    const onSourceTextChanged = (e: any) => {
        console.log("onSourceTextChanged", e.target.value);
        setFromText(e.target.value)
        setFromLength(e.target.value.length)
        if(realtimeMode) {
            tryTranslate(e.target.value)
        }
    }


    useEffect(() => {
                if(t2tt.t2ttInfo) {
                    setTimeout(() => {
                        setFromLang(t2tt.t2ttInfo['engines_info']['llm']['default_src'])
                        setToLang(t2tt.t2ttInfo['engines_info']['llm']['default_tgt'])
                    }, 1000);

                }
    }, [t2tt.t2ttInfo]);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('文本翻译'))
    }, []);
    // @ts-ignore
    return (
        <>
            <Row>
                <Col md={12}  xl={12}>
                    <div style={{
                        borderRadius: '0.5rem',
                        backgroundColor: '#eeeeee',
                        padding: '1rem'
                    }}>
                        <div className="col d-flex mb-2" style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }
                        }>
                            <div style={{marginRight: '8px'}}>
                                <ModelSelector
                                    usingInTrans={true}
                                    onModelChanged={model=> setModelConfig(model)}
                                />

                            </div>
                            {/*
                                                        <Select
                                className="react-select react-select-container me-2"
                                classNamePrefix="react-select"
                                isClearable={false}
                                isSearchable={false}
                                value={currentEngine}

                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '200px',
                                    }),
                                }}
                                options={
                                    t2tt.t2ttInfo? t2tt.t2ttInfo['engines'] : []
                                }
                                defaultValue={defaultEngine}
                                onChange={(e) => {
                                    const engine = e ? e  : defaultEngine
                                    setCurrentEngine(engine)
                                    setFromLang(t2tt.t2ttInfo['engines_info'][engine.value]['default_src'])
                                    setToLang(t2tt.t2ttInfo['engines_info'][engine.value]['default_tgt'])

                                }}
                            ></Select>
                            */}


                            <Select
                                className="react-select react-select-container me-2"
                                classNamePrefix="react-select"
                                isClearable={false}
                                isSearchable={true}
                                value={fromLang}

                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '200px',
                                    }),
                                }}
                                options={
                                    t2tt.t2ttInfo?
                                        t2tt.t2ttInfo[currentEngine.value]['src_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['src_langs'][code]['language']}))
                                        :[]
                                }
                                onChange={(e) => {
                                    setFromLang(e)
                                }}
                            ></Select>

                            <Button onClick={() => handleSwitchLang()} variant="default" className="btn-info waves-effect waves-light  me-2">
                                <i className="fas fa-exchange-alt"></i>
                            </Button>
                            <Select
                                className="react-select react-select-container me-4"
                                classNamePrefix="react-select"
                                isClearable={false}
                                isSearchable={true}
                                value={toLang}


                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '200px',
                                    }),
                                }}
                                options={
                                    t2tt.t2ttInfo?
                                        t2tt.t2ttInfo[currentEngine.value]['tgt_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['tgt_langs'][code]['language']}))
                                        :[]
                                }
                                onChange={(e) => {
                                    setToLang(e)
                                }}
                            ></Select>


                            <span hidden={!isBusy} className={"text-warning"}><Spinner
                                className="spinner-border-sm text-warning"/>正在翻译</span>

                        </div>


                        <div className="col d-flex" style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }
                        }>

                            <Form.Check
                                className={"me-2"}
                                type="checkbox"
                                label={"热词校准"}
                                checked={enableHot}
                                onChange={e => setEnableHot(e.target.checked)}
                            />

                            <Form.Check
                                className={"me-2"}
                                type="checkbox"
                                label={"实时翻译"}
                                checked={realtimeMode}
                                onChange={e => setRealtimeMode(e.target.checked)}
                            />


                            <Form.Check
                                className={"me-2"}
                                type="checkbox"
                                label={"双语对照"}
                                checked={transDiff}
                                onChange={e => setTransDiff(e.target.checked)}
                            />


                            <Form.Check
                                className={"me-2"}
                                type="checkbox"
                                label={"总结模式"}
                                checked={summaryMode}
                                onChange={e => setSummaryMode(e.target.checked)}
                            />


                        </div>


                    </div>
                </Col>
            </Row>

            <Row>

                <Col md={12} xl={6}>
                    <div style={{
                        borderRadius: '0.5rem',
                        backgroundColor: '#ffffff',
                        padding: '1rem'
                    }}>
                        <FormInput
                            label={""}
                            type={"textarea"}
                            name={"fi_from_text"}
                            placeholder={"输入翻译内容"}
                            key={"fi_from_text"}
                            value={fromText}
                            onChange={onSourceTextChanged}
                            style={{
                                width: '100%',
                                height: '60vh',
                                border: 'none',
                                outline: 'none',
                                resize: 'none',
                            }}


                        />
                        <div style={{
                            position: 'relative',
                            bottom: '0px',
                            left: '0px',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingRight: '2rem'

                        }}>
                            <button onClick={() => handleImportClick()} className="btn btn-outline-primary"><i
                                className={"far fa-folder-open me-1"}></i>导入文本文件
                            </button>
                            <span className={"text-info text-muted"}>字符数 {fromLength}</span>
                            {
                                !realtimeMode && (
                                    <button onClick={() => handleStartTransClick()} className="btn btn-outline-primary" disabled={isBusy}>{isBusy?'正在翻译':'开始翻译'}
                                    </button>
                                )
                            }

                        </div>
                    </div>

                </Col>
                <Col md={12} xl={6}>
                    <div style={{
                        borderRadius: '0.5rem',
                            backgroundColor: '#ffffff',
                            padding: '1rem'
                        }}>
                            <div
                                key={'to_text_div'}
                                style={{
                                    width: '100%',
                                    height: '60vh',
                                    border: 'none',
                                    outline: 'none',
                                    resize: 'none',
                                    whiteSpace: 'pre-wrap',
                                    overflowY: 'auto'
                                }}
                            >
                                {transDiff?  diff.map((v, k) => (<DiffControl key={'diff_control_' + k} line={k} fromLine={v[0]} toLine={v[1]} />)) : toText }

                            </div>
                            <div style={{position: 'relative', bottom: '0px', left: '0px', zIndex: 1}}>
                                <button className="btn btn-outline-primary me-2" onClick={handleDownloadFile}>
                                    <i className={'far fa-save me-1'}></i>保存结果
                                </button>
                                <button className="btn btn-outline-primary" onClick={handleCopyToClipboard}>
                                <i className={'far fa-copy me-1'}></i>复制内容
                                </button>
                            </div>
                    </div>

                </Col>
            </Row>

        </>
);
};

export default SuperTransApp;
