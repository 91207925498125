import React from "react";
import {Modal, Button, ProgressBar} from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {formatFileSize} from "./Helpers";


interface UploadingProgressModalProps {
    fileName: string
    fileSize: number
    statusText: string
    progress: number;
    show: boolean;
    onHide: () => void;
    onStopClicked: () => void;
}

const UploadingProgressModal = (props: UploadingProgressModalProps) => {
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="bg-light" onHide={props.onHide} closeButton>
                    <Modal.Title className="m-0">正在上传</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <p>文件: <strong>{props.fileName}</strong></p>
                    <p>大小: <strong>{formatFileSize(props.fileSize)}</strong></p>
                    <p>状态: <strong>{props.statusText}</strong></p>
                    <ProgressBar now={props.progress} label={props.progress.toFixed(2) + "%"} className="mb-0" />
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"btn btn-danger"} onClick={props.onStopClicked}>停止</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};


export default UploadingProgressModal;
