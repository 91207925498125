import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import './UploadableVideoBox.css'
import useUploading from "../../../utils/uploading";
import Conf from "../Utils/Conf";
import UploadingProgressModal from "../../../utils/UploadingProgressModal";


interface UploadableVideoBoxProps  {
    onVideoFileChanged: (fileId: string, fileName: string,  fileUrl: string) => void;
    onTimeUpdate: (currentTime: number) => void;
}


export interface UploadableVideoBoxRef {
    // 暴露给外部程序的方法或属性...
    someMethod: () => void;
}

const UploadableVideoBox = forwardRef<UploadableVideoBoxRef, UploadableVideoBoxProps> ((props, ref) => {
    const uploading = useUploading(Conf.urlSecaiFoSend);
    const [showCloseButton, setShowCloseButton] = useState(false);
    useImperativeHandle(ref, () => ({
        someMethod() {
            console.log("someMethod called");
        }
    }))

    const handleUploadClick = () => {
        console.log("upload clicked");
        uploading.startUpload("video/*");
    }
    const handleCloseClick = () => {
        uploading.clear()
    };

    useEffect(() => {
        props.onVideoFileChanged(uploading.fileId, uploading.fileName, uploading.fileUrl)
    }, [uploading.fileId]);
    return (
        <div className={"uploadable-video-box-container"}>
            {
                (uploading.fileUrl !== '') ? (
                    <div style={{position: 'relative', width: '100%', height: '100%'}}>
                        <video controls
                               style={{
                                   width: "100%",
                                   height: "100%",
                                   objectFit: "contain"}}
                               onError={e => console.log("video error", e)}
                               onMouseEnter={() => setShowCloseButton(true)}
                               onMouseLeave={() => setShowCloseButton(false)}
                               onTimeUpdate={e => props.onTimeUpdate(e.currentTarget.currentTime)}
                        >
                            <source src={uploading.fileUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {showCloseButton && (
                            <div style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                color: '#fff',
                                cursor: 'pointer'
                            }}
                                 onMouseEnter={() => setShowCloseButton(true)}
                                 onMouseLeave={() => setShowCloseButton(false)}
                                 onClick={handleCloseClick}>
                                <i className={"fas fa-times"}></i>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={"uploadable-video-box-placeholder"}>
                        <Button variant={"primary"} onClick={() => handleUploadClick()}><i className={"fas fa-upload"}></i> 上传视频</Button>
                    </div>
                )
            }
            <UploadingProgressModal
                fileName={uploading.fileName}
                fileSize={uploading.fileSize}
                progress={uploading.progress}
                statusText={uploading.statusText}
                show={uploading.uploading}
                onHide={()=> {}}
                onStopClicked={()=> {}}></UploadingProgressModal>
        </div>
    )
})

export default UploadableVideoBox;