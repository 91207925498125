import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Card, Button, Form} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";


import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Table from "../../../components/Table";
import {users} from "../AiChat/data";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import useLogDownloader from "../../../utils/loaddownloader";
import HyperDatepicker from "../../../components/Datepicker";


const sizePerPageList = [
    {
        text: "5",
        value: 5,
    },
    {
        text: "10",
        value: 10,
    },
    {
        text: "25",
        value: 25,
    },
    {
        text: "All",
        value: users.length,
    },
];



const OpLogsApp = () => {
    const [searchIdEnabled, setSearchIdEnabled] = useState(false)
    const [searchId, setSearchId] = useState<string>("")

    const [searchOpUserIpEnabled, setSearchOpUserIpEnabled] = useState(false)
    const [searchOpUserIp, setSearchOpUserIp] = useState<string>("")

    const [searchOpUserIdEnabled, setSearchOpUserIdEnabled] = useState(false)
    const [searchOpUserId, setSearchOpUserId] = useState<string>("")

    const [searchOpDescriptionEnabled, setSearchOpDescriptionEnabled] = useState(false)
    const [searchOpDescription, setSearchOpDescription] = useState<string>("")

    const [searchOpTimeStampStartEnabled, setSearchOpTimeStampStartEnabled] = useState(false)
    const [searchOpTimeStampStart, setSearchOpTimeStampStart] = useState<Date>(new Date());

    const [searchOpTimeStampStopEnabled, setSearchOpTimeStampStopEnabled] = useState(false)
    const [searchOpTimeStampStop, setSearchOpTimeStampStop] = useState<Date>(new Date());


    const onSearchOpTimeStartChanged = (date: Date) => {
        if (date) {
            setSearchOpTimeStampStart(date);
        }
    };
    const onSearchOpTimeStopChanged = (date: Date) => {
        if (date) {
            setSearchOpTimeStampStop(date);
        }
    };
    const exporter = useLogDownloader()

    const [opLogs, setOpLogs] = useState<any>([])
    const columns = React.useMemo(
        () => [
            {
                Header: "日志编号",
                accessor: "id",
                sort: false,
            },
            {
                Header: "操作员客户端IP",
                accessor: "op_user_ip",
                sort: false,
            },
            {
                Header: "操作员用户编号",
                accessor: "op_user_id",
                sort: false,
            },
            {
                Header: "描述 ",
                accessor: "op_description",
                sort: false,
            },
            {
                Header: "发生时间",
                accessor: "op_timestr",
                sort: false,
            },
            {
                Header: "详细数据",
                accessor: 'op_detail_info',
                Cell: (data: any) => (
                    <button className={"btn-primary"} onClick={() => {
                        alert(data.row.original.op_detail)
                    }}><i className={"mdi mdi-eye"}></i></button>
                )
            },
        ],
        []
    );


    const getOpLogs = useCallback(() => {


        const conditions = {
            "searchIdEnabled": searchIdEnabled,
            "searchOpUserIpEnabled": searchOpUserIpEnabled,
            "searchOpUserIdEnabled": searchOpUserIdEnabled,
            "searchOpDescriptionEnabled": searchOpDescriptionEnabled,
            "searchOpTimeStampStartEnabled": searchOpTimeStampStartEnabled,
            "searchOpTimeStampStopEnabled": searchOpTimeStampStopEnabled,
            "searchId": searchId,
            "searchOpUserIp": searchOpUserIp,
            "searchOpUserId": searchOpUserId,
            "searchOpDescription": searchOpDescription,
            "searchOpTimeStampStart": searchOpTimeStampStart.getTime(),
            "searchOpTimeStampStop": searchOpTimeStampStop.getTime()
        }

        auth_fetch_post_json(Conf.urlOpLogs, conditions)
            .then((json: any) => {
                setOpLogs(json)

            })
            .catch((error) => {
                console.log(error)
            });
    }, [
        searchIdEnabled,
        searchOpUserIpEnabled,
        searchOpUserIdEnabled,
        searchOpDescriptionEnabled,
        searchOpTimeStampStartEnabled,
        searchOpTimeStampStopEnabled,
        searchId,
        searchOpUserIp,
        searchOpUserId,
        searchOpDescription,
        searchOpTimeStampStart,
        searchOpTimeStampStop,
    ]);


    useEffect(() => {
        getOpLogs();
    }, []);




    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "审批日志", path: "/apps/oplogs", active: true },
                ]}
                title={"审批日志"}
            />
            <Row>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"日志编号"}
                        checked={searchIdEnabled}
                        onChange={e => setSearchIdEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchId} onChange={e => setSearchId(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"操作员客户端IP"}
                        checked={searchOpUserIpEnabled}
                        onChange={e => setSearchOpUserIpEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchOpUserIp} onChange={e => setSearchOpUserIp(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"操作员用户编号"}
                        checked={searchOpUserIdEnabled}
                        onChange={e => setSearchOpUserIdEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchOpUserId} onChange={e => setSearchOpUserId(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <Form.Check
                        type="checkbox"
                        label={"描述"}
                        checked={searchOpDescriptionEnabled}
                        onChange={e => setSearchOpDescriptionEnabled(e.target.checked)}

                    />
                    <FormInput name={"keyword"} type={"text"} value={searchOpDescription} onChange={e => setSearchOpDescription(e.target.value)}></FormInput>
                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label={"发生时间开始"}
                            checked={searchOpTimeStampStartEnabled}
                            onChange={e => setSearchOpTimeStampStartEnabled(e.target.checked)}

                        />
                        <HyperDatepicker
                            hideAddon={true}
                            showTimeSelect
                            timeFormat="HH:mm"
                            tI={60}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            value={searchOpTimeStampStart}
                            onChange={(date) => {
                                onSearchOpTimeStartChanged(date);
                            }}
                        />
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label={"发生时间结束"}
                            checked={searchOpTimeStampStopEnabled}
                            onChange={e => setSearchOpTimeStampStopEnabled(e.target.checked)}
                        />
                        <HyperDatepicker
                            hideAddon={true}
                            showTimeSelect
                            timeFormat="HH:mm"
                            tI={60}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            value={searchOpTimeStampStop}
                            onChange={(date) => {
                                onSearchOpTimeStopChanged(date);
                            }}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button className={"btn btn-primary"} onClick={() => getOpLogs()}><i className={"mdi mdi-search me-1"}></i>查询</Button>
                </Col>
            </Row>



            <Row>
                <Col sm={12} md={6} lg={9}>

                    <Table
                        columns={columns}
                        data={opLogs? opLogs: []}
                        pageSize={10}
                        sizePerPageList={sizePerPageList}
                        isSortable={false}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}

                    />
                </Col>

            </Row>


        </>
    );
};

export default OpLogsApp;
