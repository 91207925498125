import React, { useEffect, useState } from "react";
import {Row, Col, Card, Button, Tab, Nav} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import Deploy from "./Deploy";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import TabLlm from "./TabLlm";
import TabAsr from "./TabAsr";

interface TabContentType {
    id: number;
    title: string;
    icon?: string;
    content: JSX.Element
}


// import AddEditEvent from "./AddEditEvent";

// dummy data
// import { defaultEvents } from "./data";

const DeployApp = () => {
    /*
     * modal handeling
     */
    const [show, setShow] = useState<boolean>(false);
    const tabContents: TabContentType[] = [
        {
            id: 1,
            title: "大语言模型",
            icon: "mdi mdi-chat",
            content: <TabLlm></TabLlm>
        },
        {
            id: 1,
            title: "语音转写",
            icon: "mdi mdi-home-variant",
            content: <TabAsr></TabAsr>
        },
        {
            id: 2,
            title: "AI绘图",
            icon: "mdi mdi-draw",
            content: <TabAsr></TabAsr>
        },
        {
            id: 3,
            title: "图片翻译",
            icon: "mdi mdi-folder-image",
            content: <TabAsr></TabAsr>
        },
        {
            id: 4,
            title: "OCR服务",
            icon: "mdi mdi-ocr",
            content: <TabAsr></TabAsr>
        },
    ];

    const onCloseModal = () => {
        setShow(false);
        setEventData({});
        setDateInfo({});
    };
    const onOpenModal = () => setShow(true);
    const [isEditable, setIsEditable] = useState<boolean>(false);

    /*
     * event data
     */
    // const [events, setEvents] = useState<EventInput[]>([...defaultEvents]);
    const [eventData, setEventData] = useState<EventInput>({});
    const [dateInfo, setDateInfo] = useState<any>({});


    useEffect(() => {
        // create dragable events
    }, []);

    /*
      calendar events
      */
    // on date click
    const onDateClick = (arg: DateClickArg) => {
        setDateInfo(arg);
        onOpenModal();
        setIsEditable(false);
    };

    // on event click
    const onEventClick = (arg: EventClickArg) => {
        const event = {
            id: String(arg.event.id),
            title: arg.event.title,
            className: arg.event.classNames[0],
        };
        setEventData(event);
        setIsEditable(true);
        onOpenModal();
    };




    // create new event
    const createNewEvent = () => {
        setIsEditable(false);
        onOpenModal();
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/deploy" },
                    { label: "部署管理", path: "/apps/deploy", active: true },
                ]}
                title={"部署管理"}
            />

            <Row>
                <Col xl={12}>

                    <Row>

                        <Tab.Container defaultActiveKey="大语言模型">
                            <Col sm={3}>
                                <Nav as="ul" variant="pills" className="flex-column">
                                    {(tabContents || []).map((tab, index) => {
                                        return (
                                            <Nav.Item as="li" key={index}>
                                                <Nav.Link
                                                    className="cursor-pointer"
                                                    href="#"
                                                    eventKey={tab.title}
                                                >
                                                    <i className={tab.icon + " me-1"}></i>
                                                    {tab.title}
                                                </Nav.Link>
                                            </Nav.Item>
                                        );
                                    })}
                                </Nav>
                            </Col>

                            <Col sm={9}>
                                <Tab.Content className="pt-0">
                                    {(tabContents || []).map((tab, index) => {
                                        return (
                                            <Tab.Pane
                                                eventKey={tab.title}
                                                id={String(tab.id)}
                                                key={index}
                                            >
                                                {tab.content}
                                            </Tab.Pane>
                                        );
                                    })}
                                </Tab.Content>
                            </Col>
                        </Tab.Container>

                    </Row>
                </Col>

            </Row>

        </>
    );
};

export default DeployApp;
