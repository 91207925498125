import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

// components
import PageTitle from "../../../components/PageTitle";



// dummy data
import { users, ChatUser } from "./data";
import avatar_chathelper_b from "../../../assets/images/aichat/bots/chathelper_b.png";
import ChatArea from "../AiChat/ChatArea";
import KbDocBases, {KbDocBase} from "./KbDocBases";
import {KbChatConf} from "../AiChat/data";


// AiChatApp
const KbChatApp = () => {
    const [selectedUser, setSelectedUser] = useState<ChatUser>({
            id: 2,
            robotId: "chatFile",
            name: "内部知识库",
            avatar: avatar_chathelper_b,
            lastMessage: "",
            totalUnread: 3,
            lastMessageOn: "4:30am",
            email: "support@coderthemes.com",
            phone: "+1 456 9595 9594",
            location: "California, USA",
            languages: "English, German, Spanish",
            groups: "Work, Favourties",
        }
    );

    const [selectedChatDocBase, setSelectedChatDocBase] = useState<KbDocBase>();
    const [kbChatConf, setKbChatConf] = useState<KbChatConf>(
        {
            history_len: 1,
            temperature: 0.35,
            top_k: 3,
            score_threshold: 1.00
        }
    );



    const onDocBaseChanged = (docBase: KbDocBase) => {
        setSelectedChatDocBase(docBase);
    };

    const onKbChatConfChanged = (value: KbChatConf) => {
        setKbChatConf(value)
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "内部知识库2.0", path: "/apps/kbchat", active: true },
                ]}
                title={"内部知识库2.0"}
            />

            <Row>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <KbDocBases onDocBaseSelect={onDocBaseChanged} onKbChatConfChanged={onKbChatConfChanged} />
                </Col>
                <Col xs={12} sm={6} md={6} lg={8} xl={9}>
                    <ChatArea selectedUser={selectedUser} chatDocBaseUuid={selectedChatDocBase?.uuid} kbChatConf={kbChatConf}  />
                </Col>
            </Row>
        </>
    );
};

export default KbChatApp;
