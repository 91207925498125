


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_meetingminutes.png";

interface MeetingMinutesWidgetProps extends WidgetProps {
    pad?: string;
}
export class MeetingMinutesWidget extends BaseWidget<any, MeetingMinutesWidgetProps> {
    title: string = "会议纪要";
    desc: string = "帮助你生成会议纪要";
    routePath: string = "/apps/aitool/widgets/meetingminutes/meetingminutesapp";
    iconUrl: string = avatar;


}



