import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Card, Button, Modal, Form, InputGroup} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import WelcomeEditor from "./WelcomeEditor";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import {FormProvider, useForm} from "react-hook-form";
import {users} from "../AiChat/data";
import {auth_fetch_post_json, useAuthFetchPost} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import Table from "../../../components/Table";
import Spinner from "../../../components/Spinner";





const WelcomeEditorApp = () => {
    const [faqs, setFaqs] = useState<any>([]);
    const [isFaqModalEdit, seIsFaqModalEdit] = useState(false)
    const [newFaqVisible, setNewFaqVisible] = useState(false)
    const [currentFaq, setCurrentFaq] = useState(-1)
    const [bigTitle, setBigTitle] = useState("")
    const [smallTitle, setSmallTitle] = useState("")

    const faqMethods = useForm({});
    const faqResetPost = useAuthFetchPost()
    const faqUpdatePost = useAuthFetchPost()
    const faqNewPost = useAuthFetchPost()
    const faqRemovePost = useAuthFetchPost()


    const sizePerPageList = [
        {
            text: "25",
            value: 25,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        }
    ];

    const columns = React.useMemo(
        () => [

            {
                Header: "编号",
                accessor: "faq_id",
                sort: false,
            },
            {
                Header: "问题",
                accessor: "question",
                sort: false,
            },
            {
                Header: "答案",
                accessor: "answer",
                sort: false,
            },
            {
                Header: "可见",
                accessor: "is_visible_icon",
                sort: false,
                Cell: (data: any) => {
                    if(data.row.original.is_visible) {
                        return <span className={'text-success'}><i className={"mdi mdi-eye"}></i></span>
                    } else {
                        return <span className={'text-danger'}><i className={"mdi mdi-eye-off"}></i></span>
                    }
                }
            },
            {
                Header: "操作",
                accessor: "action",
                sort: false,
                Cell: (data: any) => {
                    return (
                        <div>
                            <Button className={"btn btn-danger me-2"} size={"sm"}><i className={"mdi mdi-trash-can"}
                                onClick={()=>{
                                    faqRemovePost.doPost(Conf.urlWelcomeFaqRemove, {
                                        'faq_id': data.row.original['faq_id'],
                                        'is_welcome': true
                                    })
                                        .then((respJson: any) => {
                                            if(respJson["status"]) {
                                                setFaqs(respJson.data)
                                            } else {
                                                alert(respJson.reason)
                                            }
                                        })
                                        .catch((error) => {
                                            alert(error)
                                        })
                                }}
                            ></i></Button>
                            <Button className={"btn btn-primary"} size={"sm"}><i className={"mdi mdi-pencil"}
                                onClick={()=>{
                                    setCurrentFaq(data.row.original['faq_id'])
                                    seIsFaqModalEdit(true)
                                    setNewFaqVisible(true)
                                    faqMethods.reset({
                                        'question': data.row.original['question'],
                                        'answer': data.row.original['answer'],
                                        'is_visible': data.row.original['is_visible'],
                                    })
                                }}
                            ></i></Button>
                        </div>
                    )
                }
            },
        ],
        []
    );

    const toggleNewFaqModal = () => {
        setNewFaqVisible(!newFaqVisible)
    };



    const newFaq = async (values: any, event: any) => {
        console.log(values)
        console.log(event)

        const jsonData: any = {}
        jsonData["is_welcome"] = true
        jsonData["question"] = values.question
        jsonData["answer"] = values.answer
        jsonData["is_visible"] = values.is_visible


        if (isFaqModalEdit) {
            jsonData["faq_id"] = currentFaq
        }

        const url = isFaqModalEdit? Conf.urlWelcomeFaqUpdate : Conf.urlWelcomeFaqNew

        faqNewPost.doPost(url, jsonData)
            .then((respJson: any) => {
                if(respJson["status"]) {
                    setFaqs(respJson.data)
                    setNewFaqVisible(false)
                } else {
                    alert(respJson.reason)
                }
            })
            .catch((error) => {
                alert(error)
            })
        return false
    }

    const loadFaqs = () => {
        console.log("loadFaqs")

        auth_fetch_post_json(Conf.urlWelcomeFaqList, {
            'is_welcome': true
        })
            .then((json: any) => {
                console.log(json)
                setFaqs(json.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const loadTitles = async () => {
        auth_fetch_post_json(Conf.urlWebCustomGetStr, {
            'k': "welcome_big_title"
        }).then((json: any)=> {
            if(json.status) {
                setBigTitle(json.v)
            } else {
                console.log(json)
            }
        }).catch(error => console.log(error))
        auth_fetch_post_json(Conf.urlWebCustomGetStr, {
            'k': "welcome_small_title"
        }).then((json: any)=> {
            if(json.status) {
                setSmallTitle(json.v)
            } else {
                console.log(json)
            }
        }).catch(error => console.log(error))

    }
    useEffect(() => {
        loadTitles()
        loadFaqs()
    }, []);

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "欢迎编辑器", path: "/apps/welcomeeditor", active: true },
                ]}
                title={"欢迎编辑器"}
            />
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="Button">大标题</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="输入大标题"
                                aria-label="大标题"
                                aria-describedby="大标题-addon2"
                                value={bigTitle}
                                onChange={(e) => {
                                    setBigTitle(e.target.value)
                                }}
                            />
                            <Button variant="primary" onClick={() => {
                                auth_fetch_post_json(Conf.urlWebCustomSetStr, {
                                    'k': 'welcome_big_title',
                                    'v': bigTitle,
                                })
                                    .then((json: any) => {
                                        if(!json.status) {
                                            alert(json.reason)
                                        }
                                    })
                            }}>
                                保存
                            </Button>
                            <Button variant="danger" onClick={()=> {
                                auth_fetch_post_json(Conf.urlWebCustomReset, {
                                    'k': 'welcome_big_title'
                                })
                                    .then((json: any) => {
                                        if(!json.status) {
                                            alert(json.reason)
                                        } else {
                                            setBigTitle(json.v)
                                        }
                                    })
                            }}>
                                恢复出厂设置
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="Button">小标题</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="输入小标题"
                                aria-label="小标题"
                                aria-describedby="小标题-addon2"
                                value={smallTitle}
                                onChange={(e) => {
                                    setSmallTitle(e.target.value)
                                }}
                            />
                            <Button variant="primary" onClick={() => {
                                auth_fetch_post_json(Conf.urlWebCustomSetStr, {
                                    'k': 'welcome_small_title',
                                    'v': smallTitle
                                })
                                    .then((json: any) => {
                                        if(!json.status) {
                                            alert(json.reason)
                                        }
                                    })
                            }}>
                                保存
                            </Button>
                            <Button variant="danger" onClick={() => {
                                auth_fetch_post_json(Conf.urlWebCustomReset, {
                                    'k': 'welcome_small_title'
                                })
                                    .then((json: any) => {
                                        if(!json.status) {
                                            alert(json.reason)
                                        } else {
                                            setSmallTitle(json.v)
                                        }
                                    })
                            }}>
                                恢复出厂设置
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className={"mb-3"}>
                    <button
                        className={"btn btn-sm btn-primary me-2"}
                        onClick = {
                        () => {
                            setCurrentFaq(-1)
                            seIsFaqModalEdit(false)
                            setNewFaqVisible(true)
                            faqMethods.reset({})
                        }
                    }>
                        <i className={"mdi mdi-plus"}></i>添加一条
                    </button>
                    <button
                        className={"btn btn-sm btn-danger me-2"}
                        disabled={faqResetPost.isPosting}
                        onClick = {
                            () => {
                                faqResetPost.doPost(Conf.urlWelcomeFaqReset, {
                                    'is_welcome': true
                                })
                                    .then(json => {
                                        if(json.status) {
                                            setFaqs(json.data)
                                        } else {
                                            alert(json.reason)
                                        }
                                    })
                            }
                        }>
                        {faqResetPost.isPosting && <Spinner
                            className="spinner-border-sm me-1"
                            tag="span"
                            color="white"
                        />}
                        恢复出厂设置
                    </button>

                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}>
                    <Table
                        columns={columns}
                        data={faqs}
                        pageSize={25}
                        sizePerPageList={sizePerPageList}
                        isSortable={true}
                        pagination={true}
                        isSearchable={true}
                        isSelectable={false}
                    />
                </Col>
            </Row>
            <Modal show={newFaqVisible} onHide={toggleNewFaqModal}>
                <FormProvider {...faqMethods}>
                    <Form onSubmit={faqMethods.handleSubmit(newFaq)}>
                        <Modal.Header closeButton>
                            <h4 className="modal-title">{isFaqModalEdit? '编辑问答' : '添加问答'}</h4>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="field-3" className="form-label">
                                            问题
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="field-3"
                                            placeholder="输入一些问题"
                                            required
                                            {...faqMethods.register("question", { required: true })}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="field-answer" className="form-label">
                                            回答
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="field-answer"
                                            placeholder="再此输入回答"
                                            required
                                            style={{height: "240px"}}
                                            {...faqMethods.register("answer", { required: true })}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id="default-checkbox1"
                                            label="显示在欢迎页"
                                            {...faqMethods.register("is_visible", { required: false })}

                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect"
                                onClick={toggleNewFaqModal}
                            >
                                关闭
                            </button>
                            <button
                                disabled={faqNewPost.isPosting}
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                            >
                                保存
                            </button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default WelcomeEditorApp;
