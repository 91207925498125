
import React, {useEffect, useState} from "react";
import {BaseWidgetApp} from "../BaseWidgetApp";
import {WidgetAppParam, WidgetAppParamTextArea} from "../WidgetAppParam";


export class MeetingMinutesApp extends BaseWidgetApp<any, any> {
    params(): WidgetAppParam[] {
        return [
            new WidgetAppParamTextArea("subject", "会议主题", "请输入会议的主题", "会议主题"),
            new WidgetAppParamTextArea("content", "会议内容", "请输入会议的内容", "会议内容"),

        ];
    }

    template: string = "MeetingMinutes";
    title: string = "会议纪要";
}

export default MeetingMinutesApp;

