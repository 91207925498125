import sparkMD5 from "spark-md5";
import Conf from "../pages/apps/Utils/Conf";

type ProgressCallback = (sentBytes: number, totalBytes: number) => void;
type FinishedCallback = (fileId: string, fileUrl: string, file: File) => void;


class FileSender {
    private static readonly CHUNK_SIZE = 64 * 1024; // Internal constant for chunk size

    private readonly _file: File;
    private readonly _wsUrl: string;
    private readonly _md5: string;
    private readonly _onProgress: ProgressCallback;
    private readonly _onFinished: FinishedCallback;
    public fileId = ''
    public fileUrl = ''
    private _ws: WebSocket | undefined


    constructor(wsUrl: string, file: File, md5: string, onProgress: ProgressCallback, onFinished: FinishedCallback) {
        this._file = file;
        this._wsUrl = wsUrl;
        this._md5 = md5;
        this._onProgress = onProgress;
        this._onFinished = onFinished;
    }




    public async startSend(): Promise<void> {
        let is_ok_done = false
        return new Promise((resolve, reject) => {
            this._ws = new WebSocket(this._wsUrl);
            this._ws.binaryType = "arraybuffer";
            this._ws.onopen = () => {
                this._ws?.send(JSON.stringify({
                    action: "start",
                    filename: this._file.name,
                    filesize: this._file.size,
                    mimetype: this._file.type,
                    md5: this._md5,
                }));
            };

            this._ws.onclose = () => {
                if(!is_ok_done) {
                    reject(new Error(`File Sending Error: socket closed`));
                }
            }

            this._ws.onmessage = (event) => {
                const data = event.data;
                console.log(data);
                try {
                    const json = JSON.parse(data);
                    if(json.action == "accepted") {
                        this._sendFileInChunks();
                    } else if(json.action == "error") {
                        reject(new Error(`File Sending Error: ${json.reason}`));
                    } else if(json.action == "finished") {
                        is_ok_done = true;
                        this.fileId = json.fileId
                        this.fileUrl = Conf.urlSecaiFoGet + "?fo_id=" + json.fileId
                        console.log("_onFinished " + this._file.name)
                        this._onFinished(this.fileId , this.fileUrl, this._file)


                        resolve();
                    } else {
                        reject(new Error(`File Sending Error: unknown action ${json.action}`));
                    }
                } catch (error) {
                    reject(new Error(`File Sending Error: parse error}`));
                }

            }

            this._ws.onerror = (event) => {
                reject(new Error(`网络错误`));
            }

        })


    }

    public async stopSend(): Promise<void> {
        console.error("stopSend")
        try {
            if (this._ws) {
                this._ws.close()
            }
        } catch (e) {

        } finally {
            this._ws = undefined
        }
    }

    private async _sendFileInChunks(): Promise<void> {

        return new Promise(async (resolve, reject) => {
            const ws = this._ws;
            if (!ws) {
                reject(new Error(`File Sending Error: socket closed`));
            } else {
                const totalBytes = this._file.size;
                let sentBytes = 0;

                const totalChunks = Math.ceil(totalBytes / FileSender.CHUNK_SIZE);
                console.log("total size is " + totalBytes + " totalChunks " + totalChunks);
                for (let i = 0; i < totalChunks; i++) {
                    const start = i * FileSender.CHUNK_SIZE;
                    const end = Math.min(totalBytes, start + FileSender.CHUNK_SIZE);
                    const blob = this._file.slice(start, end);
                    await this._sendChunk(ws, blob);
                    sentBytes += blob.size;
                    this._onProgress?.(sentBytes, totalBytes);
                }
                console.log("sentBytes is " + sentBytes);
            }
        })


    }

    private _sendChunk(ws: WebSocket, chunk: Blob): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    ws.send(reader.result as ArrayBuffer);
                    console.log("bytes sent" + (reader.result as ArrayBuffer).byteLength)
                    resolve();
                } catch (error) {
                    reject(new Error(`File Sending Error:send broken`));
                }
            };
            reader.onerror = (event) => {
                const error = event.target?.error;
                reject(new Error(`File Reading Error: ${error?.message || "Unknown error"}`));
            };
            reader.readAsArrayBuffer(chunk);
        });
    }
}

export default FileSender;
