import {Dropdown} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import {ChatDialog} from "./ChatArea";

interface ChatDialogSelectorProps {
    selectedDialogId: number
    onNewClicked: ()=> void
    onRenameClicked: ()=> void
    onRemoveClicked: () => void
    onHistoryClicked: ()=> void
    onDialogChanged: (dialogId: number, robotId: string) => void
    refresh: number
    isUsingInKb: boolean
}
export const ChatDialogSelector = (props: ChatDialogSelectorProps) => {
    const [chatDialogs, setChatDialogs] = useState<ChatDialog[]>([])

    useEffect(() => {
        const reload = async () => {
            try {
                const dialogs = await auth_fetch_post_json(Conf.urlApiChat2DialogList, {}) as any
                console.log(dialogs)
                if(props.isUsingInKb) {
                    setChatDialogs(dialogs.filter((d: ChatDialog) => d.robotId == 'chatFile'))
                } else {
                    setChatDialogs(dialogs.filter((d: ChatDialog) => d.robotId != 'chatFile'))
                }

                if(dialogs.length > 0) {
                    const usage = await auth_fetch_post_json(Conf.urlApiChat2DialogUsage, {}) as any
                    if(!usage.status) {
                        if(props.isUsingInKb) {
                            props.onDialogChanged(-1, 'chatFile')
                        } else {
                            props.onDialogChanged(-1, 'genericHelperA')
                        }

                    } else {
                        props.onDialogChanged(usage.dialogId, usage.robotId)
                    }
                } else {
                    if(props.isUsingInKb) {
                        props.onDialogChanged(-1, 'chatFile')
                    } else {
                        props.onDialogChanged(-1, 'genericHelperA')
                    }
                }
            } catch (error) {
                console.error('Error:', error);
                if(props.isUsingInKb) {
                    props.onDialogChanged(-1, 'chatFile')
                } else {
                    props.onDialogChanged(-1, 'genericHelperA')
                }
            }
        }

        reload().then().catch().finally()
    }, [props.refresh]);

    const findDialogName = (dialogId: number) => {
        for(let i = 0; i < chatDialogs.length; i++) {
            if(chatDialogs[i].dialogId == dialogId) {
                return chatDialogs[i].dialogName
            }
        }
        return '未命名会话'
    }

    const dialogSwitch = async (dialog: ChatDialog) => {
        const dialogId = dialog.dialogId
        console.log("切换到 " + dialogId)
        const res = await auth_fetch_post_json(Conf.urlApiChat2DialogSwitch, {dialogId}) as any
        console.log(res)
        if(res.status) {
            props.onDialogChanged(dialogId, dialog.robotId)
        } else {
            console.error('切换失败')
        }
    }

    const handleOnRemoveClicked = async () => {
        if(props.selectedDialogId == -1 ) {return}
        const res = await auth_fetch_post_json(Conf.urlApiChat2DialogRemove, {dialogId: props.selectedDialogId}) as any
        console.log(res)
        if(res.status) {
            const dialogs = await auth_fetch_post_json(Conf.urlApiChat2DialogList, {}) as any
            if(props.isUsingInKb) {
                setChatDialogs(dialogs.filter((d: ChatDialog) => d.robotId == 'chatFile'))
            } else {
                setChatDialogs(dialogs.filter((d: ChatDialog) => d.robotId != 'chatFile'))
            }

            if(dialogs.length > 0) {
                const usage = await auth_fetch_post_json(Conf.urlApiChat2DialogUsage, {}) as any
                if(!usage.status) {
                    if(props.isUsingInKb) {
                        props.onDialogChanged(-1, 'chatFile')
                    } else {
                        props.onDialogChanged(-1, 'genericHelperA')
                    }
                } else {
                    props.onDialogChanged(usage.dialogId, usage.robotId)
                }
            } else {
                if(props.isUsingInKb) {
                    props.onDialogChanged(-1, 'chatFile')
                } else {
                    props.onDialogChanged(-1, 'genericHelperA')
                }
            }
        } else {
            console.error('删除失败')
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    {findDialogName(props.selectedDialogId)} <i className="mdi mdi-chevron-down"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu
                    style={{
                        maxHeight: '420px',
                        overflowY: 'auto'
                    }}
                >
                    <Dropdown.Item onClick={props.onNewClicked}>发起新会话</Dropdown.Item>
                    <Dropdown.Item onClick={handleOnRemoveClicked}>删除当前会话</Dropdown.Item>
                    {chatDialogs.length > 0 && (<Dropdown.Divider/>)}
                    {
                        chatDialogs.map(cd => <Dropdown.Item key={'dlg-' + cd.dialogId} onClick={async ()=> {await dialogSwitch(cd) }} >{cd.dialogName} </Dropdown.Item>)
                    }
                    {chatDialogs.length > 0 && (<Dropdown.Divider/>)}
                    {chatDialogs.length > 0 && (<Dropdown.Item
                        onClick={props.onHistoryClicked}>更多历史会话...</Dropdown.Item>)}

                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}