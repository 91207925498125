import React, {useEffect, useState} from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import profileImg from "../../../assets/images/users/user-1.jpg";
import {APICore} from "../../../helpers/api/apiCore";
import useUser from "../../../hooks/useUser";
import useUploading from "../../../utils/uploading";
import Conf from "../Utils/Conf";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";

const MyProfileUserBox = () => {
  const user = useUser()
  const uploading = useUploading(Conf.urlSecaiImageTransFileSend)
  const [myAvatarUrl, setMyAvatarUrl] = useState('')

  useEffect(() => {
    auth_fetch_post_json(Conf.urlWebCustomGetStr, {
      k: "avatar_default_url",
    }).then((res: any) => {
      if(res['status']) {
        setMyAvatarUrl(res['v'])

      } else {

      }
    })
  }, []);

  return (
    <Card className="text-center">
      <Card.Body>
        <img
          src={myAvatarUrl}
          className="rounded-circle avatar-lg img-thumbnail"
          alt=""
          onClick={()=> {
            uploading.startUpload("image/png, image/jpeg")
          }}
        />
        <h4 className="mb-0">{user.user? user.user.username: "-"}</h4>
        <p className="text-muted">{user.user? user.user.username: "-"}</p>
        <div className="text-start mt-3">
          <h4 className="font-13 text-uppercase">关于我:</h4>
          <p className="text-muted font-13 mb-3">
            我没有留下任何资料.
          </p>
          <p className="text-muted mb-2 font-13">
            <strong>全名 :</strong>
            <span className="ms-2">{user.user? user.user.username: "-"}</span>
          </p>

          <p className="text-muted mb-2 font-13">
            <strong>电话 :</strong>
            <span className="ms-2">(123) 123 1234</span>
          </p>

          <p className="text-muted mb-2 font-13">
            <strong>邮箱 :</strong>
            <span className="ms-2 ">user@email.domain</span>
          </p>

          <p className="text-muted mb-1 font-13">
            <strong>Location :</strong>
            <span className="ms-2">保密</span>
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MyProfileUserBox;
