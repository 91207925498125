


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_engchat.png";

interface EngChatWidgetProps extends WidgetProps {
    pad?: string;
}
export class EngChatWidget extends BaseWidget<any, EngChatWidgetProps> {
    title: string = "英语对话练习";
    desc: string = "帮你练习英语对话";
    routePath: string = "/apps/aitool/widgets/engchat/engchatapp";
    iconUrl: string = avatar;


}



