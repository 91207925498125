


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_studymindmap.png";

interface StudyMindMapWidgetProps extends WidgetProps {
    pad?: string;
}
export class StudyMindMapWidget extends BaseWidget<any, StudyMindMapWidgetProps> {
    title: string = "教学思维导图";
    desc: string = "帮您生成教学思维导图";
    routePath: string = "/apps/aitool/widgets/studymindmap/studymindmapapp";
    iconUrl: string = avatar;


}



