import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Spinner, Form, ButtonGroup} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import Inpaint from "./Inpaint";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import {Position} from "react-beautiful-dnd";
import {browseFile} from "../Utils/FileUtil";
import Conf from "../Utils/Conf";
import {calcDrawPos} from "../Utils/CanvasUtil";

enum Action {
    DRAWING,
    ERASING,
    NONE
}



const InpaintApp = () => {


    const canvasTarget = useRef<HTMLCanvasElement>(null);
    const canvasSource = useRef<HTMLCanvasElement>(null);
    const canvasBack = document.createElement('canvas');

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [drawing, setDrawing] = useState<Action>(Action.NONE);
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
    const [lastPoint, setLastPoint] = useState<{x: number, y: number}>({x: 0, y: 0});

    // 初始化canvas
    useEffect(() => {
        console.log("new canvasback")

        const renderCtx = canvasBack.getContext('2d');
        canvasBack.width = canvasSource.current!.offsetWidth
        canvasBack.height = canvasSource.current!.offsetHeight


        if (renderCtx) {
            setContext(renderCtx);
        }

    }, [canvasBack]);

    // 画线的方法
    const drawLine = (x1: number, y1: number, x2: number, y2: number, erasing = false) => {
        if (!context) return;
        context.beginPath();
        context.strokeStyle = erasing ? 'white' : 'black';
        context.lineWidth = erasing ? 10 : 1;
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();
        context.closePath();
    };

    // 鼠标按下的方法
    const startDrawing = (event: React.MouseEvent<HTMLCanvasElement>) => {
        const {button, clientX, clientY} = event;
        const rect = canvasBack.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;

        setLastPoint({x, y});
        setDrawing(button === 0 ? Action.DRAWING : Action.ERASING);
        event.preventDefault();
    };

    // 鼠标松开的方法
    const stopDrawing = () => {
        setDrawing(Action.NONE);
    };

    // 鼠标移动的方法
    const draw = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (drawing === Action.NONE) return;

        const {clientX, clientY} = event;
        const rect = canvasBack.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;

        drawLine(lastPoint.x, lastPoint.y, x, y, drawing === Action.ERASING);
        setLastPoint({x, y});
    };



    const setSourceImage = useCallback((event: any) => {
        // @ts-ignore
        browseFile()
            .then(f => {
                const img = new Image();
                img.onload = () => {
                    if(canvasSource) {
                        const contextSource = canvasSource.current!.getContext('2d');
                        canvasSource.current!.width = img.width
                        canvasSource.current!.height = img.height
                        contextSource!.drawImage(img, 0, 0, img.width, img.height);
                    }

                }
                img.src = f as string

            })
            .catch(e => {
                alert(e)
        })

    }, []);


    const doInpaint = useCallback((e) => {




    }, [])







    useEffect(() => {


    }, []);






    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "应用", path: "/apps/inpaint" },
                    { label: "Inpaint", path: "/apps/inpaint", active: true },
                ]}
                title={"大模型抠图"}
            />
            <Row>
                <Col md={6} xl={6}>
                    <Button variant="danger" onClick={setSourceImage}>
                        <i className="mdi mdi-close"></i>
                    </Button>
                    <Button variant="primary" onClick={doInpaint}>
                        抠图
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col md={12} xl={6}>
                    <canvas
                        ref={canvasSource}
                        onMouseDown={startDrawing}
                        onMouseUp={stopDrawing}
                        onMouseMove={draw}
                        onContextMenu={(e) => e.preventDefault()}
                        style={{ width: '100%', height: '60vh' }}
                    />
                </Col>
                <Col md={12} xl={6}>
                    <canvas
                        ref={canvasTarget}
                        style={{ width: '100%', height: '60vh' }}
                    />
                </Col>
            </Row>

        </>
    );
};

export default InpaintApp;
