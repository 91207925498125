import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import './UploadableAudioBox.css'
import useUploading from "../../../utils/uploading";
import Conf from "../Utils/Conf";
import UploadingProgressModal from "../../../utils/UploadingProgressModal";
import { AudioVisualizer } from 'react-audio-visualize';


interface UploadableAudioBoxProps  {
    onAudioFileChanged: (fileId: string, fileName: string,  fileUrl: string) => void;
    onTimeUpdate: (currentTime: number) => void;
}


export interface UploadableAudioBoxRef {
    // 暴露给外部程序的方法或属性...
    someMethod: () => void;
}

const UploadableAudioBox = forwardRef<UploadableAudioBoxRef, UploadableAudioBoxProps> ((props, ref) => {
    const uploading = useUploading(Conf.urlSecaiFoSend);
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [blob, setBlob] = useState<Blob>();
    const visualizerRef = useRef<HTMLDivElement>(null);
    const [visualizerWidth, setVisualizerWidth] = useState(0);

    useImperativeHandle(ref, () => ({
        someMethod() {
            console.log("someMethod called");
        }
    }))

    const handleUploadClick = () => {
        console.log("upload clicked");
        uploading.startUpload("audio/*");
    }
    const handleCloseClick = () => {
        uploading.clear()
    };

    useEffect(() => {
        const handleResize = () => {
            if (visualizerRef.current) {
                console.log("change " + visualizerRef.current.clientWidth)
                setVisualizerWidth(visualizerRef.current.clientWidth);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        props.onAudioFileChanged(uploading.fileId, uploading.fileName, uploading.fileUrl)
        const fetchAudioBlob = async () => {
            try {
                const response = await fetch(uploading.fileUrl);
                const blob = await response.blob();
                setBlob(blob);
            } catch (error) {
                console.error('Error fetching audio blob:', error);
            }
        };
        if (visualizerRef.current) {
            console.log("change " + visualizerRef.current.clientWidth)
            setVisualizerWidth(visualizerRef.current.clientWidth);
        }
        fetchAudioBlob();
    }, [uploading.fileId]);
    return (
        <div className={"uploadable-audio-box-container"}>
            {
                (uploading.fileUrl !== '') ? (
                    <div style={{position: 'relative', width: '100%', height: '100%'}}
                         onMouseEnter={() => setShowCloseButton(true)}
                         onMouseLeave={() => setShowCloseButton(false)}
                    >
                        <div ref={visualizerRef}>
                            {blob && (
                                <AudioVisualizer
                                    blob={blob}
                                    width={visualizerWidth}
                                    height={100}
                                    barWidth={1}
                                    gap={0}
                                    barColor={'#006600'}
                                />
                            )}
                        </div>
                        <div>
                            <audio controls className={'uploadable-audio-box-audio-control'}
                                   onError={e => console.log("audio error", e)}
                                   onTimeUpdate={e => props.onTimeUpdate(e.currentTarget.currentTime)}
                            >
                                <source src={uploading.fileUrl} />
                                Your browser does not support the audio tag.
                            </audio>
                            {showCloseButton && (
                                <div style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    color: '#fff',
                                    cursor: 'pointer'
                                }}
                                     onMouseEnter={() => setShowCloseButton(true)}
                                     onMouseLeave={() => setShowCloseButton(false)}
                                     onClick={handleCloseClick}>
                                    <i className={"fas fa-times"}></i>
                                </div>
                            )}
                        </div>
                    </div>

                ) : (
                    <div className={"uploadable-audio-box-placeholder"}>
                        <Button variant={"primary"} onClick={() => handleUploadClick()}><i
                            className={"fas fa-upload"}></i> 上传音频</Button>
                    </div>
                )
            }
            <UploadingProgressModal
                fileName={uploading.fileName}
                fileSize={uploading.fileSize}
                progress={uploading.progress}
                statusText={uploading.statusText}
                show={uploading.uploading}
                onHide={() => {
                }}
                onStopClicked={() => {
                }}></UploadingProgressModal>
        </div>
    )
})

export default UploadableAudioBox;