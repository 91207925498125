import {auth_fetch_post, auth_fetch_post_json} from "../../../utils/auth_fetch";

export const ssePost = async (sseUrl: string, dataToPost: any, onMsg: (msg: string) => void, onFinish: () => void, onError: (msg: string)=> void) => {
    const resp = await auth_fetch_post(sseUrl, dataToPost);
    if (!resp.ok) {
        onError("连接服务器错误")
        return
    }
    const reader = resp.body!.getReader();
    const decoder = new TextDecoder('utf-8');
    async function readStream(): Promise<void> {
        const { value, done } = await reader.read();

        if (done) {
            onFinish();
            return;
        }

        const data = decoder.decode(value, { stream: true });
        onMsg(data)
        readStream();
    }
    // Start reading the stream
    readStream();
}

export const sseGet = async (sseUrl: string, dataToPost: any, onMsg: (msg: string) => void, onFinish: () => void, onError: (msg: string)=> void) => {
    function utf8ToBase64(str: string) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode(parseInt(p1, 16));
        }));
    }
    const dataToPostStr = JSON.stringify(dataToPost)
    const encodedPayload = utf8ToBase64(dataToPostStr);
    const eventSource = new EventSource(sseUrl + "?encodedPayload=" + encodedPayload);
    eventSource.onopen = (event) => {
        console.log("Connection opened:", event);
    };

    eventSource.onmessage = (event) => {
        console.log("Data received:", event.data);
        // const data = decoder.decode(value, { stream: true });
        // onMsg(data)

    };

    eventSource.onerror = (event) => {
        console.log("错误:", event);
        if (eventSource.readyState === EventSource.CLOSED) {
            console.log("Connection closed.");
        } else {
            console.log("An error occurred.");
        }
        onError("连接服务器错误")

    };


    eventSource.addEventListener("customEvent", (event) => {
        console.log("Custom event received:", event);
    });

}