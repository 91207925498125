import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";
const ocrLangs = [
        {"value": "ch", "label": "中文"},
        {"value": "en", "label": "英文"},
        {"value": "fr", "label": "法文"},
        {"value": "german", "label": "德文"},
        {"value": "japan", "label": "日文"},
        {"value": "korean", "label": "韩文"},
        {"value": "chinese_cht", "label": "中文繁体"},
        {"value": "it", "label": "意大利文"},
        {"value": "es", "label": "西班牙文"},
        {"value": "pt", "label": "葡萄牙文"},
        {"value": "ru", "label": "俄罗斯文"},
        {"value": "ar", "label": "阿拉伯文"},
        {"value": "hi", "label": "印地文"},
        {"value": "ug", "label": "维吾尔"},
        {"value": "fa", "label": "波斯文"},
        {"value": "ur", "label": "乌尔都文"},
        {"value": "rs_latin", "label": "塞尔维亚文（latin）"},
        {"value": "oc", "label": "欧西坦文"},
        {"value": "mr", "label": "马拉地文"},
        {"value": "ne", "label": "尼泊尔文"},
        {"value": "rs_cyrillic", "label": "塞尔维亚文（cyrillic）"},
        {"value": "mi", "label": "毛利文"},
        {"value": "ms", "label": "马来文"},
        {"value": "mt", "label": "马耳他文"},
        {"value": "nl", "label": "荷兰文"},
        {"value": "no", "label": "挪威文"},
        {"value": "pl", "label": "波兰文"},
        {"value": "ro", "label": "罗马尼亚文"},
        {"value": "sk", "label": "斯洛伐克文"},
        {"value": "sl", "label": "斯洛文尼亚文"},
        {"value": "sq", "label": "阿尔巴尼亚文"},
        {"value": "sv", "label": "瑞典文"},
        {"value": "sw", "label": "西瓦希里文"},
        {"value": "tl", "label": "塔加洛文"},
        {"value": "tr", "label": "土耳其文"},
        {"value": "uz", "label": "乌兹别克文"},
        {"value": "vi", "label": "越南文"},
        {"value": "mn", "label": "蒙古文"},
        {"value": "bg", "label": "保加利亚文"},
        {"value": "uk", "label": "乌克兰文"},
        {"value": "be", "label": "白俄罗斯文"},
        {"value": "te", "label": "泰卢固文"},
        {"value": "abq", "label": "阿巴扎文"},
        {"value": "ta", "label": "泰米尔文"},
        {"value": "af", "label": "南非荷兰文"},
        {"value": "az", "label": "阿塞拜疆文"},
        {"value": "bs", "label": "波斯尼亚文"},
        {"value": "cs", "label": "捷克文"},
        {"value": "cy", "label": "威尔士文"},
        {"value": "da", "label": "丹麦文"},
        {"value": "et", "label": "爱沙尼亚文"},
        {"value": "ga", "label": "爱尔兰文"},
        {"value": "hr", "label": "克罗地亚文"},
        {"value": "hu", "label": "匈牙利文"},
        {"value": "id", "label": "印尼文"},
        {"value": "is", "label": "冰岛文"},
        {"value": "ku", "label": "库尔德文"},
        {"value": "lt", "label": "立陶宛文"},
        {"value": "lv", "label": "拉脱维亚文"},
        {"value": "dar", "label": "达尔瓦文"},
        {"value": "inh", "label": "因古什文"},
        {"value": "lbe", "label": "拉克文"},
        {"value": "lez", "label": "莱兹甘文"},
        {"value": "tab", "label": "塔巴萨兰文"},
        {"value": "bh", "label": "比尔哈文"},
        {"value": "mai", "label": "迈蒂利文"},
        {"value": "ang", "label": "昂加文"},
        {"value": "bho", "label": "孟加拉文"},
        {"value": "mah", "label": "摩揭陀文"},
        {"value": "sck", "label": "那格浦尔文"},
        {"value": "new", "label": "尼瓦尔文"},
        {"value": "sa", "label": "沙特阿拉伯文"},
        {"value": "ady", "label": "阿迪赫文"}

]

interface OcrResult {
    imageUrl?: string
    txtUrl?: string
    jsonUrl?: string
    txt?: string
    translatedText?: string
}
function useOcr() {
    const [ocrProgress, setOcrProgress] = useState(0);
    const [ocrResult, setOcrResult] = useState<OcrResult>({});
    const [isOcring, setIsOrcing] = useState(false);
    const [statusText, setStatusText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // @ts-ignore
    const socketRef = useRef<WebSocket | null>(null);  // Explicitly type the ref here

    const startOcr = useCallback((fileId: string, langCode: string, transEnabled: boolean, transFromLangName: string, transToLangName: string) => {
        if (socketRef.current) {
            socketRef.current.close();
        }
        // @ts-ignore
        const socket = new WebSocket(Conf.urlSecAiOcrFileConvert);
        socketRef.current = socket;

        socket.onopen = () => {
            setIsOrcing(true)
            socket.send(JSON.stringify({
                action: "ocr",
                source: fileId,
                langCode: langCode,
                transEnabled: transEnabled,
                transFromLangName: transFromLangName,
                transToLangName: transToLangName
            }));
        }

        socket.onmessage = (event: any) => {
            console.log("onmessage " + event.data)
            const json = JSON.parse(event.data);
            if(json.action === "progress") {
                setOcrProgress(json.progress);
            } else if(json.action === "finished") {
                setOcrProgress(100);
                console.log("setOcrResult")
                console.log(json.result)
                setOcrResult(json.result)
            } else if(json.action === "error") {
                setErrorMessage(json['reason'])
            }
        }
        socket.onclose = () => {
            setIsOrcing(false)
        }
    }, []);

    const stopOcr = useCallback(() => {
        if (socketRef.current) {
            socketRef.current.close();
        }
    }, []);

    const findOcrLang = (langCode: string) => {
        const found = ocrLangs.find((lang) => lang.value === langCode)
        if(found) {
            return found['label']
        } else {
            return langCode
        }
    }


    return {
        ocrProgress,
        startOcr,
        stopOcr,
        findOcrLang,
        ocrLangs,
        ocrResult,
        isOcring,
        statusText,
        errorMessage
    };
}

export default useOcr;
