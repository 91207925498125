import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";
const imageTransLangs = [
    {"value": "CHS","label":  "简体中文"},
    {"value": "CHT","label":  "繁體中文"},
    {"value": "CSY","label":  "捷克语"},
    {"value": "NLD","label":  "荷兰语"},
    {"value": "ENG","label":  "英语"},
    {"value": "FRA","label":  "法语"},
    {"value": "DEU","label":  "德语"},
    {"value": "HUN","label":  "匈牙利语"},
    {"value": "ITA","label":  "意大利语"},
    {"value": "JPN","label":  "日语"},
    {"value": "KOR","label":  "韩语"},
    {"value": "PLK","label":  "波兰语"},
    {"value": "PTB","label":  "葡萄牙语（巴西）"},
    {"value": "ROM","label":  "罗马尼亚语"},
    {"value": "RUS","label":  "俄语"},
    {"value": "ESP","label":  "西班牙语"},
    {"value": "TRK","label":  "土耳其语"},
    {"value": "UKR","label":  "乌克兰语"},
    {"value": "VIN","label":  "越南语"},
    {"value": "ARA","label":  "阿拉伯语"}
]
function useTts() {
    const [ttsProgress, setTtsProgress] = useState(0);
    const [ttsStatus, setTtsStatus] = useState("");
    const [ttsResult, setTtsResult] = useState("");
    const [isTtsing, setIsTtsiing] = useState(false);
    const [statusText, setStatusText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // @ts-ignore
    const socketRef = useRef<WebSocket | null>(null);  // Explicitly type the ref here

    const startTts = useCallback((speakedId: string, textToConvert: string) => {
        console.log("开始转换 " + speakedId)
        if (socketRef.current) {
            socketRef.current.close();
        }
        setTtsResult("")
        // @ts-ignore
        const socket = new WebSocket(Conf.urlTtsConvert);
        socketRef.current = socket;

        socket.onopen = () => {
            setIsTtsiing(true)
            socket.send(JSON.stringify({
                action: "convertText",
                speakerId: speakedId,
                text: textToConvert
            }));
        }

        socket.onmessage = (event: any) => {
            console.log("onmessage " + event.data)
            const json = JSON.parse(event.data);
            if(json.action === "progress") {
                setTtsProgress(json.progress);
            } else if(json.action === "finished") {
                setTtsProgress(100);
                setTtsResult(json.result)
                socket.close()
            } else if(json.action === "error") {
                setErrorMessage(json['reason'])
                socket.close()
            }
        }
        socket.onclose = () => {
            setIsTtsiing(false)
        }
    }, []);


    const callTts = (speakedId: string, textToConvert: string, onDoneProc: (result: string) => void) => {
        const socket = new WebSocket(Conf.urlTtsConvert);
        socket.onopen = () => {
            socket.send(JSON.stringify({
                action: "convertText",
                speakerId: speakedId,
                text: textToConvert
            }));
        }

        socket.onmessage = (event: any) => {
            console.log("onmessage " + event.data)
            const json = JSON.parse(event.data);
            if(json.action === "finished") {
                onDoneProc(json.result)
                socket.close()
                setTtsResult(json.result)
            } else if(json.action === "error") {
                socket.close()
            }
        }
    }

    const stopTts = useCallback(() => {
        if (socketRef.current) {
            socketRef.current.close();
        }
    }, []);


    return {
        ttsProgress,
        startTts,
        stopTts,
        ttsResult,
        isTtsing,
        statusText,
        errorMessage,
        callTts
    };
}

export default useTts;
