import {Row, Col, Card, Button, Tab, Nav, Form, ListGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import { withSwal } from "react-sweetalert2";
import Conf from "../Utils/Conf";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";

interface TabLlmProps {
    swal: any
}
const TabLlm = withSwal((props: TabLlmProps) => {
    const {swal} = props
    const [llmServers, setLlmServers] = useState<string[]>([])


    const handleOnAddClick = async () => {
        const swalRes = await swal.fire(
            {
                title: "增加服务器",
                input: "text",
                inputPlaceholder: "请输入服务器地址, 例如: http://localhost:8002",
                showCancelButton: true,
                confirmButtonText: "增加",
                cancelButtonText: "取消",
                allowOutsideClick: false
            }
        )
        if(!swalRes.isConfirmed) {
            return
        }
        const serverUrl = swalRes.value
        if(!serverUrl) {
            return
        }
        const resp: any = await auth_fetch_post_json(Conf.urlApiLlmServerAdd, {
            url: serverUrl
        })
        if(resp.status !== 200) {
            swal.fire({
                title: "增加服务器失败",
                text: "服务器错误 " + resp.status + " " + resp.statusText,
                icon: "error"
            })
            return
        }
        const respJson = await resp.json()
        console.log(respJson)
        setLlmServers(respJson.map((item: any) => item.url))


    }


    const handleOnRemoveClick = async () => {
        const selectedUrls = llmServers.filter((item, index) => {
            const checkbox = document.getElementById("llmServerCheckbox" + index) as HTMLInputElement
            return checkbox.checked
        })
        console.log(selectedUrls)
        if(selectedUrls.length === 0) {
            swal.fire({
                title: "删除服务器",
                text: "请选择要删除的服务器",
                icon: "warning"
            })
            return
        }

        swal.fire(
            {
                title: "删除服务器",
                text: "确定要删除选中的服务器吗?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                allowOutsideClick: false
            }
        )
            .then(async (swalRes: any) => {
                if(!swalRes.isConfirmed) {
                    return
                }
                const resp: any = await auth_fetch_post_json(Conf.urlApiLlmServerRemove, {
                    urls: selectedUrls
                })
                if(resp.status !== 200) {
                    swal.fire({
                        title: "删除服务器失败",
                        text: "服务器错误 " + resp.status + " " + resp.statusText,
                        icon: "error"
                    })
                    return
                }
                const respJson = await resp.json()
                console.log(respJson)
                setLlmServers(respJson.map((item: any) => item.url))
            })
    }

    useEffect(() => {
        auth_fetch_post_json(Conf.urlApiLlmServerList, {
        }).then(async (respJson: any) => {
            console.log(respJson)
            setLlmServers(respJson.map((item: any) => item.url))
        })

    }, []);

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <h4>大语言模型</h4>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h4 className="header-title">模型服务器列表</h4>
                                <p className="sub-header">
                                    本系统支持自动调度模型服务器. 可以部署多台<code>大语言模型服务器</code>, 系统自动调度.
                                </p>
                                <ListGroup>
                                    {
                                        llmServers.map((serverUrl, index) => {
                                            return (
                                                <ListGroup.Item key={index}>
                                                    <input
                                                        id={"llmServerCheckbox" + index}
                                                        key={index}
                                                        className="form-check-input me-1"
                                                        type="checkbox"
                                                        value=""
                                                        aria-label="..."
                                                    />
                                                    {serverUrl}
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                </ListGroup>
                            </Col>
                            <Col></Col>


                        </Row>

                    </Card.Body>
                    <Card.Footer>
                        <Button className="btn btn-primary btn-sm me-2" onClick={handleOnAddClick}><i className={"mdi mdi-plus"}></i>增加服务器</Button>
                        <Button className="btn btn-danger btn-sm" onClick={handleOnRemoveClick}><i className={"mdi mdi-close"}></i>删除服务器</Button>

                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    )
})

export default TabLlm;