import {auth_fetch_post_json} from "./auth_fetch";
import Conf from "../pages/apps/Utils/Conf";
import {useEffect, useState} from "react";

type TaskType = 'video_trans' | 'audio_trans' | 'doc_trans'

interface VideoTransOption {
    asr_lang_code: String
    trans_engine: String
    trans_to_lang_code: String
}

interface AudioTransOption {
    asr_lang_code: String
    trans_engine: String
    trans_to_lang_code: String
}

interface DocTransOption {
    trans_from_lang_code: String
    trans_to_lang_code: String
}

interface TaskItem {
    task_type: TaskType
    file_name: string
    file_id: string
    video_trans_option?: VideoTransOption
    audio_trans_option?: AudioTransOption
    doc_trans_option?: DocTransOption
}

export interface Sentence {
    start: number
    stop: number
    text: string
    to_text: string
}

const useDocBatchClient = () => {
    const [isAdding, setIsAdding] = useState<boolean>(false);

    const addTaskOne = async (item: TaskItem): Promise<string | null> => {
        setIsAdding(true)
        return await auth_fetch_post_json(Conf.urlSecAiDocBatchTasksAddOne, item)
            .then((data: any) => {
                console.log('addTaskOne return:', data);
                if (data['status']) {
                    return data['task_id']
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                return null
            }).finally(() => {
                setIsAdding(false)
            })
    }

    return {
        isAdding,
        addTaskOne
    }
}

export default useDocBatchClient;