


import  React from "react";
import { Card } from "react-bootstrap";
import {BaseWidget, WidgetProps} from "../BaseWidget";
import avatar from "../../../../../assets/images/aitool/widgets/icon_activityplan.png";

interface ActivityPlanWidgetProps extends WidgetProps {
    pad?: string;
}
export class ActivityPlanWidget extends BaseWidget<any, ActivityPlanWidgetProps> {
    title: string = "活动方案";
    desc: string = "帮助你策划活动方案";
    routePath: string = "/apps/aitool/widgets/activityplan/activityplanapp";
    iconUrl: string = avatar;


}



