import {FormInput} from "../../../../components";
import {string} from "yup";
import React from "react";


export enum WidgetAppParamType {
    ParamTextLine = 1,
    ParamTextArea = 2,
    ParamSelect = 3

}

export abstract class WidgetAppParam {
    name: string;
    label: string;
    placeholder: string;
    type: WidgetAppParamType;
    desc: string;
    constructor(name: string, label: string, placeholder: string, type: WidgetAppParamType, desc: string) {
        this.name = name;
        this.label = label;
        this.placeholder = placeholder;
        this.type = type;
        this.desc = desc
    }

    typeToInputType(): string {
        switch (this.type) {
            case WidgetAppParamType.ParamTextLine:
                return "text";
            case WidgetAppParamType.ParamTextArea:
                return "textarea";
            case WidgetAppParamType.ParamSelect:
                return "select";
            default:
                return "text";
        }
    }

    abstract createFormInput(): JSX.Element
    isTextArea(): boolean {
        return this.type === WidgetAppParamType.ParamTextArea;
    }

}
export class WidgetAppParamTextLine extends WidgetAppParam {
    constructor(name: string, label: string, placeholder: string, desc: string) {
        super(name, label, placeholder, WidgetAppParamType.ParamTextLine, desc);
    }

    createFormInput(): JSX.Element {
        return (
            <FormInput
                data-desc={this.desc}
                label={this.label}
                type={this.typeToInputType()}
                name={this.name}
                placeholder={this.placeholder}
                containerClass={"mb-3"}
                style={this.isTextArea() ? { height: '256px' } : undefined}
                key={this.name}
            />
        );
    }
}
export class WidgetAppParamTextArea extends WidgetAppParam {
    constructor(name: string, label: string, placeholder: string, desc: string) {
        super(name, label, placeholder, WidgetAppParamType.ParamTextArea, desc);
    }

    createFormInput(): JSX.Element {
        return (
            <FormInput
                data-desc={this.desc}
                label={this.label}
                type={this.typeToInputType()}
                name={this.name}
                placeholder={this.placeholder}
                containerClass={"mb-3"}
                style={this.isTextArea() ? { height: '256px' } : undefined}
                key={this.name}
            />
        );
    }
}

export class WidgetAppParamSelect extends WidgetAppParam {
    constructor(name: string,
                label: string,
                placeholder: string,
                desc: string,
                public options: {value: string, label: string}[]
    ) {
        super(name, label, placeholder, WidgetAppParamType.ParamSelect, desc);
    }

    createFormInput(): JSX.Element {
        return (
            <FormInput
                data-desc={this.desc}
                label={this.label}
                type={this.typeToInputType()}
                name={this.name}
                placeholder={this.placeholder}
                containerClass={"mb-3"}
                className="form-select"
                key={this.name}
            >
                {this.options.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        )
                    })}
            </FormInput>
        );
    }
}
