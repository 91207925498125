import React, {useRef} from "react";
import {WidgetAppParam} from "./WidgetAppParam";
import PageTitle from "../../../../components/PageTitle";
import {Button, Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import {FormInput} from "../../../../components";
import Conf from "../../Utils/Conf";
import Spinner from "../../../../components/Spinner";
import {auth_fetch_post_json} from "../../../../utils/auth_fetch";


export interface BaseWidgetState {
    isBusy: boolean;
}

export interface BaseWidgetAppProps {
    avatar: string;
}


export abstract class BaseWidgetApp<P extends BaseWidgetAppProps, S extends BaseWidgetState> extends React.Component<P, S> {
    abstract template: string;
    abstract title: string;
    abstract params(): WidgetAppParam[];
    private readonly formRef: React.RefObject<HTMLFormElement>;


    constructor(props: P, state:S) {

        super(props, state);
        this.formRef = React.createRef();
        this.setState(state)

    }
    handleGenerate() {
        console.log("handleGenerate " + this.formRef.current?.children.length);

        if(this.formRef.current) {
            const dataToPost: any = {
                template: this.template,
                params: []
            };
            const inputs = this.formRef.current.querySelectorAll("input");
            const textareas = this.formRef.current.querySelectorAll("textarea");
            const selects = this.formRef.current.querySelectorAll("select");
            console.log("inputs " + inputs.length);
            console.log("textareas " + textareas.length);
            console.log("selects " + selects.length);
            inputs.forEach((input) => {
                if(input.name) {
                    dataToPost.params.push({
                        name: input.name,
                        value: input.value,
                        desc: input.getAttribute('data-desc')
                    })
                }
            });
            textareas.forEach((textarea) => {
                if(textarea.name) {
                    dataToPost.params.push({
                        name: textarea.name,
                        value: textarea.value,
                        desc: textarea.getAttribute('data-desc')
                    })
                }
            });

            selects.forEach((select) => {

                if(select.name) {
                    dataToPost.params.push({
                        name: select.name,
                        value: select.value,
                        desc: select.getAttribute('data-desc')
                    })
                }
            })

            console.log(dataToPost)
            this.setState({
                isBusy: true
            })
            auth_fetch_post_json(Conf.urlTextGenerate, dataToPost).then((data: any) => {
                console.log(data);
                const result = document.getElementById("result");
                if(result) {
                    if(data['type'] && data['type'] == 'ImageUrl') {
                        const imageUrl = data.text;
                        const imageElement = document.createElement("img");
                        imageElement.src = Conf.urlOfFile(imageUrl);
                        imageElement.style  = "max-width: 100%;";
                        console.log(imageElement.src)
                        result.innerHTML = "";
                        result.appendChild(imageElement);


                    } else {
                        result.innerText = data.text;
                    }
                }

            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    isBusy: false
                })
            })


        }


        return undefined;
    }
    render() {
        return (
            <>
                <PageTitle
                    breadCrumbItems={[
                        { label: "应用", path: "/apps/aitool" },
                        { label: "AI工具箱", path: "/apps/aitool" },
                        { label: this.title, path: "#", active: true }

                    ]}
                    title={this.title}
                />

                <Row>
                    <Col md={12} xl={6}>
                        <Card

                        >
                            <Card.Body>
                                <form ref={this.formRef}>
                                    {this.params().map((param) => param.createFormInput())}
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" onClick={()=> this.handleGenerate()} disabled={this.state?.isBusy}>
                                            <span hidden={!this.state?.isBusy}>
                                                <Spinner
                                                    className="spinner-border-sm me-1"
                                                    color="white"
                                                />
                                            </span>
                                            <span hidden={!this.state?.isBusy}>生成中...</span>
                                            <span hidden={this.state?.isBusy}>生成</span>
                                        </Button>
                                    </div>
                                </form>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12} xl={6}>
                        <Card>
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="result"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="result" title="生成结果">
                                        <div id="result" style={{whiteSpace: "pre-wrap"}}></div>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

            </>
        );
    }
}