import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers/";

// actions
import {authApiResponseSuccess, authApiResponseError, loginKey} from "./actions";

// constants
import { AuthActionTypes } from "./constants";
import Conf from "../../pages/apps/Utils/Conf";
import axios from "axios";
import {bool} from "yup";


interface UserData {
  payload: {
    username: string;
    password: string;
    amode: string;
    fullname: string;
    email: string;
  };
  type: string;
}

const api = new APICore();


async function fetchGetApi(url: string, token: string) {
  return new Promise(async (resolve, reject) => {
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json'
      }
    })
    if (resp.status === 401) {
      const resp_json = await resp.json();
      reject(resp_json['detail']);
      return;
    } else if (resp.status !== 200) {
      reject('服务返回状态: ' + resp.status);
      return;
    }


    const json = await resp.json()
    resolve(json)
  })

}

function fetchPostApi(url: string, params: any): Promise<any> {
  return new Promise(async (resolve, reject) => {
    let url_args = '';
    for (const key in params) {
      url_args += key + '=' + params[key] + '&';
    }

    try {
      const resp = await fetch(url, {
        method: 'POST',
        body: url_args,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      });

      // 检查状态码
      if (resp.status === 401) {
        const resp_json = await resp.json();
        reject(resp_json['detail']);
        return;
      } else if (resp.status !== 200) {
        reject('服务返回状态: ' + resp.status);
        return;
      }

      const json = await resp.json();
      resolve(json);
    } catch (error) {
      reject(error);
    }
  });
}


function fetchPostJson(url: string, body: any): Promise<any> {
  return new Promise(async (resolve, reject) => {

    try {
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // 检查状态码
      if (resp.status === 401) {
        const resp_json = await resp.json();
        reject(resp_json['detail']);
        return;
      } else if (resp.status !== 200) {
        reject('服务返回状态: ' + resp.status);
        return;
      }

      const json = await resp.json();
      resolve(json);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { username, password, amode },
  type,
}: UserData): SagaIterator {
  try {
    console.log("SAGA login amode", amode)

    const urlJwt = amode == "Admin" ? Conf.urlJwtLoginAdmin: Conf.urlJwtLogin
    console.log(urlJwt)
    const tokenResponse = yield call(fetchPostApi,urlJwt , { username, password});


    /**
     * user is {"id":1,"username":"test","password":"test","firstName":"Test","lastName":"User","role":"Admin","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI"}
     */

    const meResponse = yield call(fetchGetApi, Conf.urlJwtMe, tokenResponse['access_token']);

    const user = meResponse
    user['token'] = tokenResponse["access_token"]



    // NOTE - You can change this according to response format from your api
    api.setLoggedInUser(user);
    setAuthorization(tokenResponse["access_token"]);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}


/**
 * Login the user
 * @param {*} payload - username and password
 */
function* logkey({
                  payload: { amode  },
                  type,
                }: UserData): SagaIterator {
  try {

    const tokenResponse = yield call(fetchPostJson, Conf.urlGoalLogin, { amode  });


    /**
     * user is {"id":1,"username":"test","password":"test","firstName":"Test","lastName":"User","role":"Admin","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI"}
     */

    const meResponse = yield call(fetchGetApi, Conf.urlGoalMe, tokenResponse['access_token']);

    const user = meResponse
    user['token'] = tokenResponse["access_token"]


    // NOTE - You can change this according to response format from your api
    api.setLoggedInUser(user);
    setAuthorization(tokenResponse["access_token"]);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_KEY, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_KEY, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}


/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { fullname, email, password },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { fullname, email, password });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLoginKey() {
  yield takeEvery(AuthActionTypes.LOGIN_KEY, logkey);
}


export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginKey),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
  ]);
}

export default authSaga;
