import axios from "axios";
import MockAdapter from "axios-mock-adapter";

interface UserData {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  role: string;
}

var mock = new MockAdapter(axios);

export function configureFakeBackend() {
  let users: UserData[] = [
    {
      id: 1,
      username: "test",
      firstName: "Test",
      lastName: "User",
      role: "Admin",
    },
  ];

  mock.onPost("/login/").reply(function (config) {
    return new Promise(function (resolve, reject) {

      reject("")
    });
  });

  mock.onPost("/register/").reply(function (config) {
    return new Promise(function (resolve, reject) {

      reject("")
    });
  });

  mock.onPost("/forget-password/").reply(function (config) {
    return new Promise(function (resolve, reject) {
      reject("")
    });
  });
}
